import { combineReducers } from 'redux'

import roles from './roles'
import permissions from './permissions'



export default combineReducers({
  roles,
  permissions
})

