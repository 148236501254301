import { purgeStoredState } from 'redux-persist'

export const setToken = accessToken => {
  window.localStorage.setItem('SESSION_TOKEN', accessToken)

  return accessToken
}

export const getToken = () => {
  return window.localStorage.getItem('SESSION_TOKEN')
}

export const deleteToken = () => {
  return window.localStorage.removeItem('SESSION_TOKEN')
}

export const setUser = accessToken => {
  window.localStorage.setItem('USER_DATA', accessToken)

  return accessToken
}

export const getUser = () => {
  return window.localStorage.getItem('USER_DATA')
}

export const deleteUser = () => {
  return window.localStorage.removeItem('USER_DATA')
}

export const backToLoginPage = () => {
  deleteToken()
  purgeStoredState()
  window.localStorage.clear()
  window.location.href = '/'
}

export const headers = {
  "Authorization": `Bearer ${getToken()}`,
  "Accept": "application/json",
  "Content-Type": "application/json"
}
