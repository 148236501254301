import React, { Component } from 'react'
import { Modal, Layout, Form, Input, Icon, Checkbox, Button, Radio, Row, Col } from 'antd'
import { submitQuestionAndAnswer, updateQuestionAndAnswer } from '../../actions'
import { connect } from 'react-redux'

const { TextArea } = Input;

class QuestionAndAnswer extends Component {
    handleSubmit = e => {
        e.preventDefault();
        const { selectedAnswerAndQuestion, activeCategoryId } = this.props
        const categoryId = activeCategoryId
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (Object.entries(selectedAnswerAndQuestion).length === 0) {
                    this.props.onSubmitQuestionAndAnswer({ values, selectedAnswerAndQuestion, categoryId }).then((res) => {
                        if (!res.error) {
                            this.props.close('exit')
                            Modal.success({
                                // title: "Draft!",
                                content: "Great! You have added new question and answer.",
                                okText: ' Okay',
                                className: '',
                            });
                        }
                    })
                } else {
                    this.props.onUpdateQuestionAndAnswer({ values, selectedAnswerAndQuestion, categoryId }).then((res) => {
                        if (!res.error) {
                            this.props.close('exit')
                            Modal.success({
                                // title: "Draft!",
                                content: "Great! You have updated the question and answer.",
                                okText: ' Okay',
                                className: '',
                            });
                        }
                    })
                }
            }
        });
    };

    render() {
        const { selectedAnswerAndQuestion } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <Layout  >
                <Layout.Content>
                    <Modal
                        title={(Object.entries(this.props.selectedAnswerAndQuestion).length === 0 ? 'Add New Question & Answer' : 'Edit Question & Answer ')}
                        visible={this.props.visible}
                        onOk={this.handleOk}
                        footer={null}
                        className="faq-modal-new-category"
                        width={800}
                        onCancel={() => this.props.close('exit')}
                    >
                        <Form onSubmit={this.handleSubmit} className="faq-modal-form">
                            <Row>
                                <Col md={24}>
                                    <Form.Item className='faq-view-options'>
                                        <label>VIEW OPTIONS</label><br />
                                        {getFieldDecorator('type', {
                                            initialValue: selectedAnswerAndQuestion.type,
                                            rules: [{ required: true, message: 'Please Select View Options!' }],
                                        })(
                                            <div className='faq-view-options-radio-btn'>
                                                <input type="radio" id="loggedIn" defaultChecked={selectedAnswerAndQuestion.type === 1} name="viewOptions" value={1} />
                                                <label htmlFor="loggedIn">Logged In</label><br />
                                                <input type="radio" id="notLoggedIn" defaultChecked={selectedAnswerAndQuestion.type === 0} name="viewOptions" value={0} />
                                                <label htmlFor="notLoggedIn">Both</label>
                                            </div>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    <Form.Item>
                                        <label>QUESTION</label>
                                        {getFieldDecorator('question', {
                                            initialValue: selectedAnswerAndQuestion.question,
                                            rules: [{ required: true, message: 'Please input your Question!' }],
                                        })(
                                            <Input
                                                className='input-line'
                                                placeholder="Type A Question"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    <Form.Item>
                                        <label>ANSWER</label>
                                        {getFieldDecorator('answer', {
                                            initialValue: selectedAnswerAndQuestion.answer,
                                            rules: [{ required: true, message: 'Please input your Answer!' }],
                                        })(
                                            <TextArea
                                                rows={4}
                                                className='input-line'
                                                placeholder="Type A Answer"
                                            />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={24}>
                                    <Col span={24} align="right">
                                        <Form.Item>
                                            <Button key="back" onClick={() => this.props.close('exit')} className="faq-modal-cancel-btn" disabled={false}>
                                                <span>Close</span>
                                            </Button>
                                            &nbsp;
                                            <Button type="primary" htmlType="submit" className="faq-modal-submit-btn" disabled={this.props.submitCategoryLoading} loading={this.props.submitCategoryLoading}>
                                                <span>{(Object.entries(this.props.selectedAnswerAndQuestion).length === 0 ? 'Add New Question' : 'Edit Question')}</span>
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Layout.Content>
            </Layout >
        )
    }
}


function mapStateToProps(state) {
    return {
        submitCategoryLoading: state.FAQ.submitCategoryLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitQuestionAndAnswer: (data) => dispatch(submitQuestionAndAnswer(data)),
        onUpdateQuestionAndAnswer: (data) => dispatch(updateQuestionAndAnswer(data)),

    }
}

const NewQuestionAndAnswer = Form.create({ name: 'AddEditQuestionAndAnswer' })(QuestionAndAnswer);
export default connect(mapStateToProps, mapDispatchToProps)(NewQuestionAndAnswer);