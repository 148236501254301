import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from './Page';

import {
    fetchPermissions
} from '../../actions'

import {
    fetchInquiryDashboardData,
} from './actions'
class Dashboard extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        inquiryDashboardData: state.inquiries.inquiryDashboardData,
        inquiryDashboardLoading: state.inquiries.inquiryDashboardLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        fetchInquiryDashboardData: (data) => dispatch(fetchInquiryDashboardData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
