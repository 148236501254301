import React, { Component } from 'react';
import moment from "moment";
import {
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Dropdown,
  Menu,
  Input,
  Modal,
  Skeleton,
  Result,
  Typography,
  Select,
  Tabs,
  DatePicker,
  Divider,
  message,
  Upload,
  Spin
} from 'antd';
import { Link } from 'react-router-dom'
import './index.scss'
import * as _ from "lodash";
import ModalContainerBreakdown from './components/modalContainerBreakdown';
import { getNames, getName, getCode } from 'country-list'
import { map } from 'lodash';
const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const OrderStatusUploadButton = ({ myPermissions }) => {
  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_URL}/order-status`,
    showUploadList: false,
    headers: {
      authorization: `Bearer ${window.localStorage.getItem("SESSION_TOKEN")}`,
      accept: "application/json",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // 
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button
        style={{
          margin: "0 10px",
        }}
        className="btn-small btn-primary"
      // disabled={
      //   !myPermissions.some((r) => r.name === "admin.distributor_orders.create")
      // }
      >
        Upload SAP Data
      </Button>
    </Upload>
  );
};

class ContainerConfiguration extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      activeRadio: 'all',
      filterBy: 'all',
      filterDate: 'All',
      filterInvoicedDate: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      isEditCustomerPoNumber: undefined,
      editCustomerPoNumberValue: '',
      modalContainerVisible: false,
      showResponsesModal: false,
      notifData: [],
      selectedRow: null,
      searchValue: "",
      dataSearched: [],
      startDate: null,
      endDate: null,
      filterOptions: [],
      filterInvoicedDateOptions :[]
    }
    this.handleToggleModalContainer = this.handleToggleModalContainer.bind(this)
  }
  componentDidMount() {
    this.props.onLoadContainerSummary()
    this.props.fetchNotifications()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.containerBreakdownData !== nextProps.containerBreakdownData) {

      this.setState({ tableData: nextProps.containerBreakdownData })
    }
    if (this.props.notifData !== nextProps.notifData) {
      this.setState({ notifData: nextProps.notifData })
      this.clearFilter();
    }
    // console.log("receieved this ", nextProps)
  }

  componentDidUpdate(prevState, prevProps) {
    const { startDate, endDate, searchValue } = this.state;
    if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
      prevProps.notifData = this.props.notifData;
    }

    if (searchValue !== prevState.searchValue) {
      if (prevState.startDate !== startDate && prevState.endDate !== endDate) {
        prevProps.notifData = this.props.notifData;
      }
    }
  }

  getFilterBasedOnColumn(array, column) {
    const filteredByCustomerName = array && array.map(data => _.pick(data, [column]))
    return _.uniqBy(filteredByCustomerName, column)
  }

  handleToggleModalContainer(row) {
    this.setState({ modalContainerVisible: !this.state.modalContainerVisible, selectedRow: row })
  }

  onChangeFilterRadio(status) {
    this.state.filterOptions = [];
    this.state.filterInvoicedDateOptions = [];
    
    var filter = {
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      invoicedDate: this.state.filterInvoicedDate !== 'All' ? this.state.filterInvoicedDate : undefined,
      customer_name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let newData = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });

    let filteredByStatus
    if (status === 'all') {
      filteredByStatus = newData
    } else if (status === 'in process') {
      filteredByStatus = newData.filter((data) => data.status !== 'invoiced')
    } else if(status === 'cancelled'){
      filteredByStatus = newData.filter((data) => data.status === 'cancelled')
    } else {
      filteredByStatus = newData.filter((data) => data.status === 'invoiced')
    }
    this.setState({
      activeRadio: status,
      tableData: filteredByStatus
    })
  }

  clearFilter() {
    this.setState({
      filterDate: 'All',
      filterInvoicedDate: 'All',
      filterCustomer: 'All',
      filterCountry: 'All',
      tableData: this.props.containerBreakdownData,
      filterOptions: [],
      filterInvoicedDateOptions: []
    })
  }

  handleSearch = (searchTerm, searchKeys) => {
    if (this.state.filterBy !== 'all') {
      searchKeys = [this.state.filterBy]
    }
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }

    var filter = {
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      invoicedDate: this.state.filterInvoicedDate !== 'All' ? this.state.filterInvoicedDate : undefined,
      customer_name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let newData = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });

    let newArrayObj
    if (this.state.activeRadio === 'all') {
      newArrayObj = newData
    } else if (this.state.activeRadio === 'in process') {
      newArrayObj = newData.filter((data) => data.status !== 'invoiced')
    } else {
      newArrayObj = newData.filter((data) => data.status === 'invoiced')
    }
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj })
    }
    let newArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound =
          rowItemKeys &&
          (rowItemKeys.toString().toLowerCase().replace(/[$,]/g, "").includes(searchTerm.toString().toLowerCase().replace(/[$,]/g, "")) ||
            rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()));
        if (isFound) newArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = newArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ tableData: newArrayElement })
  }

  handleChangeInvoicedDate(value) {
    this.state.filterOptions = [];
    this.state.filterInvoicedDateOptions = [];
    var filter = {
      invoicedDate: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      customer_name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    this.setState({ filterInvoicedDate: value, tableData: data })
  }

  handleChangeDate(value) {
    this.state.filterOptions = [];
    this.state.filterInvoicedDateOptions = [];
    var filter = {
      created_at: value !== 'All' ? value : undefined,
      invoicedDate: this.state.filterInvoicedDate !== 'All' ? this.state.filterInvoicedDate : undefined,
      customer_name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    this.setState({ filterDate: value, tableData: data })
  }

  handleChangeCustomer(value) {
    this.state.filterOptions = [];
    this.state.filterInvoicedDateOptions = [];
    var filter = {
      customer_name: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      invoicedDate: this.state.filterInvoicedDate !== 'All' ? this.state.filterInvoicedDate : undefined,
      country: this.state.filterCountry !== 'All' ? this.state.filterCountry : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    this.setState({ filterCustomer: value, tableData: data })
  }

  handleChangeCountry(value) {
    this.state.filterOptions = [];
    this.state.filterInvoicedDateOptions = [];
    var filter = {
      country: value !== 'All' ? value : undefined,
      created_at: this.state.filterDate !== 'All' ? this.state.filterDate : undefined,
      invoicedDate: this.state.filterInvoicedDate !== 'All' ? this.state.filterInvoicedDate : undefined,
      customer_name: this.state.filterCustomer !== 'All' ? this.state.filterCustomer : undefined
    };
    Object.keys(filter).forEach(key => filter[key] === undefined ? delete filter[key] : {});
    let data = this.props.containerBreakdownData && this.props.containerBreakdownData.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    this.setState({ filterCountry: value, tableData: data })
  }

  handleShowResponsesModal = () => {
    this.setState({
      showResponsesModal: true
    });
  };

  handleEditCustomerPoNumber(data) {
    const newCustomerPoNumber = (this.state.editCustomerPoNumberValue ? this.state.editCustomerPoNumberValue : data.customer_po_number)
    const submitData = {
      customer_po_number: data.customer_po_number,
      new_customer_po_number: newCustomerPoNumber,
      so_number: data.so_number
    }
    if (data.customer_po_number === newCustomerPoNumber) {
      return this.setState({ isEditCustomerPoNumber: undefined })
    }
    this.props.onUpdateCustomerPoNumber(submitData).then((res) => {
      if (!res.error) {
        this.setState({ isEditCustomerPoNumber: undefined })
      }
    })
  }

  render() {
    const {
      showResponsesModal,
      selectedRow,
      searchValue,
      dataSearched,
      startDate,
      endDate
    } = this.state

    const {
      notifData,
      onSendRequest
    } = this.props


    const handleNewSearch = (e) => {
      const result = Object.values(notifData).filter((row) => {
        const search = (arg) => {
          if (arg) {
            return arg
              .toString()
              .toLowerCase()
              .includes(this.state.searchValue.toString().toLowerCase());
          }
        };

        const soNumber = () => {
          if (row) {
            if (row.progress !== null) {
              return row.progress;
            }
          }
        };

        const comments = () => {
          if (row) {
            if (row.description !== null) {
              return row.description;
            }
          }
        };

        const distributorName = () => {
          if (row) {
            if (row.distributor !== null) {
              return row.distributor.name;
            }
          }
        };

        return search(soNumber()) || search(comments()) || search(distributorName());
      });

      this.setState({
        searchValue: e.target.value.substr(0, 20),
        dataSearched: result,
      });
    };

    const handleRecentResponsesDatePicker = (value) => {
      const [start, end] = value;
      const startDate = moment(start).unix();
      const endDate = moment(end).unix();
      this.setState({
        startDate,
        endDate,
      });
    };

    const columns = [
      {
        title: 'CREATED DATE',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => {
          const x = a.created_at.toLowerCase();
          const y = b.created_at.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'SO NUMBER',
        dataIndex: 'so_number',
        key: 'so_number',
        sorter: (a, b) => {
          const x = a.so_number.toLowerCase();
          const y = b.so_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (data, i) => <Link to={`/container-configuration?so_number=${data}&created_at=${i.created_at}`} onClick={() => this.handleToggleModalContainer(i)}>{data}</Link>
      },
      {
        title: 'CUSTOMER PO NUMBER',
        dataIndex: 'customer_po_number',
        key: 'customer_po_number',
        sorter: (a, b) => {
          const x = a.customer_po_number.toLowerCase();
          const y = b.customer_po_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (item, data) => {
          return (
            (this.state.isEditCustomerPoNumber !== data.so_number) ?
              <div style={{ display: 'flex' }} key={data.so_number}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon type="edit" style={{ color: '#1890FF', margin: 10, fontSize: 18 }} onClick={() => this.setState({ isEditCustomerPoNumber: data.so_number, editCustomerPoNumberValue: data.customer_po_number })} />
                <h1 style={{ margin: 5 }}>{data.customer_po_number}</h1>
                {/* <Input value={data.customer_po_number} style={{ width: 150 }} /> */}
              </div> :
              <div style={{ display: 'flex' }} key={data.so_number}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Input value={this.state.editCustomerPoNumberValue} style={{ width: 130 }} onChange={(e) => this.setState({ editCustomerPoNumberValue: e.target.value })} />
                <Button style={{ backgroundColor: '#1890FF', color: 'white', margin: 2 }} icon='save' shape="circle" onClick={() => this.handleEditCustomerPoNumber(data)} />
                <Button type="primary" shape="circle" icon="close" style={{ margin: 2 }} onClick={() => this.setState({ isEditCustomerPoNumber: undefined, editCustomerPoNumberValue: '' })} />
              </div>
          )
        }
      },
      {
        title: 'CUSTOMER NAME',
        dataIndex: 'customer_name',
        key: 'customer_name',
        sorter: (a, b) => {
          const x = a.customer_name.toLowerCase();
          const y = b.customer_name.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'ORDER STATUS',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {
          const x = a.status.toLowerCase();
          const y = b.status.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: text => <div className='table-status'>
          <div className={`${text === 'invoiced' ? 'invoiced' : 'inProcess'}-status`}><span>{text === 'invoiced' ? 'INVOICED' : 'IN PROCESS'}</span></div>
        </div>,
      },
      {
        title: 'AMOUNT (IN USD)',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        sorter: (a, b) => {
          const x = a.amount;
          const y = b.amount;
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (data) => <span>${
          data.toLocaleString(undefined, {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          })
        }</span>
      },
      {
        title: 'CBM',
        dataIndex: 'cbm',
        key: 'cbm',
        align: 'right',
        sorter: (a, b) => {
          const x = a.cbm;
          const y = b.cbm;
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (data) => data.toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })
      },
      {
        title: 'GROSS WEIGHT',
        dataIndex: 'gross_weight',
        key: 'gross_weight',
        align: 'right',
        sorter: (a, b) => {
          const x = a.gross_weight;
          const y = b.gross_weight;
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (data) => data.toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })
      },
      {
        title: 'INVOICED DATE',
        dataIndex: 'invoicedDate',
        key: 'invoicedDate',
        sorter: (a, b) => {
          const x = a.invoicedDate.toLowerCase();
          const y = b.invoicedDate.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
    ];

    return <div>
      <Layout className='configuration-container'>
        <Content>
          <Content className='configuration-header'>
            <Row type="flex" justify="space-between">
              <Col className="page-title" style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                <h1>Container Configuration</h1>
              </Col>
              <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <OrderStatusUploadButton
                  myPermissions={this.props.myPermissions}
                />
                <Button
                  className="btn-small btn-primary"
                  onClick={() => this.setState({ showResponsesModal: true })}
                  style={{ marginRight: 20 }}
                >
                  View Recent Responses
                </Button>
              </Col>
            </Row>
          </Content>
          <Content className='configuration-filter'>
            <Row type="flex" justify="start" gutter={32}>
              <Col className='col-filter'>
                <label>DATE</label>
                <Select value={this.state.filterDate} onChange={(value) => this.handleChangeDate(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                    // this.props.containerBreakdownData && this.getFilterBasedOnColumn(this.props.containerBreakdownData, 'created_at').map((item, i) => {
                      this.props.containerBreakdownData && 
                     this.props.containerBreakdownData.map((item, i) => {
                        if((this.state.filterInvoicedDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterInvoicedDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === item.country)||
                        (this.state.filterInvoicedDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterCountry === 'All')||
                        (this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCustomer === item.customer_name && this.state.filterCountry === 'All') ||
                        (this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCustomer === 'All' && this.state.filterCountry === item.country) ||
                        (this.state.filterInvoicedDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterCountry === item.country) ||
                        (this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCustomer === item.customer_name && this.state.filterCountry === item.country) 
                        )
                        {
                        //filter options array ensures that there won't be any duplicates in filters
                        if(!(this.state.filterOptions.includes(item.created_at))){
                          this.state.filterOptions.push(item.created_at);
                          return (
                            <Option key={i} value={item.created_at}>{item.created_at}</Option>
                          )
                        }        
                        }
                    })
                  }
                </Select>
              </Col>
              <Col className='col-filter'>
                <label>INVOICED DATE</label>
                <Select value={this.state.filterInvoicedDate} onChange={(value) => this.handleChangeInvoicedDate(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                    // filterDate: 'All',
                    // filterInvoicedDate: 'All',
                    // filterCustomer: 'All',
                    // filterCountry: 'All',
                    //this.props.containerBreakdownData && this.getFilterBasedOnColumn(this.props.containerBreakdownData, 'invoicedDate').map((item, i) => {
                    this.props.containerBreakdownData && 
                    this.props.containerBreakdownData.map((item, i) => { 
                      if((this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterCountry === item.country)||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterCountry === 'All')||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.customer_name && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterCountry === item.country) ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterCountry === item.country) ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.customer_name && this.state.filterCountry === item.country) 
                        )
                        {
                        //filter options array ensures that there won't be any duplicates in filters
                        if(!(this.state.filterInvoicedDateOptions.includes(item.invoicedDate))){
                          this.state.filterInvoicedDateOptions.push(item.invoicedDate);
                          return (
                            <Option key={i} value={item.invoicedDate}>{item.invoicedDate}</Option>
                          )
                        } 
                      }
                    })
                  }
                </Select>
              </Col>
              <Col className='col-filter'>
                <label>CUSTOMER</label>
                <Select value={this.state.filterCustomer} onChange={(value) => this.handleChangeCustomer(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {
                    // this.getFilterBasedOnColumn(this.props.containerBreakdownData, 'customer_name').sort((a, b) => a.customer_name.localeCompare(b.customer_name)).map((item, i) => {
                    this.props.containerBreakdownData && 
                     this.props.containerBreakdownData.sort((a, b) => a.customer_name.localeCompare(b.customer_name)).map((item, i) => {
                        // console.log("wahuu", item);
                        
                        // ;
                        if((this.state.filterDate === 'All' && this.state.filterInvoicedDate === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === 'All' && this.state.filterInvoicedDate === 'All' && this.state.filterCountry === item.country)||
                        (this.state.filterDate === 'All' && this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCountry === 'All')||
                        (this.state.filterDate === item.created_at && this.state.filterInvoicedDate === 'All' && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCountry === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterInvoicedDate === 'All' && this.state.filterCountry === item.country) ||
                        (this.state.filterDate === 'All' && this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCountry === item.country) ||
                        (this.state.filterDate === item.created_at && this.state.filterInvoicedDate === item.invoicedDate && this.state.filterCountry === item.country) 
                        )
                        {
                          if(!(this.state.filterOptions.includes(item.customer_name))){
                            this.state.filterOptions.push(item.customer_name);
                            return (
                              <Option key={i} value={item.customer_name}>{item.customer_name}</Option>
                            )
                          } 
                        }
                    })
                  }
                </Select>
              </Col>
              <Col className='col-filter'>
                <label>COUNTRY</label>
                <Select value={this.state.filterCountry} onChange={(value) => this.handleChangeCountry(value)} style={{ width: 150 }} showSearch>
                  <Option value='All' key={-1}>All</Option>
                  {/* {
                    getNames().map((country, i) => {
                      return (
                        <Option value={getCode(country)} key={i}>{country}</Option>
                      )
                    })
                  } */}

                  {
                    // this.getFilterBasedOnColumn(this.props.containerBreakdownData, 'country').sort((a, b) => a.country.localeCompare(b.country)).map((item, i) => {
                    this.props.containerBreakdownData && 
                    this.props.containerBreakdownData.sort((a, b) => a.country.localeCompare(b.country)).map((item, i) => {
                      if((this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterInvoicedDate === 'All') ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === 'All' && this.state.filterInvoicedDate === item.invoicedDate)||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterInvoicedDate === 'All')||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterInvoicedDate === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.customer_name && this.state.filterInvoicedDate === 'All') ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === 'All' && this.state.filterInvoicedDate === item.invoicedDate) ||
                        (this.state.filterDate === 'All' && this.state.filterCustomer === item.customer_name && this.state.filterInvoicedDate === item.invoicedDate) ||
                        (this.state.filterDate === item.created_at && this.state.filterCustomer === item.customer_name && this.state.filterInvoicedDate === item.invoicedDate) 
                        )
                        {
                          //filter options array ensures that there won't be any duplicates in filters
                          if(!(this.state.filterOptions.includes(item.country))){
                            this.state.filterOptions.push(item.country);
                            return (
                              <Option key={i} value={item.country}>{item.country}</Option>
                            )
                          } 
                        }
                    })
                  }
                </Select>
              </Col>
              <Col >
                <Content className='col-filter-reset'>
                  <label onClick={() => this.clearFilter()}>Reset Filter</label>
                </Content>
              </Col>
            </Row>
          </Content>
          <Content className='configuration-content'>
            <Row>
              <Content className='content-filter'>
                <div>
                  <Search
                    placeholder="Search"
                    onSearch={value => this.handleSearch(value, [
                      'actual_stocks',
                      'amount',
                      'cbm',
                      'country',
                      'created_at',
                      'created_at_whole',
                      'created_at_number',
                      'customer_name',
                      'gross_weight',
                      // 'po_number',
                      'so_number',
                      'status',
                      'invoicedDate',
                      'customer_po_number'
                    ])}
                    style={{ width: 230 }}
                  />
                </div>
                <div className='status-radio-filter'>
                  <h1>STATUS</h1>
                  <Content className='filter-radio'>
                    <label className="radio"><span style={{ margin: '10px 0' }}>ALL</span>
                      <input type="radio" name="status" checked={this.state.activeRadio === 'all'} value="all" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">IN PROCESS
                      <input type="radio" name="status" checked={this.state.activeRadio === 'in process'} value="in process" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">INVOICED
                    <input type="radio" name="status" checked={this.state.activeRadio === 'invoiced'} value="invoiced" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">CANCELLED
                    <input type="radio" name="status" checked={this.state.activeRadio === 'cancelled'} value="cancelled" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                      <span className="checkmark"></span>
                    </label>
                  </Content>
                </div>
              </Content>
            </Row>
            <Row>
              <Col>
                <Table
                  rowKey="id"
                  dataSource={this.state.tableData}
                  columns={columns}
                  loading={this.props.containerBreakdownLoading || this.props.updateCustomerPoNumberLoading}
                  onChange={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0;
                  }}
                  onRowClick={(r, index) => {
                    this.handleToggleModalContainer(index)
                    this.props.history.push(`/container-configuration?so_number=${r.so_number}&created_at=${r.created_at}`)
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Content>

        <Modal
          title="View Recent Responses"
          visible={showResponsesModal}
          onCancel={() => this.setState({ showResponsesModal: false })}
          width={"50%"}
          footer={null}
          className="responses-modal"
        >
          <Spin spinning={notifData.length === 0}>
            <Tabs defaultActiveKey="1" onChange={() => this.setState({ searchValue: '' })}>
              <TabPane tab="All" key="1">
                <span className="recent-responses">Recent Responses</span>
                <Row type="flex">
                  <Col>
                    <Search
                      size="large"
                      placeholder="Search"
                      value={this.state.searchValue}
                      onChange={handleNewSearch}
                      style={{ margin: "10px", width: 150 }}
                    />
                  </Col>
                  <Col>
                    <span className="datepicker-label">DATE </span>{" "}
                    <RangePicker
                      className="datepicker"
                      size="large"
                      style={{ width: 200 }}
                      onChange={handleRecentResponsesDatePicker}
                    />
                  </Col>
                </Row>
                {/* <h1>Date</h1> */}

                {(searchValue.length >= 1 ? dataSearched : notifData)
                  .filter((response) => {
                    return (
                      response.notif_type === "sku replacement" &&
                      response.response !== null
                    );
                  })
                  .filter((row) => {
                    if (startDate !== null && endDate !== null) {
                      return (
                        moment(row.updated_at).unix() > startDate &&
                        moment(row.updated_at).unix() < endDate
                      );
                    } else {
                      return row;
                    }
                  })
                  .map((response, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <p>
                          Your request from SO #:{" "}
                          <strong>
                            {response.progress}
                          </strong>{" "}
                        was{" "}
                          {response.response === "yes" ? (
                            <span className="approved">
                              <strong>approved</strong>
                            </span>
                          ) : (
                              <span className="not-approved">
                                <strong>not approved</strong>
                              </span>
                            )}{" "}
                        by <strong>{response.distributor.name}</strong> to change product/s.{" "}
                          <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                          <span className="single-notif-date">
                            {moment(response.updated_at).format("LLL")}
                          </span>
                        </p>
                      </div>
                    );
                  })}
              </TabPane>
              <TabPane tab="Approved" key="2">
                <span className="recent-responses">Recent Responses</span>
                <Row type="flex">
                  <Col>
                    <Search
                      size="large"
                      placeholder="Search"
                      value={this.state.searchValue}
                      onChange={handleNewSearch}
                      style={{ margin: "10px", width: 150 }}
                    />
                  </Col>
                  <Col>
                    <span className="datepicker-label">DATE </span>{" "}
                    <RangePicker
                      className="datepicker"
                      size="large"
                      style={{ width: 200 }}
                      onChange={handleRecentResponsesDatePicker}
                    />
                  </Col>
                </Row>
                {/* <h1>Date</h1> */}
                {(searchValue.length >= 1 ? dataSearched : notifData)
                  .filter((response) => {
                    return (
                      response.notif_type === "sku replacement" &&
                      response.response === "yes"
                    );
                  })
                  .filter((row) => {
                    if (startDate !== null && endDate !== null) {
                      return (
                        moment(row.updated_at).unix() > startDate &&
                        moment(row.updated_at).unix() < endDate
                      );
                    } else {
                      return row;
                    }
                  })
                  .map((response, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <p>
                          Your request from PO Number{" "}
                          <strong>
                            {response.progress}
                          </strong>{" "}
                        was{" "}
                          <span className="approved">
                            <strong>approved</strong>
                          </span>{" "}
                        by <strong>{response.distributor.name}</strong> to change product/s.{" "}
                          <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                          <span className="single-notif-date">
                            {moment(response.updated_at).format("LLL")}
                          </span>
                        </p>
                      </div>
                    );
                  })}
              </TabPane>
              <TabPane tab="Not Approved" key="3">
                <span className="recent-responses">Recent Responses</span>
                <Row type="flex">
                  <Col>
                    <Search
                      size="large"
                      placeholder="Search"
                      value={this.state.searchValue}
                      onChange={handleNewSearch}
                      style={{ margin: "10px", width: 150 }}
                    />
                  </Col>
                  <Col>
                    <span className="datepicker-label">DATE </span>{" "}
                    <RangePicker
                      className="datepicker"
                      size="large"
                      style={{ width: 200 }}
                      onChange={handleRecentResponsesDatePicker}
                    />
                  </Col>
                </Row>
                {/* <h1>Date</h1> */}
                {(searchValue.length >= 1 ? dataSearched : notifData)
                  .filter((response) => {
                    return (
                      response.notif_type === "sku replacement" &&
                      response.response === "no"
                    );
                  })
                  .filter((row) => {
                    if (startDate !== null && endDate !== null) {
                      return (
                        moment(row.updated_at).unix() > startDate &&
                        moment(row.updated_at).unix() < endDate
                      );
                    } else {
                      return row;
                    }
                  })
                  .map((response, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <p>
                          Your request from PO Number{" "}
                          <strong>
                            {response.progress}
                          </strong>{" "}
                        was{" "}
                          <span className="not-approved">
                            <strong>not approved</strong>
                          </span>{" "}
                        by <strong>{response.distributor.name}</strong> to change product/s.{" "}
                          <br />
                        Comment:{" "}<strong><em>{response.description}</em></strong><br />
                          <span className="single-notif-date">
                            {moment(response.updated_at).format("LLL")}
                          </span>
                        </p>
                      </div>
                    );
                  })}
              </TabPane>
            </Tabs>
          </Spin>
        </Modal>
      </Layout>

      {
        this.state.modalContainerVisible &&
        <ModalContainerBreakdown
          {...this.props}
          onLoadContainerSummaryBasedOnSoNumber={this.props.onLoadContainerSummaryBasedOnSoNumber}
          containerBreakdownBasedSoNumberData={this.props.containerBreakdownBasedSoNumberData}
          containerBreakdownBasedSoNumberLoading={this.props.containerBreakdownBasedSoNumberLoading}
          visible={this.state.modalContainerVisible}
          onCancel={this.handleToggleModalContainer}
          selectedRow={selectedRow}
          onSendRequest={onSendRequest}
        />
      }
    </div >
  }
}

export default ContainerConfiguration;
