import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadProductVariantsData,
    submitAddProductVariant,
    submitUpdateProductVariant
} from './actions';
import {
    fetchPermissions
} from '../../actions'
import {
    loadActiveProductData
} from '../Products/actions';
import Page from './Page';

class ProductVariant extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const ProductVariantForm = Form.create({ name: 'product_variant_form' })(ProductVariant);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        variantLoading: state.productVariants.variantLoading,
        variantsData: state.productVariants.variantsData,
        productsData: state.product.productData,
        productLoading: state.product.productLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadProductVariantsData: (data) => dispatch(loadProductVariantsData(data)),
        onSubmitAddProductVariant: (data, searchParams) => dispatch(submitAddProductVariant(data, searchParams)),
        onSubmitUpdateProductVariant: (data, searchParams) => dispatch(submitUpdateProductVariant(data, searchParams)),
        onLoadActiveProductData: (data) => dispatch(loadActiveProductData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantForm);