import Axios from '../../../utils/axios'

export const loadUserRolesData = (data) => {
  return dispatch => {
    Axios.get('/admin/role', dispatch, 'GET_USER_ROLES', data)
  }
}

export const submitAddUserRole = (data) => {
  return dispatch => {
    return Axios.post(`/admin/role`, dispatch, 'POST_USER_ROLES', data)
      .then((response) => {
        dispatch(loadUserRolesData())
        return response
      })
  }
}

export const submitUpdateUserRole = (data) => {
  return dispatch => {
    return Axios.post(`/admin/role/${data.id}`, dispatch, 'POST_USER_ROLES', data)
      .then((response) => {
        dispatch(loadUserRolesData())
        return response
      })
  }
}

// Get permissions
export const loadPermissionsData = (data) => {
  return dispatch => {
    Axios.get('/admin/permission', dispatch, 'GET_PERMISSIONS', data)
  }
}
