import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input } from 'antd';

const { Content } = Layout;

export default function ModalForm(props) {
  const { selectedRow } = props;

  if (selectedRow === null) {
    return "";
  }

  return (
    <Modal
      title="View Details"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row type="flex" justify="center" gutter={30}>
            <Form onSubmit={props.handleSubmit} className="login-form">
              <Col span={24} align="center">
                <div style={{ position: "relative" }}>
                  {
                    selectedRow.image ? (<img
                      alt={selectedRow.name}
                      src={process.env.REACT_APP_FILES_URL + selectedRow.image}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />) : (<div style={{ verticalAlign: "middle", marginTop: "30%" }}>No Image Found</div>)
                  }
                </div>
              </Col>

              <Col span={24}>
                <Form.Item label="Brand Name">
                  <Input value={selectedRow.name} readOnly={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Description">
                  <Input value={selectedRow.description} readOnly={true} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Status">
                  <Input value={selectedRow.status ? "Active" : "Inactive"} readOnly={true} />
                </Form.Item>
              </Col>

              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal}>
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
