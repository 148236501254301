import Axios from 'axios'
import { headers } from '../utils/token';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_FULFILLED = 'FETCH_NOTIFICATION_FULFILLED';
export const FETCH_NOTIFICATION_REJECTED = 'FETCH_NOTIFICATION_REJECTED';

export const SEND_REQUEST = 'SEND_REQUEST';
export const SEND_REQUEST_FULFILLED = 'SEND_REQUEST_FULFILLED';
export const SEND_REQUEST_REJECTED = 'SEND_REQUEST_REJECTED';

const API_URL = process.env.REACT_APP_API_URL

export const fetchNotifications = (data) => {
    return dispatch => {
        dispatch({
            type: FETCH_NOTIFICATION,
            payload: {}
        })
        return Axios.get(`${API_URL}/admin/notifications`, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: FETCH_NOTIFICATION_FULFILLED,
                    payload: response.data
                })
            })
            .catch(function (error) {
                dispatch({
                    type: FETCH_NOTIFICATION_REJECTED,
                    payload: error
                })
            })
    }
}

export const sendRequest = (data) => dispatch => {
  return dispatch => {
    dispatch({
      type: SEND_REQUEST,
      payload: {}
    })
    return Axios.post(`${API_URL}/admin/notifications`, data, { headers: headers() })
      .then(response => {
        dispatch({
          type: SEND_REQUEST_FULFILLED,
          payload: response.data
        });
        return { error: false, response };
      })
      .catch(err => {
        dispatch({
          type: SEND_REQUEST_REJECTED,
          payload: err
        })
        return { error: true, err };
      });    
  }
}
