import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd';
import ImageUploader from '../../../shared/ImageUploader'
import ColumnGroup from 'antd/lib/table/ColumnGroup';

const { Content } = Layout;
const Option = Select.Option;

export default function ModalForm(props) {
  const { selectedRow, isLoading, brands } = props;

  return (
    props.showFormModal &&
    <Modal
      title={`${selectedRow ? "Edit" : 'Add'} Product`}
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row type="flex" justify="center" gutter={30}>
            <Form onSubmit={props.handleSubmit} className="login-form">
              <Col span={8} align="center" style={{ height: 200 }}>
                <ImageUploader
                  ref={props.imageUploader}
                  fileFolder={`products`}
                  buttonDesc="Click here to choose image"
                  imageUrl={selectedRow ? selectedRow.image : ""}
                />
              </Col>

              <Col span={16}>
                <Form.Item label="Product Name">
                  {props.getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your Product Name!' }],
                    initialValue: selectedRow ? selectedRow.name : ""
                  })(
                    <Input
                      placeholder="ex: Piattos"
                    />,
                  )}
                </Form.Item>
              </Col>

              <Col span={16}>
                <Form.Item label="Description">
                  {props.getFieldDecorator('description', {
                    initialValue: selectedRow ? selectedRow.description : ""
                  })(
                    <Input
                      placeholder="ex: Description"
                    />,
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Category">
                  {props.getFieldDecorator('category', {
                    rules: [{ required: true, message: 'Please input your Category!' }],
                    initialValue: selectedRow ? selectedRow.category : ""
                  })(
                    < Select placeholder='Select a Category' showSearch >
                      <Option value='Chips and Crisps'>Chips & Crisps</Option>
                      <Option value='Bakery'>Bakery</Option>
                      <Option value='Confectionery'>Confectionery</Option>
                      <Option value='Ready To Drink Beverage'>Ready To Drink Beverage</Option>
                      <Option value='Powdered Beverage'>Powdered Beverage</Option>
                      <Option value='Noodles'>Noodles</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Country Made">
                  {props.getFieldDecorator('country_made', {
                    initialValue: selectedRow ? selectedRow.country_made : ""
                  })(
                    <Input
                      placeholder="ex: Philippines"
                    />,
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Brands">
                  {props.getFieldDecorator('brand_id', {
                    rules: [{ required: true, message: 'Please input your Brand!' }],
                    initialValue: selectedRow && (selectedRow.brand.status && !selectedRow.brand.is_disabled) ? selectedRow.brand.name : ""
                  })(
                    <Select placeholder='Select a Brands' showSearch
                      onPopupScroll={(event) => {
                        if (!props.isBrandLoading && event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
                          if (props.brands.current_page !== props.brands.last_page) props.loadActiveBrands({ page: props.brands.current_page + 1 });
                        }
                      }}
                    >
                      {
                        props.brands ? props.brands.data.map((data, index) => {
                            if(data.status === "Active"){
                              return (
                              <Option value={data.name} key={index}>{data.name}</Option>
                              )
                            }
                        }) : ""
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Status">
                  {props.getFieldDecorator('status', {
                    rules: [{ required: true, message: 'Please input your Status!' }],
                    initialValue: selectedRow ? selectedRow.status.toString() : ""
                  })(
                    < Select placeholder='Select a Status' showSearch>
                      <Option value='Active'>Active</Option>
                      <Option value='Inactive'>Inactive</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal} disabled={isLoading}>
                    Close
                  </Button>
                  &nbsp;
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal >
  )
}
