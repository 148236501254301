const initialData = {
  userLoading: false,
  usersData: []
}

export default function brand(state = initialData, action) {
  switch (action.type) {
    case 'GET_USERS_DATA':
      return {
        ...state,
        userLoading: true
      }
    case 'GET_USERS_DATA_FULFILLED':
      return {
        ...state,
        usersData: action.payload,
        userLoading: false
      }
    case 'GET_USERS_DATA_REJECTED':
      return {
        ...state,
        usersData: [],
        userLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
