import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Table, Icon, Tabs } from 'antd'
import './ModalView.scss'
import { numberFormat } from "../../../../utils";

const { Search, TextArea } = Input;
const ButtonGroup = Button.Group;
const { Content } = Layout
const { TabPane } = Tabs

export default class ModalForm extends Component {
  constructor() {
    super()
    this.state = {
      showRequestModal: false,
      // keyword: "",
      comments: "",
      tableData: [],
      sapData: []
    }
  }

  handleCloseModal = () => {
    this.setState({
      showRequestModal: false
    });
  };

  handleComments = e => {
    this.setState({ comments: e.target.value })
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { onSendRequest, selectedRow } = this.props;
    // const { selectedRow } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        let values = {
          status: 0,
          notif_type: 'sku replacement',
          progress: 'in process',
          description: this.state.comments,
          distributor_id: selectedRow.distributor_id,
          distributor_order_id: selectedRow.id
        }


        if (selectedRow) {
          await onSendRequest(values)
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to send Request.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Request has been sent successfully',
                  content: ''
                })
              }
            })
        }

        this.props.form.resetFields();
        this.handleCloseModal();
      }
    });
  };

  componentWillReceiveProps(nextProps) {

    const { selectedRow, orderStatusData } = this.props
    let { product } = nextProps
    product = ((nextProps.selectedRow)) ? nextProps.selectedRow.product : []
    let newNextProps = nextProps.selectedRow && (nextProps.selectedRow.product || '')
    let newThisProps = selectedRow && (selectedRow.product || '')
    if (newThisProps !== newNextProps) {
      this.setState({ tableData: product })
    }

    if (orderStatusData !== nextProps.orderStatusData) {
      this.setState({ sapData: nextProps.orderStatusData })
    }
  }



  // state = {
  //   showRequestModal: false
  // }

  // _getPartialTotalAmount() {
  //   const arrayList = this.props.selectedRow.product
  //   let partialAmount = 0
  //   arrayList.map(data => {
  //     partialAmount += data.quantity * data.selling_price;
  //   })
  //   return partialAmount;
  // }

  // footer() {
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'space-between', color: 'lightgray' }}>
  //       <h3>Total Amount</h3>
  //       <h3>$ {this._getPartialTotalAmount().toFixed(2)}</h3>
  //     </div>
  //   )
  // }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }

    const { selectedRow } = this.props

    const newArrayObj = selectedRow.product
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj })
    }
    let distributorArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
        if (isFound) distributorArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ tableData: newArrayElement })
  }

  getStatus = sapData => {
    sapData = sapData || "";
    let status;
    if (sapData["billing_document"]) status = "invoiced";
    else if (sapData["delivery"]) status = "forLoading";
    else if (sapData["sales_document"]) status = "produced";
    else if (!sapData["sales_document"] && !sapData["delivery"] && !sapData["billing_document"]) status = "forProduction";
    else status = "";
    return status;
  };


  render() {
    const { selectedRow, notifData, orderStatusData } = this.props;
    const { showRequestModal, keyword, handleSubmit, sapData } = this.state

    const row = selectedRow ? selectedRow : []

    const responses = notifData.filter((item) => {
      return item.notif_type === 'sku replacement' && item.response !== null && item.status === 1 && item.distributor_order_id === row.id
    });

    const response = responses.filter(res => {
      return res.distributor_order_id === row.id
    })

    if (selectedRow === null) {
      return "";
    }

    const callback = (tab) => {
      // 
    };

    const dataSource = this.state.tableData

    dataSource.map((data, i) => {
      data.cust_po_number = selectedRow.sales_ref_number
      data.po_number = selectedRow.po_reference
      data.so_number = selectedRow.transaction_number
      data.totalCbm = data.quantity * data.variant.cbm
      data.partialPrice = data.quantity * data.selling_price
      data.partialGrossWeight = data.quantity * data.variant.gross_weight

      data.sapStatus = []

      sapData.flatMap(sap => {
        if (sap) {
          if (data.po_number === sap.customer_po_number && data.variant.material_code === sap.material) {
            data.sapStatus.push({
              status: this.getStatus(sap)
            })
          }
        } else {
          data.sapStatus.push({
            status: 'noSap'
          })
        }
      })
    })

    const columns = [
      {
        title: 'CUSTOMER PO. NO.',
        dataIndex: 'cust_po_number',
        key: 'cust_po_number',
        align: 'center',
        sorter: (a, b) => {
          const x = a.cust_po_number.toLowerCase();
          const y = b.cust_po_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'SO NUMBER',
        dataIndex: 'so_number',
        key: 'so_number',
        sorter: (a, b) => {
          const x = a.so_number.toLowerCase();
          const y = b.so_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'PO NUMBER',
        dataIndex: 'po_number',
        key: 'po_number',
        sorter: (a, b) => {
          const x = a.po_number.toLowerCase();
          const y = b.po_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'SKU CODE',
        dataIndex: 'variant.material_code',
        key: 'material_code',
        sorter: (a, b) => {
          const x = a.variant.material_code.toLowerCase();
          const y = b.variant.material_code.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'SKU DESCRIPTION',
        dataIndex: 'product.name',
        key: 'productName',
        sorter: (a, b) => {
          const x = a.product.name.toLowerCase();
          const y = b.product.name.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (e, i) => <div>{i.product.name} {i.variant.variants.flavor}</div>
      },
      {
        title: 'PACKING SCHEME',
        dataIndex: 'variant.packing_scheme',
        key: 'packing_scheme',
        sorter: (a, b) => {
          const x = a.variant.packing_scheme.toLowerCase();
          const y = b.variant.packing_scheme.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      // {
      //   title: 'ORDER STATUS',
      //   dataIndex: 'sapStatus[0].status',
      //   key: 'status',
      //   align: 'center',
      //   sorter: (a, b) => {
      //     const x = a.sapStatus[0].status.toLowerCase();
      //     const y = b.sapStatus[0].status.toLowerCase();
      //     if (x < y) { return -1; }
      //     if (x > y) { return 1; }
      //     return 0;
      //   },
      //   render: status => {
      //     switch (status) {
      //       case 'invoiced':
      //         return (
      //           <div className='order-status-tag'>
      //             <span className='invoiced'>INVOICED</span>
      //           </div>
      //         )
      //         break;
      //       case 'forLoading':
      //         return (
      //           <div className='order-status-tag'>
      //             <span className='forLoading'>READY TO LOAD</span>
      //           </div>
      //         )
      //         break;
      //       case 'produced':
      //         return (
      //           <div className='order-status-tag'>
      //             <span className='produced'>PRODUCED</span>
      //           </div>
      //         )
      //         break;
      //       case 'forProduction':
      //         return (
      //           <div className='order-status-tag'>
      //             <span className='forProduction'>FOR PRODUCTION</span>
      //           </div>
      //         )
      //         break;

      //       default:
      //         return (
      //           <div className='order-status-tag'>
      //             <span className='forProduction'>FOR PRODUCTION</span>
      //           </div>
      //         )
      //         break;
      //     }
      //   }
      // },
      {
        title: 'ACTUAL STOCKS',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        sorter: (a, b) => {
          const x = a.quantity.toString().toLowerCase();
          const y = b.quantity.toString().toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (e, i) => <div>{i.quantity.toLocaleString()}</div>
      },
      {
        title: 'AMOUNT IN USD',
        dataIndex: 'partialTotal',
        key: 'partialTotal',
        align: 'right',
        render: (e, i) => <div>${numberFormat(i.partialPrice)}</div>
      },
      {
        title: 'CBM',
        dataIndex: 'variant.cbm',
        key: 'cbm',
        sorter: (a, b) => {
          const x = a.variant.cbm.toString().toLowerCase();
          const y = b.variant.cbm.toString().toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (e, i) => <div>{numberFormat(i.totalCbm)}</div>
      },
      {
        title: 'GROSS WEIGHT',
        dataIndex: 'partialGrossWeight',
        key: 'partialGrossWeight',
        align: 'center',
        render: (e, i) => <div>{i.partialGrossWeight.toLocaleString()}</div>
      }
    ]

    return (



      <Modal
        {...this.props}
        title="Order Management"
        visible={this.props.showFormModal}
        onCancel={this.props.handleCloseModal}
        centered={true}
        footer={null}
        width={"80%"}
        className="order-modal-container"
      >
        <Layout>
          <Content>
            {/* <Form onSubmit={this.props.handleSubmit} className="login-form"> */}
            {/* <Row type="flex" justify="center" gutter={30}>
                <Col span={24}>
                  <Form.Item label='Distributor Name'>
                    <Input value={selectedRow.distributor.name} disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Country Source'>
                    <Input value={selectedRow.distributor.country} disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Status'>
                    <Input value={selectedRow.status ? 'Active' : 'Inactive'} disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Order Number'>
                    <Input value={selectedRow.transaction_number} disabled />
                  </Form.Item>
                </Col>
              </Row> */}

            <Row type='flex' style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
              <Col className="search-container" /* style={{ display: "flex", flex: 1, justifyContent: "flex-start" }} */>
                <Search size="large" placeholder="Search" onSearch={(value) => this.handleSearch(value, [
                  'cust_po_number',
                  'po_number',
                  'so_number',
                  'quantity',
                  'selling_price',
                  'product.name',
                  'product.description',
                  'product.country_made',
                  'product.category',
                  'variant.variants.flavor',
                  'variant.variants.size',
                  'variant.material_code',
                  'variant.packing_scheme',
                  'variant.country',
                  'variant.description',
                  'variant.gross_weight',
                  'variant.name',
                  'variant.selling_price',
                  'variant.uom',
                  'totalCbm',
                  'partialPrice',
                  'partialGrossWeight',
                  'sapStatus[0].status'
                ])} style={{ margin: '10px', width: 300 }} />
              </Col>
              {/* <Col style={{ padding: 30 }} >
                <Button className="btn-small btn-primary" onClick={() => this.setState({ showRequestModal: true })} style={{ paddingTop: 5 }}>Request to Replace</Button>
              </Col> */}
            </Row>

            <Row type='flex'>
              <Tabs defaultActiveKey="1" onChange={callback} className="orders-tab">
                <TabPane tab="All" key="1">
                  <Table /* footer={() => this.footer()} */ dataSource={dataSource} rowKey='id' columns={columns} />
                </TabPane>
                <TabPane tab="In-Process" key="2">
                  <Table /* footer={() => this.footer()} */
                    dataSource={dataSource.filter(value => {
                      if (value.sapStatus.length > 0) {
                        return value.sapStatus.some(status => status['status'] === 'forProduction') ||
                          value.sapStatus.some(status => status['status'] === 'forLoading') ||
                          value.sapStatus.some(status => status['status'] === 'produced')
                      } else if (value.sapStatus.length === 0) {
                        return true
                      }
                    })}
                    columns={columns} />
                </TabPane>
                <TabPane tab="Invoiced" key="3">
                  <Table /* footer={() => this.footer()} */
                    dataSource={dataSource.filter(value => {
                      if (value.sapStatus.length > 0) {
                        return value.sapStatus.every(status => status['status'] === 'invoiced')
                      }
                    })}
                    columns={columns} />
                </TabPane>
              </Tabs>

            </Row>

            {/* </Form> */}
          </Content>
          <form>
            <Modal
              {...this.props}
              title="Update Replace SKU"
              visible={showRequestModal}
              width={"35%"}
              onCancel={() => this.setState({ showRequestModal: false })}
              closable={false}
              footer={null}
              className="replacement-modal"
            >

              <div className="comments-content">
                <h3>COMMENTS</h3>
                <TextArea className="comments-input" name="comments" value={this.state.comments} onChange={this.handleComments} rows={2} placeholder="Comments here" />
              </div>

              <Row type="flex" style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <Col><Button className="btn-small btn-default" onClick={() => this.setState({ showRequestModal: false })} style={{ marginBottom: 30 }}>Cancel</Button></Col>
                <Col><Button className="btn-small btn-primary" onClick={this.handleSubmit} style={{ marginBottom: 30 }}>Send</Button></Col>
              </Row>
            </Modal>
          </form>
        </Layout>
      </Modal>
    )
  }
}
