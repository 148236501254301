import Axios from '../../../utils/axios'

export const loadProductVariantsData = (data) => {
    return dispatch => {
        Axios.get('/admin/product-variants', dispatch, 'GET_PRODUCT_VARIANTS_DATA', data)
    }
}

export const submitAddProductVariant = (data, searchParams) => {
    return dispatch => {
        return Axios.post(`/admin/product-variant`, dispatch, 'POST_PRODUCT_VARIANTS_DATA', data)
            .then((response) => {
                dispatch(loadProductVariantsData(searchParams))
                return response
            })
    }
}

export const submitUpdateProductVariant = (data, searchParams) => {
    return dispatch => {
        return Axios.post(`/admin/product-variant/${data.id}`, dispatch, 'POST_PRODUCT_VARIANTS_DATA', data)
            .then((response) => {
                dispatch(loadProductVariantsData(searchParams))
                return response
            })
    }
}
