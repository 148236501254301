import moment from 'moment'
let initialData = {
    variantLoading: false,
    variantsData: []
}

export default function productVariantList(state = initialData, action) {
    switch (action.type) {
        case 'GET_PRODUCT_VARIANTS_DATA':
            return {
                ...state,
                variantLoading: true,
            }
        case 'GET_PRODUCT_VARIANTS_DATA_FULFILLED':
            action.payload.data.map((data, i) => {
                data.created_at = moment(data.created_at).format("MMM. DD, YYYY")
                data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
                data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
                data.status = data.status === 1 ? 'Active' : 'Inactive'
                data.is_disabled = data.is_disabled === 1 ? 'Disabled' : 'Enabled'
            })
            return {
                ...state,
                variantsData: action.payload,
                variantLoading: false
            }
        case 'GET_PRODUCT_VARIANTS_DATA_REJECTED':
            return {
                ...state,
                variantsData: '',
                variantLoading: false
            }

        case 'POST_PRODUCT_VARIANTS_DATA':
            return {
                ...state,
                variantLoading: true,
            }
        case 'POST_PRODUCT_VARIANTS_DATA_FULFILLED':
            return {
                ...state,
                variantLoading: false
            }
        case 'POST_PRODUCT_VARIANTS_DATA_REJECTED':
            return {
                ...state,
                variantLoading: false
            }

        default:
            return {
                ...state,
            }
    }
}