const initialData = {
  faqCategoryData: '',
  faqCategoryLoading: false,
  submitCategoryLoading: false
}
export default function FAQ(state = initialData, action) {
  switch (action.type) {
    case 'GET_FAQ_CATEGORY_DATA':
      return {
        ...state,
        faqCategoryLoading: true
      }
    case 'GET_FAQ_CATEGORY_DATA_FULFILLED':
      action.payload.map(data => {
        data.category = data.category.toLowerCase()
      })
      return {
        ...state,
        faqCategoryData: action.payload,
        faqCategoryLoading: false
      }
    case 'GET_FAQ_CATEGORY_DATA_REJECTED':
      return {
        ...state,
        faqCategoryData: '',
        faqCategoryLoading: false
      }
    case 'POST_SUBMIT_CATEGORY':
      return {
        ...state,
        submitCategoryLoading: true
      }
    case 'POST_SUBMIT_CATEGORY_FULFILLED':
      return {
        ...state,
        submitCategoryLoading: false
      }
    case 'POST_SUBMIT_CATEGORY_REJECTED':
      return {
        ...state,
        faqCategoryData: '',
        submitCategoryLoading: false
      }
    default:
      return {
        ...state
      };
  }
}
