import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from './Page';

import {
    fetchPermissions
} from '../../actions'

import {
    fetchDashboardData,
    fetchDistributorTotalActive
} from './actions'
class Dashboard extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        dashboardData: state.dashboard.dashboardData,
        dashboardLoading: state.dashboard.dashboardLoading,
        distributorTotalActiveData: state.dashboard.distributorTotalActiveData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        fetchDashboardData: (data) => dispatch(fetchDashboardData(data)),
        fetchDistributorTotalActive: (data) => dispatch(fetchDistributorTotalActive(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
