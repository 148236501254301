import moment from 'moment'
const initialData = {
  productData: '',
  productLoading: false,
  disabledProductLoading: false,
  updateProductLoading: false,
  brandsData: [
    {
      id: ''
    }
  ]
}
export default function product(state = initialData, action) {
  switch (action.type) {
    case 'GET_PRODUCT_DATA':
      return {
        ...state,
        productLoading: true
      }
    case 'GET_PRODUCT_DATA_FULFILLED':
      action.payload.data.map((data, i) => {
        data.created_at = moment(data.created_at).format("MMM. DD, YYYY")
        data.created_at_whole = moment(data.created_at).format("MMMM DD, YYYY")
        data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
        data.status = data.status === 1 ? 'Active' : 'Inactive'
        data.is_disabled = data.is_disabled === 1 ? 'Disabled' : 'Enabled'
      })
      return {
        ...state,
        productData: action.payload,
        productLoading: false
      }
    case 'GET_PRODUCT_DATA_REJECTED':
      return {
        ...state,
        productData: '',
        productLoading: false
      }

    case 'GET_BRANDS_DATA':
      return {
        ...state
      }
    case 'GET_BRANDS_DATA_FULFILLED':
      return {
        ...state,
        brandsData: action.payload
      }
    case 'GET_BRANDS_DATA_REJECTED':
      return {
        ...state,
        brandsData: ''
      }

    case 'POST_UPDATE_PRODUCT_DATA':
      return {
        ...state,
        productLoading: true
      }
    case 'POST_UPDATE_PRODUCT_DATA_FULFILLED':
      return {
        ...state,
        productLoading: false

      }
    case 'POST_UPDATE_PRODUCT_DATA_REJECTED':
      return {
        ...state,
        productLoading: false
      }

    case 'POST_PRODUCT_DATA':
      return {
        ...state,
        productAddLoading: true
      }
    case 'POST_PRODUCT_DATA_FULFILLED':
      return {
        ...state,
        productAddLoading: false

      }
    case 'POST_PRODUCT_DATA_REJECTED':
      return {
        ...state,
        productAddLoading: false
      }

    case 'POST_DISABLED_PRODUCT_DATA':
      return {
        ...state,
        disabledProductLoading: true
      }
    case 'POST_DISABLED_PRODUCT_DATA_FULFILLED':
      return {
        ...state,
        disabledProductLoading: false
      }
    case 'POST_DISABLED_PRODUCT_DATA_REJECTED':
      return {
        ...state,
        disabledProductLoading: false
      }
    case 'POST_UPDATE_PRODUCT_DATA':
      return {
        ...state,
        updateProductLoading: true
      }
    case 'POST_UPDATE_PRODUCT_DATA_FULFILLED':
      return {
        ...state,
        updateProductLoading: false
      }
    case 'POST_UPDATE_PRODUCT_DATA_REJECTED':
      return {
        ...state,
        updateProductLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
