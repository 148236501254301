export const numberFormat = (amount, decimalPlace = 2) => {
  if (isNaN(amount)) {
    return amount;
  }

  const pieces = parseFloat(amount)
    .toFixed(decimalPlace)
    .split("");
  let ii = pieces.length - 3;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ",");
  }

  return pieces.join("");
};
