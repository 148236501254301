const initialData = {
    myPermissionsLoading: false,
    myPermissions: []
}

export default function permissions(state = initialData, action) {
    switch (action.type) {
        case 'FETCH_PERMISSIONS':
            return {
                ...state,
                myPermissionsLoading: true
            }
        case 'FETCH_PERMISSIONS_FULFILLED':
            return {
                ...state,
                myPermissions: action.payload,
                myPermissionsLoading: false
            }
        case 'FETCH_PERMISSIONS_REJECTED':
            return {
                ...state,
                myPermissions: [],
                myPermissionsLoading: false
            }

        default:
            return {
                ...state
            }
    }
}
