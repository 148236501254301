import Axios from '../../../utils/axios'
import axios from 'axios'
import { headers } from '../../../utils/token'
import * as _ from "lodash";
import { getName } from 'country-list'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL
// export const loadContainerSummary = (data) => {
//     return dispatch => {
//         Axios.get('/admin/cms-container-summary-breakdown', dispatch, 'GET_CONTAINER_SUMMARY', data)
//     }
// }


export const loadContainerSummary = (data) => {
    return dispatch => {
        dispatch({
            type: 'GET_CONTAINER_BREAKDOWN',
            payload: {}
        })
        return axios.get(`${API_URL}/admin/cms-container-summary-breakdown`, { headers: headers })
            .then(function (response) {
                const groupBysoNumber = groupBy("sales_document"); // sales_document is equivalent to 'so number'
                const mappedGroudBySoNumber = _.map(
                    groupBysoNumber(response.data),
                    (mappedSoNumber) => mappedSoNumber
                );
                const newMappedData = []
                mappedGroudBySoNumber.map((mappedData) => {
                    newMappedData.push({
                        created_at: mappedData[0]['so_created_on'],
                        created_at_whole: moment(mappedData[0]['so_created_on']).format('MMMM DD YYYY'),
                        created_at_number: moment(mappedData[0]['so_created_on']).format('MM/DD/YYYY'),
                        so_number: mappedData[0]['sales_document'],
                        po_number: mappedData[0]['po_number'],
                        distributor_id: mappedData[0].distributor.id,
                        customer_name: mappedData[0].distributor.name,
                        customer_po_number: mappedData[0].customer_po_number,
                        country: getName(mappedData[0].distributor.country),
                        invoicedDate: mappedData[0].billing_creation_dat,
                        targetEtd: moment().add(mappedData[0].distributor.etd_month, 'M').format('YYYY-MM-DD'),
                        status: _getMappedStatus(mappedData),
                        actual_stocks: _getMappedActualStocks(mappedData),
                        amount: _getMappedAmount(mappedData),
                        // amount: 0,
                        cbm: mappedData.reduce((a, c) => a + parseFloat(c['volume']), 0), // from URC
                        gross_weight: mappedData.reduce((a, c) => a + parseFloat(c['product_variant'] ? (c['product_variant']['gross_weight'] || 0) : 0), 0),
                    })
                })
                dispatch({
                    type: 'GET_CONTAINER_BREAKDOWN_FULFILLED',
                    payload: newMappedData
                })
                return { error: false }
            })
            .catch(function (error) {
                dispatch({
                    type: 'GET_CONTAINER_BREAKDOWN_REJECTED',
                    payload: error
                })
                return { error: true }
            })
    }
}

export const loadContainerSummaryBasedOnSoNumber = (data) => {
    return dispatch => {
        dispatch({
            type: 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER',
            payload: {}
        })
        return axios.get(`${API_URL}/admin/cms-container-summary-breakdown?so_number=${data}`, { headers: headers })
            .then(function (response) {
                const newArray = []
                response.data.map((dataToStatus) => {
                    newArray.push({
                        ...dataToStatus,
                        status: getStatusLabel(dataToStatus.status)
                    })
                })
                dispatch({
                    type: 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED',
                    payload: newArray
                })
                return { error: false }
            })
            .catch(function (error) {
                dispatch({
                    type: 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED',
                    payload: error
                })
                return { error: true }
            })
    }
}

export const updateCustomerPoNumber = (data) => {
    return dispatch => {
        dispatch({
            type: 'POST_UPDATE_CUSTOMER_PO_NUMBER',
            payload: {}
        })
        return axios.post(`${API_URL}/admin/update-sap-po`, { ...data }, { headers: headers })
            .then(function (response) {
                dispatch({
                    type: 'POST_UPDATE_CUSTOMER_PO_NUMBER_FULFILLED',
                    payload: response.data
                })
                dispatch(loadContainerSummary())
                return { error: false }
            })
            .catch(function (error) {
                dispatch({
                    type: 'POST_UPDATE_CUSTOMER_PO_NUMBER_REJECTED',
                    payload: error
                })
                return { error: true }
            })
    }
}



// Global Resusable functions

const groupBy = (key) => (array) => {
    const test = [];
    return array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
};

const _getMappedStatus = (arrayData) => {
    const newStatus = []
    arrayData.map((data) => newStatus.push({ status: data.status }))
    const returnStatus = newStatus.every((data) => data.status === 'invoiced')
    let strStatus = ''
    if (returnStatus) strStatus = 'invoiced'
    else strStatus = 'in process'
    return strStatus
}

export const getStatus = data => {
    data = data || "";
    let status;
    if (data["billing_document"]) status = "invoiced";
    else if (data["delivery"]) status = "forLoading";
    else if (data["sales_document"]) status = "produced";
    else if (!data["sales_document"]) status = "forProduction";
    else status = "";
    return status;
};

const _getMappedActualStocks = (arrayData) => {
    const actualStocksArray = []
    arrayData.map((data) => actualStocksArray.push({ actualStocks: getActualStocks(getStatus(data), data) }))
    return actualStocksArray.reduce((a, c) => a + parseFloat(c['actualStocks']), 0)
}

const _getMappedAmount = (arrayData) => {
    const amountArray = []
    arrayData.map((data) => amountArray.push({ amount: (data.product_variant ? data.product_variant.distributor_pricelist.length !== 0 && data.product_variant.distributor_pricelist[0]['selling_price'] : 0) * getActualStocks(getStatus(data), data) }))
    return amountArray.reduce((a, c) => a + parseFloat(c['amount']), 0)
}

export const getActualStocks = (status, arrayData) => {
    let actualStocks;
    if (status === "produced") {
        actualStocks = arrayData["order_qty"]; // sap
    } else if (status === "forLoading") {
        actualStocks = arrayData["delivered_qty"]; // sap
    } else if (status === "invoiced") {
        actualStocks = arrayData["billed_qty"]; // sap
    }
    return actualStocks;
};

export const getStatusLabel = status => {
    let returnStr = ''
    if (status === 'invoiced') returnStr = 'Invoiced'
    else if (status === 'ready_to_load') returnStr = 'Ready To Load'
    else if (status === 'produced') returnStr = 'Produced'
    else if (status === 'forProduction') returnStr = 'For Production'
    return returnStr
};

export const fetchNotifications = (data) => {
    return dispatch => {
        Axios.get('/admin/notifications', dispatch, 'DISTRIBUTOR_NOTIFICATION', data)
    }
}

export const sendRequest = (data) => {
    return dispatch => {
        return Axios.post(`/admin/notifications`, dispatch, 'SEND_NOTIFICATION', data)
            .then((response) => {
                dispatch(fetchNotifications())
                return response
            })
    }
}