import moment from 'moment';
import { getCode, getName } from 'country-list'

const initialData = {
  contactUsData: '',
  contactUsLoading: false,
}
export default function contact(state = initialData, action) {
  switch (action.type) {
    case 'GET_CONTACT_US_DATA':
      return {
        ...state,
        contactUsLoading: true
      }
    case 'GET_CONTACT_US_DATA_FULFILLED':
      action.payload.map((data, i) => {
        let newStatus
        if (data.status === 1) {
          newStatus = 'New'
        } else if (data.status === 2) {
          newStatus = 'Existing'
        } else {
          newStatus = 'NoStatus'
        }
        data.created_at = moment(data.created_at).format("MMM. DD, YYYY")
        data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
        data.created_at_number = moment(data.created_at).format("MM/DD/YYYY")
        data.status = newStatus
        data.country = getName(data.country)
      })
      return {
        ...state,
        contactUsData: action.payload,
        contactUsLoading: false
      }
    case 'GET_CONTACT_US_DATA_REJECTED':
      return {
        ...state,
        contactUsData: '',
        contactUsLoading: false
      }
    default:
      return {
        ...state
      }
  }
}
