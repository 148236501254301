import React, { Component } from 'react';
import { Form, Icon, Button, Layout, Row, Col, Table, Input, Skeleton, Result } from 'antd';
import ModalForm from './components/ModalForm';
import './index.scss';

const { Content } = Layout;
const { Search } = Input;

class UserTypes extends Component {
  state = {
    showFormModal: false,
    keyword: "",
    selectedRow: {}
  }


  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };


  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: {}
    });
  };

  handleEdit = value => {
    this.setState({ selectedRow: value, showFormModal: true })
  }


  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadUserRolesData()
    this.props.onloadPermissionsData()
  }


  render() {
    const { showFormModal, keyword, selectedRow } = this.state
    const {
      myPermissions, myPermissionsLoading,
      rolesLoading, roles,
      permissions, permissionsLoading
    } = this.props

    let dataSource = roles

    dataSource = dataSource.filter(value =>
      (value.id.toString() || "").toLowerCase().includes(keyword.toLowerCase()) ||
      (value.name || "").toLowerCase().includes(keyword.toLowerCase()) ||
      (value.description || "").toLowerCase().includes(keyword.toLowerCase()) ||
      (value.status === 1 ? 'active' : 'inactive' || "").toString().toLowerCase().includes(keyword.toLowerCase())
    )

    const columns = [
      {
        title: 'USER TYPE ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'USER TYPE',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => (a.description || "").length - (b.description || "").length,
        render: (value) => value || "N/A"
      },
      {
        title: 'STATUS',
        dataIndex: 'permissions',
        key: 'permissions',
        align: 'center',
        sorter: (a, b) => a.permissions.length - b.permissions.length,
        render: (value) =>
          <span className={`${value ? "active" : "inactive"}-tag`} >{value ? "active" : "inactive"}</span>
      },
      {
        title: '',
        key: 'action',
        render: (value) =>
          <Button
            disabled={!myPermissions.some(r => r.name === 'admin.user_types.edit')}
            style={{
              border: "none",
              background: "transparent"
            }}
            onClick={() => this.handleEdit(value)}
          >
            <Icon type="edit" style={{ fontSize: "18px" }} />
          </Button>
      }
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.user_types.view') ? (
      <Layout className="user-types-page">
        <Content style={{ padding: 25 }}>
          <Row type="flex" align="middle" style={{ margin: "20px 0px" }}>
            <Col span={12}>
              <h1 style={{ fontSize: 24 }}>User Types</h1>
            </Col>
            <Col span={12} align="right">
              <Button
                disabled={!myPermissions.some(r => r.name === 'admin.user_types.add')}
                type="primary"
                onClick={this.handleShowFormModal}
                style={{ margin: '10px', height: 40 }} ><Icon type="plus" />Add New User Type</Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='user-types-container'>
                <Table
                  loading={rolesLoading}
                  title={() =>
                    (<Row>
                      <Col span={8}>
                        {/* <Input prefix={<Icon type="search" />} placeholder="Search" style={{ width: "100%" }} onChange={(e) => this.setState({ keyword: e.target.value })} /> */}
                        <Search className='search-container' size='large' placeholder="Search" onChange={(e) => this.setState({ keyword: e.target.value })} />

                      </Col>
                    </Row>)
                  }
                  rowKey="id"
                  dataSource={dataSource}
                  columns={columns}
                />
              </div>
            </Col>
          </Row>
        </Content>

        <ModalForm
          onSubmitAddUserRole={this.props.onSubmitAddUserRole}
          onSubmitUpdateUserRole={this.props.onSubmitUpdateUserRole}
          roles={roles}
          permissionsLoading={permissionsLoading}
          permissions={permissions}
          showFormModal={showFormModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </Layout>

    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const UserTypesForm = Form.create({ name: 'user-types_form' })(UserTypes);

export default UserTypesForm;
