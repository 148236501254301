import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadProductData,
    submitAddProduct,
    submitUpdateProduct,
    submitDisableProduct
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import { loadActiveBrandsData } from '../Brands/actions'
import Page from './Page'

class Product extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const ProductForm = Form.create({ name: 'product_form' })(Product);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        productData: state.product.productData,
        productLoading: state.product.productLoading,
        productAddLoading: state.product.productAddLoading,
        brandsData: state.product.brandsData,
        disabledProductLoading: state.product.disabledProductLoading,
        updateProductLoading: state.product.updateProductLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadProductData: (data) => dispatch(loadProductData(data)),
        onLoadActiveBrandsData: (data) => dispatch(loadActiveBrandsData(data)),
        onSubmitAddProduct: (data, searchParams) => dispatch(submitAddProduct(data, searchParams)),
        onSubmitUpdateProduct: (data, searchParams) => dispatch(submitUpdateProduct(data, searchParams)),
        onSubmitDisableProduct: (data) => dispatch(submitDisableProduct(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
