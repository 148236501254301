import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input, Switch, Tabs, Spin, message, Empty, Popconfirm } from 'antd'
import { connect } from 'react-redux'
import {
  submitEditPriceList,
  loadProductVariantData,
  searchProductVariant,
  submitShowHideVariant
} from '../../actions';
const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

class ModaPricelistForm extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      products: [],
      constantProduct: [],
      keyword: '',
      activeKey: 0,
      searchKey: '',
      isSearching: false
    }
  }

  // handleFetchPricelistByProduct = (productID, distributorID) => {
  //   const { onFetchPricelistByProduct } = this.props
  //   onFetchPricelistByProduct({ product_id: productID, distributor_id: distributorID })
  // }

  handleProductVariantData = (productID, distributorID) => {

    const { onLoadProductVariantData } = this.props

    onLoadProductVariantData({ product_id: productID, distributor_id: distributorID })

  }

  handleSearchProductVariant = (searchKeyword) => {

    const { onSearchProductVariant } = this.props

    onSearchProductVariant({ search: searchKeyword })

  }

  // handleSpecialCodeInput = (input) => {
  //   // this.setState({ specialCodeInput: input })
  // }

  // handleSellingPriceInput = (input) => {
  //   this.setState({ sellingPriceInput: input })
  // }

  // handleStatusInput = (input) => {
  //   this.setState({ statusInput: input })
  // }


  handleEditPricelist = (e) => {
    e.preventDefault()
    const { selectedRow: { id } } = this.props
    const { pricelistByProductData, onSubmitEditPriceList, form } = this.props
    form.validateFields(async (err, values) => {
      if (!err) {
        let returnData = pricelistByProductData && pricelistByProductData.map((data, i) => {

          return {
            distributor_id: id,
            product_id: data.id,
            product_variant_id: data.pricelist[0].product_variant_id,
            //selling_price: (values[count].selling_price !== '') ? values[count].selling_price : 0,
            selling_price: values[data.id] ? parseFloat(values[data.id].selling_price) : 0,
            // special_code: values[count].special_code,
            special_code: values[data.id] ? values[data.id].special_code : "",
            // status: values[count].status
            status: values[data.id] ? values[data.id].status : false
          }
        })

        this.setState({
          isLoading: true
        });

        onSubmitEditPriceList({ pricelist: returnData })
          .then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to update Pricelist.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Pricelist has been updated successfully',
                content: ''
              })
            }

            this.setState({
              isLoading: false
            })
          })
      }
    })
  }

  // handleSearch(e) {
  //   this.setState({ keyword: e })
  // }

  handleSearch = (searchTerm, searchKeys) => {
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }
    let { constantProduct, products } = this.state
    const newArrayObj = constantProduct
    if (!searchTerm) {
      constantProduct.map(x => x.variant.map(y => y.visible = true))
      return this.setState({ products: constantProduct, keyword: searchTerm })
    } else {
      // const test = constantProduct.map(x => { x.variant.map(y => y.visible = true) })
      products = constantProduct
    }

    let newArray = []
    products.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        if (Array.isArray(rowItemKeys)) {
          rowItemKeys.forEach((variant, x) => {
            searchKeys.forEach(keys => {
              let rowItemKeys_1 = convertStringDotNotation(keys, { ...variant })
              let isFound = (rowItemKeys_1 && !Array.isArray(rowItemKeys_1)) && rowItemKeys_1.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
              if (isFound) {
                newArray.push(rowItem)
                variant.visible = true
              } else {
                variant.visible = false
              }
            })
          })
        } else {
          // rowItem.variant.map(x => x.visible = true)
        }

        let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
        if (isFound) {
          // rowItem.variant.map(x => x.visible = true)
          newArray.push(rowItem)
        }
      })
    })
    const newArrayElement = newArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ products: newArrayElement, keyword: searchTerm })
  }

  // componentDidMount() {
  //   this.setState({ activeKey: this.props.productsData[0].currentActiveKey })
  // }


  componentWillReceiveProps(nextProps) {
    if (this.props.productVariantData !== nextProps.productVariantData) {
      let products = [];
      let productIds = [];
      const productVariant = nextProps.productVariantData
      nextProps.productVariantData && nextProps.productVariantData.forEach((row) => {
        if (productIds.indexOf(row.product.id) === -1) {
          products.push(row.product)
          productIds.push(row.product.id)
        }
      });

      products.map(rowProduct => {
        const variant = productVariant.filter(rowVariant => rowVariant.product_id === rowProduct.id)
        rowProduct.variant = variant
      })
      products.map(x => x.variant.map(y => y.visible = true))

      this.setState({ products: products, constantProduct: products })
    }
  }


  handleOnChangeInputActive(inputClassName, errorMessageId) {
    if (document.getElementsByClassName(inputClassName)[0].readOnly === true) {
      document.getElementsByClassName(inputClassName)[0].readOnly = false
      document.getElementsByClassName(inputClassName)[0].style.background = 'white'
      document.getElementById(errorMessageId).style.display = "none";
    } else {
      document.getElementsByClassName(inputClassName)[0].readOnly = true
      document.getElementById(errorMessageId).style.display = "block";
      document.getElementsByClassName(inputClassName)[0].style.background = '#F5F5F5'
    }
  }

  numberFormat = (amount, decimalPlace = 2) => {
    if (isNaN(amount)) {
      return amount;
    }

    const pieces = parseFloat(amount)
      .toFixed(decimalPlace)
      .split("");
    let ii = pieces.length - 3;
    while ((ii -= 3) > 0) {
      pieces.splice(ii, 0, ",");
    }

    return pieces.join("");
  };

  render() {
    const {
      selectedRow,
      productVariantData,
      distributorProductVariantLoading,
      productsData,
      productsLoading,
      pricelistByProductData,
      pricelistByProductLoading,
      searchProductVariantLoading,

    } = this.props;

    const {
      specialCodeInput,
      sellingPriceInput,
      statusInput,
      activeKey,
      isSearching
    } = this.state

    const { isLoading, keyword } = this.state;


    return (
      // <Spin spinning={isLoading}>
      <Modal
        destroyOnClose={true}
        visible={this.props.showFormModal}
        title={`Distributor Pricelist (${selectedRow !== null && selectedRow.name})`}
        onCancel={this.props.handleCloseModal}
        centered
        footer={null}
        width={900}
      >
        <Layout>
          <Content className='contact-form-container'>
            <Row style={{ width: '100%' }}>
              <Col md={24} align='right'>
                <Search
                  placeholder="Search"
                  // onSearch={(value) => this.handleSearch(value, ['name', 'variant', 'material_code', 'packing_scheme', 'priceList.special_code'])}
                  onSearch={async (value) => {
                    await this.setState({ searchKey: value, isSearching: true })
                    if (value) {
                      await this.props.onSearchProductVariant({ search: value, distributor_id: selectedRow.id })
                      await this.setState({ isSearching: false })
                    } else {
                      await this.handleProductVariantData(productsData[activeKey].id, selectedRow.id)
                      await this.setState({ isSearching: false })
                    }


                    // if (value === '') {
                    //   this.handleProductVariantData(1, this.props.selectedRow.id)
                    // }



                    // .then((response) => {
                    // if (response.error) {
                    //   message.warning('Unable to update Pricelist.')
                    // } else {
                    //   message.success('Pricelist has been updated successfully')
                    //   this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                    // }
                    // this.setState({
                    //   isLoading: false
                    // })
                    // })
                    // pricelistByProductData = this.handleSearchProductVariant(value)
                  }}
                  // onChange={(value) => {
                  //   this.setState({ keyword: value.target.value })
                  // }}
                  // value={this.state.keyword}
                  style={{ margin: '10px', width: 400 }}
                />
                {/* {this.state.searchKey.length === 0
                  ? (
                      <Search
                        placeholder="Search"
                        // onSearch={(value) => this.handleSearch(value, ['name', 'variant', 'material_code', 'packing_scheme', 'priceList.special_code'])}
                        onSearch={(value) => {
                          this.setState({ searchKey: value, isLoading: true })
                          if (value) {
                            this.props.onSearchProductVariant({ search: value, distributor_id: selectedRow.id })
                            this.setState({ isLoading: false })
                          }

                            this.handleProductVariantData(activeKey, selectedRow.id)
                            this.setState({ isLoading: false })
                          // if (value === '') {
                          //   this.handleProductVariantData(1, this.props.selectedRow.id)
                          // }
                          

                          
                            // .then((response) => {
                              // if (response.error) {
                              //   message.warning('Unable to update Pricelist.')
                              // } else {
                              //   message.success('Pricelist has been updated successfully')
                              //   this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                              // }
                              // this.setState({
                              //   isLoading: false
                              // })
                            // })
                          // pricelistByProductData = this.handleSearchProductVariant(value)
                        }}
                        // onChange={(value) => {
                        //   this.setState({ keyword: value.target.value })
                        // }}
                        // value={this.state.keyword}
                        style={{ margin: '10px', width: 400 }}
                      />
                    )
                  : (
                    <Button
                      style={{ fontWeight: 700, fontSize: '1.2rem', margin: '5%' }}
                      onClick={async () => {
                        this.setState({ searchKey: '' })
                        await this.handleProductVariantData(productData[activeKey].id, selectedRow.id)
                      }}
                    >&lt; Go Back</Button>
                  )
                } */}
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Spin spinning={pricelistByProductLoading || searchProductVariantLoading || isLoading}>


                {pricelistByProductData.length === 0 && !isSearching && !isLoading && !pricelistByProductLoading
                  ?
                  (
                    <Col style={{ display: 'flex', flex: 1, justifyContent: "center", height: 550 }}>
                      <Row style={{ display: 'flex', flex: 1, flexDirection: "column", justifyContent: "center" }}>
                        <div>
                          <Empty />
                          <h4>You may have entered an invalid keyword or search term. Please try again.</h4>
                        </div>
                      </Row>
                    </Col>
                  )
                  : pricelistByProductData.length > 0
                    ?
                    ''
                    :
                    (
                      <Col style={{ display: 'flex', flex: 1, justifyContent: "center", height: 550 }}>
                        <Row style={{ display: 'flex', flex: 1, flexDirection: "column", justifyContent: "center" }}>
                          <Empty />
                        </Row>
                      </Col>
                    )
                }
                <Tabs
                  defaultActiveKey="0"
                  tabPosition={"left"}
                  style={{ width: '100%', height: 550, position: "relative", display: pricelistByProductData.length === 0 ? 'none' : 'flex' }}
                  tabBarStyle={{ display: this.state.searchKey.length > 0 ? 'none' : 'flex', width: '20%', whiteSpace: 'normal' }}
                  onChange={async (activeKey) => {
                    await this.setState({ keyword: '', activeKey: parseInt(activeKey) })
                    await this.handleProductVariantData(productsData[activeKey].id, this.props.selectedRow.id)
                  }}
                // renderTabBar={renderTabBar}
                >
                  {productsData && productsData.map((row, index) => (
                    <TabPane tab={`${row.name}`} key={index}>
                      <Row style={{ paddingRight: '14px' }}>
                        <Col span={12} style={{ display: this.state.searchKey.length > 0 ? 'none' : 'flex' }}>
                          <span>
                            <h2 style={{ marginTop: '6px', marginBottom: 0 }}>{row.name}</h2>
                            <h4>({row.country_made})</h4>
                          </span>
                        </Col>
                        <Col span={12} style={{ display: this.state.searchKey.length > 0 ? 'flex' : 'none' }}>
                          <h2 style={{ marginTop: '6px' }}>Found ({pricelistByProductData.filter(pricelist => pricelist.status === 1).length}) Result{pricelistByProductData.filter(pricelist => pricelist.status === 1).length > 1 ? 's' : ''}:</h2>
                        </Col>
                        <Col span={12}>
                          {/* <Search
                            placeholder="Search"
                            // onSearch={(value) => this.handleSearch(value)}
                            onSearch={(value) => {
                              if (value === '') {

                                this.handleProductVariantData(row.id, this.props.selectedRow.id)
                              }
                              this.props.onSearchProductVariant({ search: value })
                              
                            }}
                            // onChange={(value) => this.setState({ keyword: value })}
                            // value={this.state.keyword}
                            style={{ margin: '10px' }}
                            enterButton /> */}
                        </Col>
                      </Row>



                      <Row type="flex" gutter={10} style={{ display: pricelistByProductData.length === 0 || pricelistByProductData.every(data => data.status === 0) ? 'none' : 'flex' }}>
                        <Col span={6}>
                          <b>Variant</b>
                        </Col>

                        <Col span={6}>
                          <b>Customer Code</b>
                        </Col>

                        <Col span={6}>
                          <b>Selling Price</b>
                        </Col>

                        <Col span={3} align="center">
                          {/* <b>Order Now</b> */}
                          <b>Status</b>
                        </Col>

                        {/* UNCOMMENT HERE TO UNHIDE SHOW/HIDE PRODUCT CATALOG 
                        <Col span={3} align="center">
                          <b>Product Catalog</b>
                        </Col> */}
                      </Row>

                      {
                        pricelistByProductData.every(data => data.status === 0)
                          ? (
                            <Row type="flex" gutter={10} key={index} style={{ width: 550, display: pricelistByProductData.length === 0 ? 'none' : 'flex', flex: 1, justifyContent: 'center' }}>
                              <div>
                                <h3>No Active Product Variants</h3>
                                <Empty />
                              </div>
                            </Row>
                          )
                          : ''
                      }

                      <Form onSubmit={this.handleEditPricelist} className="login-form" key={selectedRow.id}>

                        <Row type="flex" style={{ height: 400, overflowX: "hidden" }}>
                          <Col span={24}>



                            {pricelistByProductData && pricelistByProductData.filter(data => data.status === 1).map((variant, i) => {


                              return (
                                <Row type="flex" gutter={10} key={`row-${selectedRow.id}-${variant.id}-${i}`}
                                // style={{ display: !visible ? 'none' : 'flex', width: '100%' }}
                                // style={{ display: !variant.visible ? 'none' : 'flex', width: '100%' }}
                                >
                                  <Col span={6} key={`special_code_col-${selectedRow.id}-${variant.id}-${i}`}>
                                    <b>{variant.material_code}</b> - {variant.name} - <span style={{ whiteSpace: 'nowrap' }}>{variant.packing_scheme}</span>
                                  </Col>

                                  <Col span={6}>
                                    <Form.Item key={`special_code_form-${selectedRow.id}-${variant.id}-${i}`} >
                                      <Input
                                        // key={`special_code-${selectedRow.id}-${variant.id}-${i}`}
                                        // defaultValue={variant && variant.pricelist && variant.pricelist.length !== 0 ? variant.pricelist[0].special_code : ""}
                                        // value={variant && variant.pricelist && variant.pricelist.length !== 0 ? variant.pricelist[0].special_code : ""}
                                        placeholder={variant && variant.pricelist && variant.pricelist.length !== 0 ? variant.pricelist[0].special_code : ""}
                                        onBlur={async (e) => {

                                          if (e.target.value.length === 0 && variant.pricelist.length === 0) {
                                            message.warning('Code not saved.')
                                          } else if (variant.pricelist.length !== 0 && e.target.value === variant.pricelist[0].special_code) {
                                            message.warning('No changes found.')
                                          } else if (variant.pricelist.length !== 0 && e.target.value === 0) {
                                            let returnData = {}

                                            variant && variant.pricelist && variant.pricelist.length !== 0
                                              ? returnData = {
                                                pricelist_id: variant.pricelist[0].id,
                                                product_id: variant.pricelist[0].product_id,
                                                product_variant_id: variant.pricelist[0].product_variant_id,
                                                distributor_id: variant.pricelist[0].distributor_id,
                                                special_code: !e.target.value ? '' : e.target.value
                                              }
                                              : returnData = {
                                                product_id: variant.product_id,
                                                product_variant_id: variant.id,
                                                distributor_id: selectedRow.id,
                                                special_code: !e.target.value ? '' : e.target.value
                                              }

                                            if (e.target.value.length === 0) {

                                              Modal.confirm({
                                                title: 'Would you like to proceed?',
                                                content: `You are about to clear ${variant.material_code}'s Customer Code.`,

                                                okText: 'Yes',
                                                onOk: () => {

                                                  this.setState({
                                                    isLoading: true
                                                  });

                                                  this.props.onSubmitEditPriceList(returnData)
                                                    .then((response) => {
                                                      if (response.error) {
                                                        message.warning('Unable to update Pricelist.')
                                                      } else {
                                                        message.success('Pricelist has been updated successfully')
                                                      }
                                                      this.setState({
                                                        isLoading: false
                                                      })
                                                    })

                                                  this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                                },

                                                cancelText: 'No',
                                                onCancel: () => {
                                                  message.warning('Code not saved.')
                                                },

                                              })


                                            } else {
                                              this.setState({
                                                isLoading: true
                                              });

                                              await this.props.onSubmitEditPriceList(returnData)
                                                .then((response) => {
                                                  if (response.error) {
                                                    message.warning('Unable to update Pricelist.')
                                                  } else {
                                                    message.success('Pricelist has been updated successfully')
                                                  }
                                                  this.setState({
                                                    isLoading: false
                                                  })
                                                })

                                              await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                            }
                                          } else {

                                            let returnData = {}

                                            variant && variant.pricelist && variant.pricelist.length !== 0
                                              ? returnData = {
                                                pricelist_id: variant.pricelist[0].id,
                                                product_id: variant.pricelist[0].product_id,
                                                product_variant_id: variant.pricelist[0].product_variant_id,
                                                distributor_id: variant.pricelist[0].distributor_id,
                                                special_code: !e.target.value ? '' : e.target.value
                                              }
                                              : returnData = {
                                                product_id: variant.product_id,
                                                product_variant_id: variant.id,
                                                distributor_id: selectedRow.id,
                                                special_code: !e.target.value ? '' : e.target.value
                                              }

                                            if (e.target.value.length === 0) {

                                              Modal.confirm({
                                                title: 'Would you like to proceed?',
                                                content: `You are about to clear ${variant.material_code}'s Customer Code.`,

                                                okText: 'Yes',
                                                onOk: () => {

                                                  this.setState({
                                                    isLoading: true
                                                  });

                                                  this.props.onSubmitEditPriceList(returnData)
                                                    .then((response) => {
                                                      if (response.error) {
                                                        message.warning('Unable to update Pricelist.')
                                                      } else {
                                                        message.success('Pricelist has been updated successfully')
                                                      }
                                                      this.setState({
                                                        isLoading: false
                                                      })
                                                    })

                                                  this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                                },

                                                cancelText: 'No',
                                                onCancel: () => {
                                                  message.warning('Code not saved.')
                                                },

                                              })


                                            } else {
                                              this.setState({
                                                isLoading: true
                                              });

                                              await this.props.onSubmitEditPriceList(returnData)
                                                .then((response) => {
                                                  if (response.error) {
                                                    message.warning('Unable to update Pricelist.')
                                                  } else {
                                                    message.success('Pricelist has been updated successfully')
                                                  }
                                                  this.setState({
                                                    isLoading: false
                                                  })
                                                })

                                              await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                            }
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item key={`selling_price_form-${variant.id}-${i}`}  >
                                      <Input
                                        key={`selling_price-${variant.id}-${i}`}
                                        className={`isActiveInput-${i}`}
                                        readOnly={variant && variant.pricelist && variant.pricelist.length !== 0 ? parseInt(variant.pricelist[0].status) === 1 : false}
                                        disabled={variant && variant.pricelist && variant.pricelist.length !== 0 ? parseInt(variant.pricelist[0].status) === 1 : false}
                                        style={{ background: variant && variant.pricelist && variant.pricelist.length !== 0 ? (parseInt(variant.pricelist[0].status) === 1 ? '#F5F5F5' : 'white') : 'white' }}
                                        // defaultValue={variant && variant.pricelist && variant.pricelist.length !== 0
                                        //   ? (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) === 1
                                        //     ? this.numberFormat(variant.pricelist[0].selling_price)
                                        //     : (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) >= 2
                                        //       ? variant.pricelist[0].selling_price
                                        //       : variant.pricelist[0].selling_price
                                        //   : 0
                                        // }
                                        // value={variant && variant.pricelist && variant.pricelist.length !== 0
                                        //   ? (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) === 1
                                        //     ? this.numberFormat(variant.pricelist[0].selling_price)
                                        //     : (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) >= 2
                                        //       ? variant.pricelist[0].selling_price
                                        //       : variant.pricelist[0].selling_price
                                        //   : 0
                                        // }
                                        placeholder={variant && variant.pricelist && variant.pricelist.length !== 0
                                          ? (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) === 1
                                            ? this.numberFormat(variant.pricelist[0].selling_price)
                                            : (Math.floor(variant.pricelist[0].selling_price) === variant.pricelist[0].selling_price ? 0 : variant.pricelist[0].selling_price.toString().split(".")[1].length || 0) >= 2
                                              ? variant.pricelist[0].selling_price
                                              : variant.pricelist[0].selling_price
                                          : 0
                                        }
                                        onBlur={async (e) => {


                                          // if no pricelist data
                                          if (variant.pricelist.length === 0) {

                                            // Blank or 0 input, no pricelist data: show confirmation modal
                                            if (e.target.value.length === 0 || Number(e.target.value) === 0) {

                                              let returnData = {
                                                product_id: variant.product_id,
                                                product_variant_id: variant.id,
                                                distributor_id: selectedRow.id,
                                                selling_price: 0
                                              }

                                              Modal.confirm({
                                                title: 'Would you like to proceed?',
                                                content: `You are about to add a Selling Price for ${variant.material_code} which will be set to 0.`,

                                                okText: 'Yes',
                                                onOk: () => {

                                                  this.setState({
                                                    isLoading: true
                                                  });

                                                  this.props.onSubmitEditPriceList(returnData)
                                                    .then((response) => {
                                                      if (response.error) {
                                                        message.warning('Unable to update Pricelist.')
                                                      } else {
                                                        message.success('Pricelist has been updated successfully')
                                                      }
                                                      this.setState({
                                                        isLoading: false
                                                      })
                                                    })

                                                  this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                                                },

                                                cancelText: 'No',
                                                onCancel: () => {
                                                  message.warning('Price not saved.')
                                                },

                                              })

                                              // 1-9 input, no pricelist data: auto-save
                                            } else {

                                              let returnData = {
                                                product_id: variant.product_id,
                                                product_variant_id: variant.id,
                                                distributor_id: selectedRow.id,
                                                selling_price: parseFloat(e.target.value)
                                              }

                                              if (!/[0-9]/.test(e.target.value)) {

                                                message.error('Please input numbers only.');
                                                return e.target.value = ''

                                              } else {

                                                this.setState({
                                                  isLoading: true
                                                });

                                                await this.props.onSubmitEditPriceList(returnData)
                                                  .then((response) => {
                                                    if (response.error) {
                                                      message.warning('Unable to update Pricelist.')
                                                    } else {
                                                      message.success('Pricelist has been updated successfully')
                                                    }
                                                    this.setState({
                                                      isLoading: false
                                                    })
                                                  })

                                                await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                              }

                                            }

                                            // if has pricelist data
                                          } else if (variant.pricelist.length !== 0) {

                                            // Blank or 0 input, has pricelist data: show confirmation modal
                                            if (e.target.value.length === 0 || Number(e.target.value) === 0) {

                                              if (variant.pricelist[0].selling_price === Number(e.target.value)) {
                                                message.warning('No changes found.');

                                              } else {
                                                let returnData = {
                                                  pricelist_id: variant.pricelist[0].id,
                                                  product_id: variant.pricelist[0].product_id,
                                                  product_variant_id: variant.pricelist[0].product_variant_id,
                                                  distributor_id: variant.pricelist[0].distributor_id,
                                                  selling_price: 0
                                                }

                                                Modal.confirm({
                                                  title: 'Would you like to proceed?',
                                                  content: `You are about to set ${variant.material_code}'s Selling Price to 0.`,

                                                  okText: 'Yes',
                                                  onOk: () => {

                                                    this.setState({
                                                      isLoading: true
                                                    });

                                                    this.props.onSubmitEditPriceList(returnData)
                                                      .then((response) => {
                                                        if (response.error) {
                                                          message.warning('Unable to update Pricelist.')
                                                        } else {
                                                          message.success('Pricelist has been updated successfully')
                                                        }
                                                        this.setState({
                                                          isLoading: false
                                                        })
                                                      })

                                                    this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)

                                                  },

                                                  cancelText: 'No',
                                                  onCancel: () => {
                                                    message.warning('Price not saved.')
                                                  },

                                                })
                                              }
                                              // 1-9 input, has pricelist data: auto-save
                                            } else {

                                              let returnData = {
                                                pricelist_id: variant.pricelist[0].id,
                                                product_id: variant.pricelist[0].product_id,
                                                product_variant_id: variant.pricelist[0].product_variant_id,
                                                distributor_id: variant.pricelist[0].distributor_id,
                                                selling_price: parseFloat(e.target.value)
                                              }

                                              if (!/[0-9]/.test(e.target.value)) {
                                                message.error('Please input numbers only.');
                                                return e.target.value = ''
                                              }

                                              // if same input and existing value
                                              if (variant.pricelist.length !== 0 && variant.pricelist[0].selling_price === Number(e.target.value)) {
                                                message.warning('No changes found.');

                                              } else {
                                                this.setState({
                                                  isLoading: true
                                                });

                                                await this.props.onSubmitEditPriceList(returnData)
                                                  .then((response) => {
                                                    if (response.error) {
                                                      message.warning('Unable to update Pricelist.')
                                                    } else {
                                                      message.success('Pricelist has been updated successfully')
                                                    }
                                                    this.setState({
                                                      isLoading: false
                                                    })
                                                  })

                                                await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                                              }

                                            }
                                          }


                                        }}
                                      />
                                      <div key={`selling_price_status-${variant.id}-${i}`} id={`inputErrorMessage-${i}`} style={{ fontSize: 9, color: 'red', marginLeft: 5, marginTop: -18, display: variant && variant.pricelist && variant.pricelist.length !== 0 ? (parseInt(variant.pricelist[0].status) === 1 ? 'block' : 'none') : 'none' }}>
                                        <span>Unable to edit price, status is active</span>
                                      </div>
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} align="center">
                                    <Form.Item key={`status-form-${selectedRow.id}-${variant.id}-${i}`}>
                                      <Switch
                                        key={`status-${selectedRow.id}-${variant.id}-${i}`}
                                        checked={variant && variant.pricelist && variant.pricelist.length !== 0 ? (parseInt(variant.pricelist[0].status) === 1 ? true : false) : false}
                                        checkedChildren="Show"
                                        unCheckedChildren="Hide"
                                        // onClick={() => this.handleOnChangeInputActive(`isActiveInput-${i}`, `inputErrorMessage-${i}`)}
                                        disabled={variant && variant.pricelist && variant.pricelist.length === 0}
                                        // onClick={(e) => {
                                        //   if (variant && !variant.pricelist && variant.pricelist.length === 0) {
                                        //     message.warning('Please set the Customer Code or Selling Price first.')
                                        //     e = false
                                        //   }
                                        // }}
                                        onChange={async (e) => {
                                          this.handleOnChangeInputActive(`isActiveInput-${i}`, `inputErrorMessage-${i}`)

                                          if (variant && variant.pricelist.length !== 0) {
                                            let returnData = {}

                                            variant && variant.pricelist && variant.pricelist.length !== 0
                                              ? returnData = {
                                                pricelist_id: variant.pricelist[0].id,
                                                product_id: variant.pricelist[0].product_id,
                                                product_variant_id: variant.pricelist[0].product_variant_id,
                                                distributor_id: variant.pricelist[0].distributor_id,
                                                status: e === true ? 1 : 0
                                              }
                                              : returnData = {
                                                product_id: variant.product_id,
                                                product_variant_id: variant.id,
                                                distributor_id: selectedRow.id,
                                                status: e === true ? 1 : 0
                                              }

                                            this.setState({
                                              isLoading: true
                                            });

                                            await this.props.onSubmitEditPriceList(returnData)
                                              .then((response) => {
                                                if (response.error) {
                                                  message.warning('Unable to update Pricelist.')
                                                } else {
                                                  message.success('Pricelist has been updated successfully')

                                                }
                                                this.setState({
                                                  isLoading: false
                                                })
                                              })

                                            await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                                          } else {
                                            message.warning('Please set the Customer Code or Selling Price first.')
                                            e = false
                                          }
                                        }
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} align="center">
                                    <Form.Item key={`is_hidden-form-${selectedRow.id}-${variant.id}-${i}`}>
                                      {/* UNCOMMENT HERE TO UNHIDE PRODUCT CATALOG SHOW/HIDE
                                       <Switch
                                        key={`is_hidden-${selectedRow.id}-${variant.id}-${i}`}
                                        checked={variant && variant.pricelist && variant.pricelist.length !== 0 ? (parseInt(variant.pricelist[0].is_hidden) === 0 ? true : false) : true}
                                        checkedChildren="Show"
                                        unCheckedChildren="Hide"
                                        // onClick={() => this.handleOnChangeInputActive(`isActiveInput-${i}`, `inputErrorMessage-${i}`)}
                                        disabled={variant && variant.pricelist && variant.pricelist.length === 0}
                                        // onClick={(e) => {
                                        //   if (variant && !variant.pricelist && variant.pricelist.length === 0) {
                                        //     message.warning('Please set the Customer Code or Selling Price first.')
                                        //     e = false
                                        //   }
                                        // }}
                                        onChange={async (e) => {

                                          if (variant && variant.pricelist.length !== 0) {
                                            let returnData = {
                                              pricelist_id: variant.pricelist[0].id,
                                              distributor_id: variant.pricelist[0].distributor_id,
                                              is_hidden: e === false ? 1 : 0,
                                              name: variant.name,
                                              packing_scheme: variant.packing_scheme
                                            }

                                            this.setState({
                                              isLoading: true
                                            });

                                            await this.props.onSubmitShowHideVariant(returnData)
                                              .then((response) => {
                                                if (response.error) {
                                                  message.warning('Unable to update Pricelist.')
                                                } else {
                                                  if (returnData.is_hidden === 1) {
                                                    message.info(`All ${returnData.name} - ${returnData.packing_scheme} is now hidden.`)
                                                  } else {
                                                    message.info(`All ${returnData.name} - ${returnData.packing_scheme} is now shown.`)
                                                  }
                                                }
                                                this.setState({
                                                  isLoading: false
                                                })
                                              })

                                            await this.handleProductVariantData(variant.product_id, this.props.selectedRow.id)
                                          } else {
                                            message.warning('Please set the Customer Code or Selling Price first.')
                                            e = false
                                          }
                                        }

                                        }
                                      /> */}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              );
                              // }
                            })}

                            {/* <Col span={7}>
                            <b>{variant.material_code}</b> - {variant.name} - <span style={{ whiteSpace: 'nowrap' }}>{variant.packing_scheme}</span>
                          </Col>

                          <Col span={7}>
                            <Form.Item>
                              {this.props.form.getFieldDecorator(`${variant.id}.special_code`, {
                                // rules: [{ required: true, message: 'Please input your Variant Name!' }],
                                initialValue: variant.priceList.special_code
                              })(
                                <Input />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item  >
                              {this.props.form.getFieldDecorator(`${variant.id}.selling_price`, {
                                initialValue: variant.priceList.selling_price,
                              })(
                                <Input className={`isActiveInput-${i}`} readOnly={parseInt(variant.priceList.status) === 1} style={{ background: parseInt(variant.priceList.status) === 1 ? '#F5F5F5' : 'white' }} />
                              )}
                              <div id={`inputErrorMessage-${i}`} style={{ fontSize: 9, color: 'red', marginLeft: 5, marginTop: -18, display: parseInt(variant.priceList.status) === 1 ? 'block' : 'none' }}>
                                <span>Unable to edit price, status is active</span>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={3} align="center">
                            <Form.Item>
                              {this.props.form.getFieldDecorator(`${variant.id}.status`, {
                                initialValue: parseInt(variant.priceList.status) === 1 ? true : false,
                              })(<Switch defaultChecked={parseInt(variant.priceList.status) === 1 ? true : false} onClick={() => this.handleOnChangeInputActive(`isActiveInput-${i}`, `inputErrorMessage-${i}`)} />)}
                            </Form.Item>
                          </Col> */}

                            {/* {row.variant.map((variant, i) => {

                              let visible = true

                              // if (variant.product_id !== row.id) {

                              //   return false;
                              // }

                              // if (keyword !== '' && !(variant.material_code.toLowerCase().includes(keyword.toLowerCase()) || variant.name.toLowerCase().includes(keyword.toLowerCase()))) {
                              //   visible = false
                              //   // return false;
                              // }
                              if (keyword && !(variant.material_code.toString().toLowerCase().includes(keyword.toString().toLowerCase()) ||
                                variant.name.toString().toLowerCase().includes(keyword.toString().toLowerCase()) ||
                                variant.packing_scheme.toString().toLowerCase().includes(keyword.toString().toLowerCase()) ||
                                (variant.priceList.special_code && variant.priceList.special_code.toString().toLowerCase().includes(keyword.toString().toLowerCase()))
                              )) {
                                visible = false
                                // return false;
                              }

                              return (
                                <Row type="flex" gutter={10} key={i}
                                  style={{ display: !visible ? 'none' : 'flex', width: '100%' }}
                                // style={{ display: !variant.visible ? 'none' : 'flex', width: '100%' }}
                                >
                                  <Col span={7}>
                                    <b>{variant.material_code}</b> - {variant.name} - <span style={{ whiteSpace: 'nowrap' }}>{variant.packing_scheme}</span>
                                  </Col>

                                  <Col span={7}>
                                    <Form.Item>
                                      {this.props.form.getFieldDecorator(`${variant.id}.special_code`, {
                                        // rules: [{ required: true, message: 'Please input your Variant Name!' }],
                                        initialValue: variant.priceList.special_code
                                      })(
                                        <Input />
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={7}>
                                    <Form.Item  >
                                      {this.props.form.getFieldDecorator(`${variant.id}.selling_price`, {
                                        initialValue: variant.priceList.selling_price,
                                      })(
                                        <Input className={`isActiveInput-${i}`} readOnly={parseInt(variant.priceList.status) === 1} style={{ background: parseInt(variant.priceList.status) === 1 ? '#F5F5F5' : 'white' }} />
                                      )}
                                      <div id={`inputErrorMessage-${i}`} style={{ fontSize: 9, color: 'red', marginLeft: 5, marginTop: -18, display: parseInt(variant.priceList.status) === 1 ? 'block' : 'none' }}>
                                        <span>Unable to edit price, status is active</span>
                                      </div>
                                    </Form.Item>
                                  </Col>
                                  <Col span={3} align="center">
                                    <Form.Item>
                                      {this.props.form.getFieldDecorator(`${variant.id}.status`, {
                                        initialValue: parseInt(variant.priceList.status) === 1 ? true : false,
                                      })(<Switch defaultChecked={parseInt(variant.priceList.status) === 1 ? true : false} onClick={() => this.handleOnChangeInputActive(`isActiveInput-${i}`, `inputErrorMessage-${i}`)} />)}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              );
                            })} */}
                          </Col>
                        </Row>
                        {/* <Col span={24} align="right">
                          <Form.Item>
                            <Button key="back" onClick={this.props.handleCloseModal} disabled={isLoading}>
                              Close
                            </Button>
                            &nbsp;
                            <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                              Save
                            </Button>
                          </Form.Item>
                        </Col> */}
                      </Form>

                    </TabPane>
                  ))}
                </Tabs>
              </Spin>

            </Row>
          </Content>
        </Layout>
      </Modal >
      // </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    productVariantData: state.distributor.productVariantData,
    distributorProductVariantLoading: state.distributor.productVariantLoading,
    productVariantEditPricelist: state.distributor.productVariantEditPricelist,
    pricelistByProductData: state.distributor.pricelistByProductData,
    pricelistByProductLoading: state.distributor.pricelistByProductLoading,
    searchProductVariantData: state.distributor.searchProductVariantData,
    searchProductVariantLoading: state.distributor.searchProductVariantLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitEditPriceList: (data) => dispatch(submitEditPriceList(data)),
    onSubmitShowHideVariant: (data) => dispatch(submitShowHideVariant(data)),
    onLoadProductVariantData: (data) => dispatch(loadProductVariantData(data)),
    onSearchProductVariant: (data) => dispatch(searchProductVariant(data)),
  }
}

const ModalPricelist = Form.create({ name: 'modalPricelist_form' })(ModaPricelistForm);
export default connect(mapStateToProps, mapDispatchToProps)(ModalPricelist);
