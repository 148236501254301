import React from 'react'
import { Row, Col, Button, List, Modal, Select, Layout, Input } from 'antd';
import '../index.scss'
const { Content } = Layout;
const { Search, TextArea } = Input;
const { Option } = Select;
export default class ModalContainerBreakdown extends React.Component {
    constructor() {
        super()
        this.state = {
            showRequestModal: false,
            activeRadio: 'all',
            comments: "",
            filterBy: 'all',
            tableData: []
        }
    }
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        this.props.onLoadContainerSummaryBasedOnSoNumber(urlParams.get("so_number"))
    }

    handleCloseModal = () => {
        this.setState({
            showRequestModal: false,
            selectedRow: null
        });
    };

    handleComments = e => {
        this.setState({ comments: e.target.value })
    }

    handleSubmit = async e => {
        e.preventDefault();

        const { onSendRequest, selectedRow } = this.props;
        const { tableData } = this.state;

        this.props.form.validateFields(async (err, values) => {
            if (!err) {

                let values = {
                    status: 0,
                    notif_type: 'sku replacement',
                    progress: tableData[0].sales_document,
                    description: this.state.comments,
                    distributor_id: tableData[0].distributor.id,
                    // distributor_order_id: selectedRow.id
                }

                if (tableData) {
                    await onSendRequest(values)
                        .then((response) => {
                            if (response.error) {
                                Modal.warning({
                                    title: 'Unable to send Request.',
                                    content: ''
                                })
                            } else {
                                Modal.success({
                                    title: 'Request has been sent successfully',
                                    content: ''
                                })
                            }
                        })
                }

                this.props.form.resetFields();
                this.handleCloseModal();
            }
        });
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.containerBreakdownBasedSoNumberData !== nextProps.containerBreakdownBasedSoNumberData) {
            this.setState({ tableData: nextProps.containerBreakdownBasedSoNumberData })
        }
    }

    renderDotLoading(status, center) {
        const dotCount = 4;
        let i;
        let arrayCircle = [];
        let circleDataObject = {
            activeDot: 0,
            label: "",
        };
        if (status === "Invoiced") {
            circleDataObject = {
                activeDot: 4,
                label: "INVOICED",
            };
        } else if (status === "Ready To Load") {
            circleDataObject = {
                activeDot: 3,
                label: "READY TO LOAD",
            };
        } else if (status === "Produced") {
            circleDataObject = {
                activeDot: 2,
                label: "PRODUCED",
            };
        } else {
            circleDataObject = {
                activeDot: 1,
                label: "FOR PRODUCTION",
            };
        }
        for (i = 0; i < dotCount; i++) {
            arrayCircle.push(
                <div
                    key={i}
                    style={{
                        backgroundColor:
                            circleDataObject.activeDot > i ? "#6BBF59" : "lightgray",
                        height: 12,
                        width: 12,
                        borderRadius: 12,
                        margin: 8,
                    }}
                />
            );
        }
        return (
            <div className="xs-activity">
                <h4 style={{ color: "gray", whiteSpace: "nowrap" }}>
                    {circleDataObject.label}
                </h4>
                <div
                    style={{ display: "flex", justifyContent: center }}
                    className="dot"
                >
                    {arrayCircle}
                </div>
            </div>
        );
    }

    onChangeFilterRadio(status) {
        let filteredByStatus
        if (status === 'all') {
            filteredByStatus = this.props.containerBreakdownBasedSoNumberData
        } else if (status === 'in process') {
            filteredByStatus = this.props.containerBreakdownBasedSoNumberData.filter((data) => data.status !== 'Invoiced')
        } else {
            filteredByStatus = this.props.containerBreakdownBasedSoNumberData.filter((data) => data.status === 'Invoiced')
        }
        this.setState({
            activeRadio: status,
            tableData: filteredByStatus
        })
    }


    handleFilterBy(data) {
        this.setState({
            filterBy: data,
            // tableData: this.props.soNumberData.filter((data) => data.type === this.state.activeRadio)
            // activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs
        })
    }

    handleSearch = (searchTerm, searchKeys) => {
        if (this.state.filterBy !== 'all') {
            searchKeys = [this.state.filterBy]
        }
        //function inside function, this will convert the dot string into actual object
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split(".");
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }

        let newArrayObj
        if (this.state.activeRadio === 'all') {
            newArrayObj = this.props.containerBreakdownBasedSoNumberData
        } else if (this.state.activeRadio === 'in process') {
            newArrayObj = this.props.containerBreakdownBasedSoNumberData.filter((data) => data.status !== 'Invoiced')
        } else {
            newArrayObj = this.props.containerBreakdownBasedSoNumberData.filter((data) => data.status === 'Invoiced')
        }
        //if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return this.setState({ tableData: newArrayObj })
        }
        let newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
                if (isFound) newArray.push(rowItem)
            })
        })
        //Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        this.setState({ tableData: newArrayElement })
    }

    render() {
        const { selectedRow } = this.props
        const { showRequestModal } = this.state
        const urlParams = new URLSearchParams(window.location.search);
        return (
            <Modal
                // title="Container Summary Breakdown"
                visible={this.props.visible}
                width={'90%'}
                onCancel={() => this.props.onCancel()}
                // centered={true}
                className='summary-modal'
                footer={null}
            >
                <Layout className='summary-container'>
                    <Content>
                        <Content className='summary-header'>
                            <h1>Container Summary Breakdown</h1>
                        </Content>
                        <Content className='summary-sub-header'>
                            <Content className='sub-header-content'>
                                <h1>CREATED DATE</h1>
                                <p>{urlParams.get("created_at")}</p>
                            </Content>
                            <Content className='sub-header-content'>
                                <h1>SO NUMBER</h1>
                                <p>{urlParams.get("so_number")}</p>
                            </Content>
                            <Content>
                                <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                                    <Button className="btn-small btn-primary" onClick={() => this.setState({ showRequestModal: true })} style={{ padding: "0 15" }}>Request to Replace</Button>
                                </Col>
                            </Content>
                        </Content>
                        <Content>
                            <Row className='content-filter'>
                                <Col md={6}>
                                    <Search
                                        placeholder="Search"
                                        onSearch={value => this.handleSearch(value, [
                                            'material',
                                            'product_variant.name',
                                            'product_variant.packing_scheme',
                                            'status',
                                            'order_qty',
                                            'order_amount',
                                            'billed_qty',
                                            'billed_amount',
                                            'volume'
                                        ])}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                                <Col md={4} align="center" className='filter-sort-by'>
                                    <Select style={{ width: 160 }} defaultValue="All" onChange={(e) => this.handleFilterBy(e)} >
                                        <Option value="all">All</Option>
                                        <Option value="material">SKU Code</Option>
                                        <Option value="product_variant.name">SKU Description</Option>
                                        <Option value="product_variant.packing_scheme">Packing Scheme</Option>
                                        {/* <Option value="status">Status</Option> */}
                                        <Option value="status">Status</Option>
                                        <Option value="order_qty">Ordered Qty (IN CS)</Option>
                                        <Option value="order_amount">Ordered Amount (IN USD)</Option>
                                        <Option value="billed_qty">Invoice Qty</Option>
                                        {/* <Option value="billed_amount">Invoice Amount (IN USD)</Option> */}
                                        <Option value="volume">CBM</Option>
                                    </Select>
                                </Col>
                                <Col md={10} className='filter-radio'>
                                    <label className="radio"><span style={{ margin: '10px 0' }}>ALL</span>
                                        <input type="radio" name="volume" checked={this.state.activeRadio === 'all'} value="all" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="radio">IN PROCESS
                                          <input type="radio" name="volume" checked={this.state.activeRadio === 'in process'} value="in process" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="radio">INVOICED
                                             <input type="radio" name="volume" checked={this.state.activeRadio === 'invoiced'} value="invoiced" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </Col>
                            </Row>
                        </Content>
                        <Content className='summary-table'>
                            <List
                                header={<Row>
                                    <Col span={3}>SKU <br />CODE</Col>
                                    <Col span={3}>SKU <br /> DESCRIPTION</Col>
                                    <Col span={3}>PACKING <br /> SCHEME</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'center' }} >ORDER <br /> STATUS</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>ORDERED <br /> QTY (IN CS)</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>ORDERED <br /> AMOUNT (IN USD)</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>INVOICED <br /> QUANTITY</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>CBM</Col>
                                </Row>}
                                footer={<Row className='footer-table' style={{}}>
                                    <Col span={12}>TOTAL</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>{this.state.tableData.reduce((a, c) => a + parseFloat(c['order_qty']), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>${this.state.tableData.reduce((a, c) => a + parseFloat((c['product_variant'] && c['product_variant']['distributor_pricelist']['selling_price'] || 0) * getQtyBasedOnStatus(c.status, c)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>{this.state.tableData.reduce((a, c) => a + parseFloat(c['billed_qty']), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                    <Col span={3} style={{ padding: '0 5px', textAlign: 'right' }}>{this.state.tableData.reduce((a, c) => a + parseFloat(c['volume']), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                </Row>}
                                bordered
                                loading={this.props.containerBreakdownBasedSoNumberLoading}
                                dataSource={this.state.tableData}
                                renderItem={item => (
                                    <List.Item>
                                        <Row style={{ width: '100%' }}>
                                            <Col md={3}>{item.material}</Col>
                                            <Col md={3}>{item.product_variant && item.product_variant.name}</Col>
                                            <Col md={3}>{item.product_variant && item.product_variant.packing_scheme}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'center' }}  >{this.renderDotLoading(item.status, 'center')}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'right' }}  >{item.order_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'right' }}  >${((item.product_variant && item.product_variant.distributor_pricelist['selling_price'] || 0) * getQtyBasedOnStatus(item.status, item)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'right' }}   >{item.billed_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'right' }}  >{item.volume.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Content>
                    </Content>
                    <Modal
                        {...this.props}
                        title="Update Replace SKU"
                        visible={showRequestModal}
                        width={"35%"}
                        onCancel={() => this.setState({ showRequestModal: false })}
                        closable={false}
                        footer={null}
                        className="replacement-modal"
                    >

                        <div className="comments-content">
                            <h3>COMMENTS</h3>
                            <TextArea className="comments-input" name="comments" value={this.state.comments} onChange={this.handleComments} rows={2} placeholder="Comments here" />
                        </div>

                        <Row type="flex" style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                            <Col><Button className="btn-small btn-default" onClick={() => this.setState({ showRequestModal: false, selectedRow: null })} style={{ marginBottom: 30 }}>Cancel</Button></Col>
                            <Col><Button className="btn-small btn-primary" onClick={this.handleSubmit} style={{ marginBottom: 30 }}>Send</Button></Col>
                        </Row>
                    </Modal>
                </Layout>
            </Modal>
        )
    }
}

const getQtyBasedOnStatus = (status, arrayData) => {
    let returnQty;
    if (status === "Produced") {
        returnQty = arrayData["order_qty"]; // sap
    } else if (status === "Ready To Load") {
        returnQty = arrayData["delivered_qty"]; // sap
    } else if (status === "Invoiced") {
        returnQty = arrayData["billed_qty"]; // sap
    }
    return returnQty;
};

