import Axios from '../../../utils/axios'
import axios from 'axios'
import { headers } from '../../../utils/token'
import moment from 'moment'

export const loadFAQCategory = (data) => dispatch => {
  return Axios.get('/admin/faq-categories', dispatch, 'GET_FAQ_CATEGORY_DATA', { headers: headers })
}

export const submitCategory = (data) => dispatch => {
  return Axios.post('/admin/create-faq-category', dispatch, 'POST_SUBMIT_CATEGORY', data, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}

export const updateCategory = (data) => dispatch => {
  const submitData = {
    category: data.values.category,
    type: data.values.type
  }
  return Axios.post(`/admin/update-faq-category/${data.selectedActiveCategory.id}`, dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}


export const submitQuestionAndAnswer = (data) => dispatch => {
  const submitData = {
    category: data.categoryId,
    question: data.values.question,
    answer: data.values.answer,
    type: data.values.type
  }
  return Axios.post('/admin/create-faq-content', dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}

export const updateQuestionAndAnswer = (data) => dispatch => {
  const submitData = {
    category: data.categoryId,
    question: data.values.question,
    answer: data.values.answer,
    type: data.values.type,
    is_active: data.selectedAnswerAndQuestion.is_active,
    // order_no:'',
    // status:''
  }

  return Axios.post(`/admin/update-faq-content/${data.selectedAnswerAndQuestion.id}`, dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}


export const updateSortList = (data) => dispatch => {
  return Axios.post(`/admin/faq-contents-arrangement`, dispatch, 'POST_SUBMIT_CATEGORY', data, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}


export const removedQuestionAndAnswer = (data) => dispatch => {
  const submitData = {
    status: 0
  }
  return Axios.post(`/admin/update-faq-content/${data.id}`, dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}


export const updateType = (data) => dispatch => {
  const submitData = {
    type: data.type
  }
  return Axios.post(`/admin/update-faq-content/${data.id}`, dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}


export const updateStatus = (data) => dispatch => {
  const submitData = {
    is_active: data.is_active
  }
  return Axios.post(`/admin/update-faq-content/${data.id}`, dispatch, 'POST_SUBMIT_CATEGORY', submitData, { headers: headers }).then((response) => {
    if (response.type === 'POST_SUBMIT_CATEGORY_FULFILLED') dispatch(loadFAQCategory())
    return response
  })
}





