import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadInquiryDb
} from './actions'
import {
    fetchPermissions
} from '../../actions'
import Page from './Page';

class InquiryDb extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const InquiryDbForm = Form.create({ name: 'inquiry_db' })(InquiryDb);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        inquiryDbData: state.inquiryDb.inquiryDbData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadInquiryDb: (data) => dispatch(loadInquiryDb(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryDbForm);
