import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd'
import ImageUploader from '../../../shared/ImageUploader'

const { Content } = Layout
const Option = Select.Option

export default function ModalForm(props) {
  const { selectedRow, isLoading } = props;

  return (
    <Modal
      title={`${selectedRow ? "Edit" : 'Add'} Brand`}
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
    >
      <Layout>
        <Content className='contact-form-container'>
          <Row type='flex' justify='center' gutter={30}>
            <Form onSubmit={props.handleSubmit} className='login-form'>
              <Col span={24} align="center" style={{ position: "relative" }}>
                <ImageUploader
                  ref={props.imageUploader}
                  fileFolder={`brands`}
                  buttonDesc="Click here to choose image"
                  imageUrl={selectedRow ? selectedRow.image : ""}
                />
              </Col>

              <Col span={24}>
                <Form.Item label='Brand Name'>
                  {props.getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your Brand Name!' }],
                    initialValue: selectedRow ? selectedRow.name : ""
                  })(
                    <Input
                      placeholder='ex: Jack n Jill'
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Description'>
                  {props.getFieldDecorator('description', {
                    initialValue: selectedRow ? selectedRow.description : ""
                  })(
                    <Input
                      placeholder='ex: Philippines'
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label='Status'>
                  {props.getFieldDecorator('status', {
                    rules: [{ required: true, message: 'Please input your Status!' }],
                    initialValue: selectedRow ? selectedRow.status.toString() : ""
                  })(
                    <Select placeholder='Select a Status' showSearch>
                      <Option value='Active'>Active</Option>
                      <Option value='Inactive'>Inactive</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal} disabled={isLoading}>
                    Close
                  </Button>
                  &nbsp;
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
