import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    loadContainerSummary,
    loadContainerSummaryBasedOnSoNumber,
    sendRequest,
    updateCustomerPoNumber
} from './actions';

import { fetchNotifications } from '../../actions'

import Page from './Page';

class ContainerConfiguration extends Component {
    render() {
        return (
            <Page {...this.props} />
        );
    }
}

const ContainerConfigurationForm = Form.create({ name: 'container_configuration_form' })(ContainerConfiguration);

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        containerBreakdownData: state.containerBreakdown.containerBreakdownData,
        containerBreakdownBasedSoNumberData: state.containerBreakdown.containerBreakdownBasedSoNumberData,
        containerBreakdownBasedSoNumberLoading: state.containerBreakdown.containerBreakdownBasedSoNumberLoading,
        containerBreakdownLoading: state.containerBreakdown.containerBreakdownLoading,
        updateCustomerPoNumberLoading: state.containerBreakdown.updateCustomerPoNumberLoading,
        notifData: state.notifications.notifications
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadContainerSummary: (data) => dispatch(loadContainerSummary(data)),
        onLoadContainerSummaryBasedOnSoNumber: (data) => dispatch(loadContainerSummaryBasedOnSoNumber(data)),
        fetchNotifications: (data) => dispatch(fetchNotifications(data)),
        onSendRequest: (data) => dispatch(sendRequest(data)),
        onUpdateCustomerPoNumber: (data) => dispatch(updateCustomerPoNumber(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerConfigurationForm);
