
const initialData = {
    permissionsLoading: false,
    permissionsData: []
}

export default function permissions(state = initialData, action) {
    switch (action.type) {
        case 'GET_PERMISSIONS':
            return {
                ...state,
                permissionsLoading: true
            }
        case 'GET_PERMISSIONS_FULFILLED':
            return {
                ...state,
                permissionsData: action.payload,
                permissionsLoading: false
            }
        case 'GET_PERMISSIONS_REJECTED':
            return {
                ...state,
                permissionsData: null,
                permissionsLoading: false
            }

        default:
            return {
                ...state
            }
    }
}