import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input, Icon, Tabs, Divider } from 'antd';

const { Content } = Layout;
const Option = Select.Option;

let contactId = 0
let addressId = 0;

class ModalForm extends Component {

  contactRemove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const contactKeys = form.getFieldValue('contactKeys');
    // We need at least one passenger
    if (contactKeys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      contactKeys: contactKeys.filter(key => key !== k),
    });
  };

  contactAdd = () => {
    const { form } = this.props;

    // can use data-binding to get
    const contactKeys = form.getFieldValue('contactKeys');
    const nextKeys = contactKeys.concat(contactId++);
    // can use data-binding to set
    // important! notify form to detect changes
    // form.setFieldsValue({
    //   contactKeys: nextKeys,
    // });
    form.setFieldsValue({
      contactKeys: nextKeys,
    });
  };

  componentDidMount() {
    let { selectedRow } = this.props
    //EDIT
    if (selectedRow) {
      contactId = this.props.selectedRow.contact.length + 1 || 0
      addressId = this.props.selectedRow.address.length + 1 || 0
      const { form, selectedRow } = this.props;

      let arrayAddress = []
      selectedRow.address.map((data) => {
        if (data.status === 1) {
          arrayAddress.push({ id: data.id, name: data.name })
        }
      })

      form.setFieldsValue({
        // contactKeys: selectedRow.contact,
        // addressKeys: arrayAddress,
        addressKeys: selectedRow.address,
      });
    }
  }

  addressRemove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const addressKeys = form.getFieldValue('addressKeys');
    // We need at least one passenger
    if (addressKeys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      addressKeys: addressKeys.filter(key => key !== k),
    });
  };

  addressAdd = () => {
    const { form } = this.props;
    // can use data-binding to get
    const addressKeys = form.getFieldValue('addressKeys');
    const nextKeys = addressKeys.concat(addressId++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      addressKeys: nextKeys,
    });
  };

  _modalType = (type) => {
    if (type === 'add')
      return 'added'
    if (type === 'edit') {
      return 'updated'
    }
  }

  handleSubmitDistributorData = (e) => {
    e.preventDefault()
    const { form, modalType } = this.props


    form.validateFields((err, values) => {
      if (!err) {
        let contactArray = []
        if ('email' in values) contactArray.push({ contact: values.email, type: 'email' })
        if ('mobile' in values) contactArray.push({ contact: values.mobile, type: 'mobile' })
        if ('office' in values) contactArray.push({ contact: values.office, type: 'office' })
        if ('fax' in values) contactArray.push({ contact: values.fax, type: 'fax' })
        const selectedId = (this.props.selectedRow) ? this.props.selectedRow.id : ''
        let address = values.address.filter(function (e) { return e })
        const distributorData = {
          id: selectedId,
          name: values.name,
          country: 'PH',
          customer_code: values.customer_code,
          status: values.status === 'Active' ? 1 : 0,
          address: address,
          contacts: contactArray,
          etd_month: values.etd_month,
          password: values.password
        }
        this.props.submitUpdateDistributor(distributorData, this.props.searchParams).then((response) => {
          if (response.error) {
            Modal.warning({
              title: 'Unable to update Distributor.',
              content: ''
            })
          } else {
            Modal.success({
              title: `Distributor has been ${this._modalType(modalType)} successfully`,
              content: ''
            })
          }
        })
        this.props.handleCloseModal();
      }
    });
  }

  displayDistributorBasedOnId(type, key) {
    const { selectedRow } = this.props
    let returnData = ''
    selectedRow && selectedRow.contact.map((data) => {
      if (data.type === type) {
        returnData = data[key]
      }
    })
    return returnData
  }

  render() {
    const {
      users,
      isLoading, selectedRow
    } = this.props

    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('contactKeys', { initialValue: [] });
    getFieldDecorator('addressKeys', { initialValue: [] });
    const contactKeys = getFieldValue('contactKeys');
    const addressKeys = getFieldValue('addressKeys');
    const contactFormItems = contactKeys.map((k, index) => {
      return (
        <Form.Item
          required={false}
          key={index}
        >

          {getFieldDecorator(`contacts[${k.id || k}]['type']`, {
            initialValue: `${k.type || ''}`,
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                // required: true,
                whitespace: true,
                message: "Please input  ",
              },
            ],
          })(
            <Select placeholder='Set to Default' style={{ width: '30%', marginRight: '10px' }} >
              <Option value='email'>Email</Option>
              <Option value='mobile'>Mobile</Option>
              <Option value='office'>Office</Option>
              <Option value='fax'>Fax</Option>
            </Select>
          )}

          {getFieldDecorator(`contacts[${k.id || k}]['contact']`, {
            initialValue: `${k.contact || ''}`,
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input  ",
              },
            ],
          })(<Input placeholder={`contact ${index + 1}`} style={{ width: '30%', marginRight: '10px' }} />)}

          {/* })(<Input placeholder={`type ${index + 1}`} style={{ width: '30%', marginRight: '10px' }} />)} */}

          {getFieldDecorator(`contacts[${k.id || k}]is_default`, {
            rules: [{ required: true, message: '' }],
            initialValue: selectedRow ? selectedRow.status.toString() : ""
          })(
            <Select placeholder='Set to Default' style={{ width: '30%', marginRight: '10px' }}>
              <Option value='1'>Yes</Option>
              <Option value='0'>No</Option>
            </Select>
          )}

          {contactKeys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.contactRemove(k)}
              style={{ width: '5%' }}
            />
          ) : null}
        </Form.Item>
      )
    });
    const addressFormItems = addressKeys.map((k, index) => (
      <Form.Item
        required={false}
        key={k}
      >
        <Row type='flex' gutter={10}>
          {/* 1st Col */}
          <Col span={24}>
            <label>Name: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['name']`, {
              initialValue: `${k.name || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: "Please input  ",
                },
              ],
            })(<Input placeholder={`Name ${index + 1}`} style={{ width: '100%' }} />)}
          </Col>
          {/* 2nd Col */}
          {/* <Col span={8}>
            <label>Unit: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['unit']`, {
              initialValue: `${k.unit || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input unit  ",
                },
              ],
            })(<Input placeholder={`Unit ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>Floor: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['floor']`, {
              initialValue: `${k.floor || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input floor  ",
                },
              ],
            })(<Input placeholder={`Floor ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>Building: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['building']`, {
              initialValue: `${k.building || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input building  ",
                },
              ],
            })(<Input placeholder={`building ${index + 1}`} />)}
          </Col>
          {/* 3rd Col */}
          {/* <Col span={8}>
            <label>Street: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['street']`, {
              initialValue: `${k.street || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input street  ",
                },
              ],
            })(<Input placeholder={`Street ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>City: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['city']`, {
              initialValue: `${k.city || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input city  ",
                },
              ],
            })(<Input placeholder={`City ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>Region: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['region']`, {
              initialValue: `${k.region || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  // required: true,
                  whitespace: true,
                  message: "Please input region  ",
                },
              ],
            })(<Input placeholder={`Region ${index + 1}`} />)}
          </Col> */}
          {/* 4th Col */}
          {/* <Col span={8}>
            <label>Country: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['country']`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: `${k.country || ''}`,
              rules: [
                {
                  whitespace: true,
                  message: "Please input country  ",
                },
              ],
            })(<Input placeholder={`Country ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>Postal Code: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['postal_code']`, {
              initialValue: `${k.postal_code || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  whitespace: true,
                  message: "Please input postal code  ",
                },
              ],
            })(<Input placeholder={`Postal Code ${index + 1}`} />)}
          </Col>
          <Col span={8}>
            <label>Status: </label><br />
            {getFieldDecorator(`address[${k.id || k}]['status']`, {
              initialValue: `${k.status || ''}`,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  whitespace: true,
                  message: "Please input status  ",
                },
              ],
            })(
              <Select placeholder='Set to Default' >
                <Option value='1'>Yes</Option>
                <Option value='0'>No</Option>
              </Select>)}
          </Col> */}
          {addressKeys.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.addressRemove(k)}
              style={{ width: '10%' }}
            />
          ) : null}
        </Row>
      </Form.Item>
    ));

    return (
      <Modal
        title={`${selectedRow ? 'Edit' : 'Add'} Distributor`}
        visible={this.props.showAddEditModal}
        onCancel={this.props.handleCloseModal}
        centered={true}
        footer={null}
        width={800}
      >
        <Layout>
          <Content className='contact-form-container'>
            <Row type='flex' justify='center' gutter={30}>
              <Form onSubmit={this.handleSubmitDistributorData} className='login-form'>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Information" key="1">
                    <Content style={{ padding: '20px' }}>
                      <h2>Information</h2>
                      <Col span={24}>
                        <Form.Item label='Distributor Name'>
                          {this.props.form.getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Please input your Distributor Name!' }],
                            initialValue: selectedRow ? selectedRow.name : ""
                          })(
                            <Input
                              placeholder='ex: Jack n Jill'
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label='Customer Code'>
                          {this.props.form.getFieldDecorator('customer_code', {
                            rules: [{ required: true, message: 'Please input your Customer Code!' }],
                            initialValue: selectedRow ? selectedRow.customer_code : ''
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label='Password'>
                          {this.props.form.getFieldDecorator('password', {
                            rules: [{ message: 'Please input your Password!' }],
                            initialValue: selectedRow ? selectedRow.password : ""
                          })(
                            <Input type='password' />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label='Status'>
                          {this.props.form.getFieldDecorator('status', {
                            rules: [{ required: true, message: 'Please input your Status!' }],
                            initialValue: selectedRow ? selectedRow.status.toString() : ""
                          })(
                            <Select placeholder='Select a Status' showSearch>
                              <Option value='Active'>Active</Option>
                              <Option value='Inactive'>Inactive</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Divider dashed />
                      <h2>Distributor Options</h2>
                      <Col span={8}>
                        <Form.Item label='Etd Month'>
                          {this.props.form.getFieldDecorator('etd_month', {
                            rules: [{ required: true, message: 'Please input a ETD Month!' }],
                            initialValue: selectedRow ? selectedRow.etd_month.toString() : ""
                          })(
                            <Select placeholder='Select a ETD Month' showSearch>
                              <Option value='1'>1</Option>
                              <Option value='2'>2</Option>
                              <Option value='3'>3</Option>
                              <Option value='4'>4</Option>
                              <Option value='5'>5</Option>
                              <Option value='6'>6</Option>
                              <Option value='7'>7</Option>
                              <Option value='8'>8</Option>
                              <Option value='9'>9</Option>
                              <Option value='10'>10</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Content>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Contact" key="2">
                    <Content style={{ padding: '20px' }}>
                      <h2>Contact</h2>
                      <Col span={12}>
                        <Form.Item label='Email'>
                          {this.props.form.getFieldDecorator('email', {
                            rules: [
                              {
                                type: 'email', message: 'The input is not valid E-mail'
                              },
                              {
                                required: true, whitespace: true, message: 'Please input your Email!'
                              },
                              {
                                validator: (rule, value, callback) => {
                                  try {
                                    if (value) {
                                      if (users.some(r => r.email.toLowerCase() !== this.displayDistributorBasedOnId('email', 'contact').toLowerCase() && r.email.toLowerCase() === value.toLowerCase())) {
                                        callback("email already exists");
                                      } else {
                                        callback();
                                      }
                                    }
                                  } catch (err) {
                                    callback(err);
                                  }
                                }
                              }
                            ],
                            initialValue: this.displayDistributorBasedOnId('email', 'contact') || ''
                          })(
                            <Input disabled={selectedRow ? true : false} />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label='Mobile'>
                          {this.props.form.getFieldDecorator('mobile', {
                            rules: [{ required: true, whitespace: true, message: 'Please input your Mobile!' }],
                            initialValue: this.displayDistributorBasedOnId('mobile', 'contact') || ''
                          })(
                            <Input type='number' />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label='Office'>
                          {this.props.form.getFieldDecorator('office', {
                            rules: [{ required: true, whitespace: true, message: 'Please input your Office!' }],
                            initialValue: this.displayDistributorBasedOnId('office', 'contact') || ''
                          })(
                            <Input />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label='Fax'>
                          {this.props.form.getFieldDecorator('fax', {
                            rules: [{ required: true, whitespace: true, message: 'Please input your Fax!' }],
                            initialValue: this.displayDistributorBasedOnId('fax', 'contact') || ''
                          })(
                            <Input type='number' />
                          )}
                        </Form.Item>
                      </Col>
                    </Content>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Address" key="3">
                    <Content style={{ padding: '20px' }}>
                      <h2>Address</h2>
                      <Col span={24}>
                        {addressFormItems}
                        <Form.Item >
                          <Button type="dashed" onClick={this.addressAdd} style={{ width: '100%' }}>
                            <Icon type="plus" /> Add Address
                   </Button>
                        </Form.Item>
                      </Col>

                    </Content>
                  </Tabs.TabPane>
                </Tabs>
                <Col span={24} align="right">
                  <Form.Item>
                    <Button key="back" onClick={this.props.handleCloseModal} disabled={isLoading}>
                      Close
                  </Button>
                    &nbsp;
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                      Submit
                  </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Content>
        </Layout>
      </Modal >
    )
  }
}

export default Form.create({ name: 'modal_form' })(ModalForm);
