import moment from 'moment'
const initialData = {
  orderData: '',
  orderStatusData: '',
  orderLoading: false,
  orderStatusLoading: false,
  // notifications: '',
  // notificationsLoading: false,
  orderCsvData: '',
  orderDataTotalCount: 0
}
export default function order(state = initialData, action) {
  switch (action.type) {
    case 'GET_ORDER_DATA':
      return {
        ...state,
        orderLoading: true
      }
    case 'GET_ORDER_DATA_FULFILLED':
      action.payload.distributorOrder.map((data, i) => {
        // data.status = moment(data.poCreatedAt).format("MM/DD/YYYY")
        data.sales_ref_number = data.sales_ref_number !== null ? data.sales_ref_number : 'N/A'
        data.created_at = moment(data.created_at).format("YYYY-MM-DD")
        data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
        data.status = data.status === 0 ? 'Cancelled' : (data.status === 1 ? 'In Process' : 'Invoiced')
      })
      return {
        ...state,
        orderData: action.payload.distributorOrder,
        orderDataTotalCount: action.payload.total_count,
        orderLoading: false
      }
    case 'GET_ORDER_DATA_REJECTED':
      return {
        ...state,
        orderData: '',
        orderLoading: false
      }

    // orderStatus
    case 'GET_ORDER_STATUS_DATA':
      return {
        ...state,
        orderStatusLoading: true
      }
    case 'GET_ORDER_STATUS_DATA_FULFILLED':
      return {
        ...state,
        orderStatusData: action.payload.orderStatus,
        orderStatusLoading: false
      }
    case 'GET_ORDER_STATUS_DATA_REJECTED':
      return {
        ...state,
        orderStatusData: '',
        orderStatusLoading: false
      }

    // case 'FETCH_NOTIFICATION':
    //   return {
    //     ...state,
    //     notificationsLoading: true
    //   }
    // case 'FETCH_NOTIFICATION_FULFILLED':
    //   return {
    //     ...state,
    //     notifications: action.payload,
    //     notificationsLoading: false
    //   }
    // case 'FETCH_NOTIFICATION_REJECTED':
    //   return {
    //     ...state,
    //     notifications: '',
    //     notificationsLoading: false
    //   }

    case 'GET_ORDER_CSV_FILFILLED':
      return {
        orderCsvData: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
