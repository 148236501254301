const initialData = {
    userRolesLoading: false,
    userRolesData: [],
}

export default function roles(state = initialData, action) {
    switch (action.type) {
        case 'GET_USER_ROLES':
            return {
                ...state,
                userRolesLoading: true
            }
        case 'GET_USER_ROLES_FULFILLED':
            return {
                ...state,
                userRolesData: action.payload,
                userRolesLoading: false
            }
        case 'GET_USER_ROLES_REJECTED':
            return {
                ...state,
                userRolesData: [],
                userRolesLoading: false
            }

        default:
            return {
                ...state
            }
    }
}



