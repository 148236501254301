import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, Layout, Select, Input } from 'antd'
import { EditorState, ContentState, convertFromHTML, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const { Content } = Layout
const Option = Select.Option

function uploadImageCallBack(file) {
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 6ca8e4184363ab4');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    }
  );
}

export default class ModalForm extends Component {
  constructor(props) {
    super(props);
    if (props.selectedRow !== null) {
      // To convert and displayable in html-->
      const content = convertFromRaw(JSON.parse(props.selectedRow.description));
      this.state = { editorState: EditorState.createWithContent(content) };
      //<--
    } else {
      this.state = { editorState: EditorState.createEmpty() };
    }

    this.onChange = (editorState) => {
      this.setState({ editorState });
    }
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { selectedRow, isLoading } = this.props;
    const { editorState } = this.state;
    const blocks = convertToRaw(editorState.getCurrentContent());
    return (
      <Modal
        title={`${selectedRow ? "Edit" : 'Add'} Article`}
        visible={this.props.showFormModal}
        onCancel={this.props.handleCloseModal}
        width={800}
        centered={true}
        footer={null}
      >
        <Layout>
          <Content>
            <Row type='flex' justify='center' gutter={30}>
              <Form onSubmit={this.props.handleSubmit}>
                <Col span={24}>
                  <Form.Item label='Title'>
                    {this.props.getFieldDecorator('title', {
                      rules: [{ required: true, message: 'Please input your Title!' }],
                      initialValue: selectedRow ? selectedRow.title : ""
                    })(
                      <Input
                        placeholder='ex: About us'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label='Sub Title'>
                    {this.props.getFieldDecorator('sub_title', {
                      rules: [{ required: true, message: 'Please input your Sub Title!' }],
                      initialValue: selectedRow ? selectedRow.sub_title : ""
                    })(
                      <Input
                        placeholder='ex: Who We Are'
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label='Description'>
                    {this.props.getFieldDecorator('description', {
                      initialValue: selectedRow ? selectedRow.description : ""
                    })(
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='Status'>
                    {this.props.getFieldDecorator('status', {
                      rules: [{ required: true, message: 'Please input your Status!' }],
                      initialValue: selectedRow ? selectedRow.status.toString() : ""
                    })(
                      <Select placeholder='Select a Status'>
                        <Option value='1'>Active</Option>
                        <Option value='0'>Inactive</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={24} align="right">
                  <Form.Item>
                    <Button key="back" onClick={this.props.handleCloseModal} disabled={isLoading}>
                      Close
                  </Button>
                    &nbsp;
                  <Button type="primary" htmlType="submit" className="login-form-button" disabled={isLoading} loading={isLoading}>
                      Submit
                  </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Content>
        </Layout>
      </Modal>
    )
  }
}
