import React from "react";
import { Link } from "react-router-dom";
import { Menu, Layout, Icon } from "antd";
import * as _ from "lodash";

import './Sider.scss'

const { SubMenu } = Menu;
function Sider({ location, paths }) {
  let selectedKeys = _.chain(paths)
    .filter(path => location.pathname.includes(path.slug))
    .map("slug")
    .value();
  selectedKeys = !selectedKeys.length
    ? [_.find(paths, ["default", true]).slug]
    : selectedKeys;

  return (
    <Layout.Sider>
      <Link to="/" className="logo" />
      <Menu
        className="ant-menu-urc left"
        mode="inline"
        theme="light"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        defaultOpenKeys={['sub1']}
        style={{
          float: "left",
          lineHeight: "61px",
          width: 205,
          height: "100%"
        }}
      >
      <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="dashboard" />
              <span>Dashboard</span>
            </span>
          }
        >
          <Menu.Item key="analytics-dashboard">
            <Link data-menu-label="analytics-dashboard" to="/" className="analytics-dashboard-link">
              <span>OMS Analytics<br />Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="inquiry-dashboard">
            <Link data-menu-label="inquiry-dashboard" to="/inquiry-dashboard">
              Inquiry Database
            </Link>
          </Menu.Item>
        </SubMenu>

        {_.map(paths, path => {
          if (path.slug === 'homepage' || path.slug === 'about-us-page' || path.slug === 'analytics-dashboard' || path.slug === 'inquiry-dashboard' || path.slug === 'feedback' || path.slug === 'user-types' || path.slug === 'admin-management') {
            return;
          }
          return (
            <Menu.Item key={path.slug}>
              <Link data-menu-label={path.label} to={path.route}>
                <Icon type={path.icon} />
                {path.label}
              </Link>
            </Menu.Item>
          );
        })}
        {/* <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="solution" />
              <span>Pages</span>
            </span>
          }
        >
          <Menu.Item key='homepage'><Link data-menu-label='homepage' to='/homepage'><Icon type='home' />Home page</Link></Menu.Item>
          <Menu.Item key='about-us-page'><Link data-menu-label='about-us-page' to='/about-us-page'><Icon type='unordered-list' />About Us</Link></Menu.Item>
        </SubMenu> */}

       

        <SubMenu
          key="sub2"
          title={
            <span>
              <Icon type="line-chart" />
              <span>Reports</span>
            </span>
          }
        >
          <Menu.Item key="feedback">
            <Link data-menu-label="feedback" to="/feedback">
              <Icon type="container" />
              Feedback Survey
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub3"
          title={
            <span>
              <span>Admin</span>
            </span>
          }
        >
          <Menu.Item key='admin-management'><Link to='/admin-management'>Admin Management</Link></Menu.Item>
          <Menu.Item key='user-types'><Link to='/user-types'>User Types</Link></Menu.Item>

        </SubMenu>

      </Menu>
    </Layout.Sider>
  );
}

export default Sider;
