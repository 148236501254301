import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  submitResetPassword
} from './actions';
import Page from './Page';

class ResetPassword extends Component {
  render() {
    return (
      <Page {...this.props} />
    );
  }
}

const ResetPasswordForm = Form.create({ name: 'resetPassword_form' })(ResetPassword);

function mapStateToProps(state) {
  return {
    // loggingIn: state.login.loggingIn,
    // loggedIn: state.login.loggedIn,
    // loginRejected: state.login.loginRejected
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitResetPassword: (data) => dispatch(submitResetPassword(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));