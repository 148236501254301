import React, { Component } from 'react';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';
import moment from 'moment'

import './ProductVariants.scss'

import { Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Skeleton, Result, Switch, Popover, Select } from 'antd';
import { grayscale } from 'pdf-lib';

const { Content } = Layout;
const { Search } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;
class ProductVariant extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    selectedRow: null,
    tableData: [],
    filterBy: [
      'product_variants.material_code'
    ]
  }

  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc',
    filters: JSON.stringify( { 'product_variants.status': [1] } )
  };

  // Initial state for searching
  searchState = {};

  activeProducts = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmitAddProductVariant, onSubmitUpdateProductVariant } = this.props
    const { selectedRow, tableData } = this.state;
    const data = {
      page: tableData.current_page
    }

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values
        }

        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        params.image = media ? media.url : imageUrl;
        params.status = params.status === 'Active' ? 1 : 0

        tableData.data.map(data => {
          if (params.product_id === `${data.product.name} - ${data.product.country_made}`) {
            return params.product_id = data.product.id
          }
        })

        if (selectedRow) {
          params.id = selectedRow.id;
          // passed the value for the current state of filter, sort, search, and page
          await onSubmitUpdateProductVariant(params, {...this.sortState, ...data, ...this.searchState})
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to update Product variants.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Product variants has been updated successfully',
                  content: ''
                })
              }
            })
        } else {
          // passed the value for the current state of filter, sort, search, and page
          await onSubmitAddProductVariant(params, {...this.sortState, ...data, ...this.searchState})
            .then((response) => {
              if (response.error) {
                Modal.warning({
                  title: 'Unable to added Product variants.',
                  content: ''
                })
              } else {
                Modal.success({
                  title: 'Product variants has been added successfully',
                  content: ''
                })
              }
            })
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };

  handleChangeStatus = (row, status) => {
    const { tableData } = this.state;
    const data = {
      page: tableData.current_page
    };

    const params = {
      id: row.id,
      status: status
    };

    // passed the value for the current state of filter, sort, search, and page
    this.props.onSubmitUpdateProductVariant(params, {...this.sortState, ...data, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update product variants.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Product variants has been updated successfully',
            content: ''
          })
        }
      })
  };

  // handleDisableProduct = (row, is_disabled) => {
  //   const params = {
  //     id: row.id,
  //     is_disabled: is_disabled
  //   };
  //   this.props.onSubmitDisableProduct(params)
  //     .then((response) => {
  //       if (response.error) {
  //         Modal.warning({
  //           title: 'Unable to update Product.',
  //           content: ''
  //         })
  //       } else {
  //         Modal.success({
  //           title: 'Product has been updated successfully',
  //           content: ''
  //         })
  //       }
  //     })
  // }
  handleFilterBy = (value) => {
    let category = '';

    // value === 'all'
    //   ? category = [
    //     'name',
    //     'country',
    //     'material_code',
    //     'product.name',
    //     'cbm',
    //     'description',
    //     'dimension',
    //     'gross_weight',
    //     'is_disabled',
    //     'status',
    //     'm_unit',
    //     'packing_scheme',
    //     'selling_price',
    //     'created_at',
    //     'created_at_whole',
    //     'created_at_number',
    //     'product.category',
    //     'product.country_made',
    //     'product.description',
    //     'product.name'
    //   ]:

    // removed the value all for searching
    value === 'material_code'
        ? category = ['product_variants.material_code']
        : value === 'name'
          ? category = ['product_variants.name']
          : value === 'packing_scheme'
            ? category = ['product_variants.packing_scheme']
            : value === 'product.country_made'
              ? category = ['products.country_made']
              : category = [
                'name',
                'country',
                'material_code',
                'product.name',
                'cbm',
                'description',
                'dimension',
                'gross_weight',
                'is_disabled',
                'status',
                'm_unit',
                'packing_scheme',
                'selling_price',
                'created_at',
                'created_at_whole',
                'created_at_number',
                'product.category',
                'product.country_made',
                'product.description',
                'product.name'
              ]

    this.setState({ filterBy: category, keyword: "" });
    // console.log("CATEGORY ===========", category)
    // const { orderStatus } = this.props;
    // const newArrayObj = orderStatus.data;

    // // if data not yet loaded condition to avoid error
    // if (!newArrayObj) {
    //   return;
    // } else {
    //   // return original data
    //   console.log(newArrayObj);
    //   return this.setState({ tableData: newArrayObj });
    // }
  };
  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadProductVariantsData(this.sortState);
    this.props.onLoadActiveProductData()
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    // const convertStringDotNotation = (desc, obj) => {
    //   var arr = desc.split(".");
    //   while (arr.length && (obj = obj[arr.shift()]));
    //   return obj;
    // }
    // const { variantsData } = this.props
    // const newArrayObj = variantsData
    // //if SearchTerm is Empty return original data.
    // if (!searchTerm) {
    //   return this.setState({ tableData: newArrayObj })
    // }
    // let distributorArray = []
    // newArrayObj.forEach((rowItem, i) => {
    //   searchKeys.forEach(keys => {
    //     let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
    //     let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //     if (isFound) distributorArray.push(rowItem)
    //   })
    // })
    // //Removed Duplicate Entry in Array.
    // const newArrayElement = distributorArray.filter(function (elem, index, self) {
    //   return index === self.indexOf(elem);
    // })
    // this.setState({ tableData: newArrayElement })
    
    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveVariantProducts({
      page: 1, // reset pagination to 1
    });

  }

  retrieveVariantProducts(data) {
    this.props.onLoadProductVariantsData({...this.sortState, ...data, ...this.searchState});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.variantsData !== nextProps.variantsData) {
      this.setState({ tableData: nextProps.variantsData })
    }

    if (this.props.productsData.data !== nextProps.productsData.data) {
      this.activeProducts = nextProps.productsData;
      var data = nextProps.productsData.data;
      if (this.props.productsData) {
        data = [].concat(this.props.productsData.data, nextProps.productsData.data);
      } 
      this.activeProducts.data = data;

    }
  }

  // getVariantsName() {
  //   const { tableData } = this.state
  //   let dataArr = []
  //   tableData.map(data => dataArr.push({ text: data.name, value: data.name }))
  //   var dups = [];
  //   var arr = dataArr.filter(function (el) {
  //     // If it is not a duplicate, return true
  //     if (dups.indexOf(el.text) == -1) {
  //       dups.push(el.text);
  //       return true;
  //     }
  //     return false;
  //   });
  //   arr.unshift({
  //     text: "ALL",
  //     value: 'ALL'
  //   });
  //   return arr;
  // }

  // getPackingSchemes() {
  //   const { tableData } = this.state
  //   let dataArr = []
  //   tableData.map(data => {
  //     if (data.packing_scheme != null) {
  //       dataArr.push({ text: data.packing_scheme, value: data.packing_scheme })
  //     }
  //   })
  //   var dups = [];
  //   var arr = dataArr.filter(function (el) {
  //     // If it is not a duplicate, return true
  //     if (dups.indexOf(el.text) == -1) {
  //       dups.push(el.text);
  //       return true;
  //     }
  //     return false;
  //   });
  //   arr.unshift({
  //     text: "ALL",
  //     value: 'ALL'
  //   });
  //   return arr;
  // }

  // getSkuDescription() {
  //   const { tableData } = this.state
  //   let dataArr = []
  //   tableData.map(data => {
  //     if(data.description != null){
  //       dataArr.push({ text: data.description, value: data.description })
  //     }
  // })
  //   var dups = [];
  //   var arr = dataArr.filter(function (el) {
  //     // If it is not a duplicate, return true
  //     if (dups.indexOf(el.text) == -1) {
  //       dups.push(el.text);
  //       return true;
  //     }
  //     return false;
  //   });
  //   arr.unshift({
  //     text: "ALL",
  //     value: 'ALL'
  //   });
  //   return arr;
  // }

  // getSkuCode() {
  //   const { tableData } = this.state
  //   let dataArr = []
  //   tableData.map(data => dataArr.push({ text: data.material_code, value: data.material_code }))
  //   var dups = [];
  //   var arr = dataArr.filter(function (el) {
  //     // If it is not a duplicate, return true
  //     if (dups.indexOf(el.text) == -1) {
  //       dups.push(el.text);
  //       return true;
  //     }
  //     return false;
  //   });
  //   arr.unshift({
  //     text: "ALL",
  //     value: 'ALL'
  //   });
  //   return arr;
  // }

  // getCountry() {
  //   const { tableData } = this.state
  //   let dataArr = []
  //   tableData.map(data => {
  //     if (data.product.country_made != null) {
  //       dataArr.push({ text: data.product.country_made, value: data.product.country_made })
  //     }
  //   })
  //   // var result = tableData.map(a => a.country);
  //   var dups = [];
  //   var arr = dataArr.filter(function (el) {
  //     // If it is not a duplicate, return true
  //     if (dups.indexOf(el.text) == -1) {
  //       dups.push(el.text);
  //       return true;
  //     }
  //     return false;
  //   });
  //   arr.unshift({
  //     text: "ALL",
  //     value: 'ALL'
  //   });
  //   return arr;
  // }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.columnKey || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveVariantProducts({
      page: pagination.current
    });
  }

  render() {
    const { showFormModal, showViewModal, selectedRow, tableData, filterBy } = this.state
    const {
      myPermissions, myPermissionsLoading,
      productsData,
      variantLoading,
      productLoading,
      form: { getFieldDecorator }
    } = this.props
    const dataSource = tableData
    // added data property. records are stored under the data property using the Laravel Eloquent Paginate
    const variantsData = tableData.data;
    const productFilter = [];
    const productFilterValues = [];
    
    if (variantsData === undefined) {
      return false;
    }
    
    variantsData.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())
    //add ALL to product filter

    variantsData.forEach(row => {
      const data = {
        text: row.product.name,
        value: row.product.name
      };

      if (productFilterValues.indexOf(data.value) === -1) {
        productFilter.push(data);
        productFilterValues.push(data.value);
      }
    });

    productFilter.sort((a, b) => {
      const x = a.text.toLowerCase();
      const y = b.text.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    });

    productFilter.unshift({
      text: "ALL",
      value: 'ALL'
    });
    const columns = [
      // {
      //   title: '',
      //   dataIndex: 'id',
      //   key: 'id',
      //   align: 'center',
      //   width: 200,
      //   // sorter: (a, b) => {
      //   //   const x = a.is_disabled;
      //   //   const y = b.is_disabled;
      //   //   if (x > y) { return -1; }
      //   //   if (x < y) { return 1; }
      //   //   return 0;
      //   // },
      //   render: (id, row) => {
      //     return (
      //       <Switch checked={row.is_disabled === 'Enabled' ? true : false} onChange={(checked) => {
      //         checked
      //           ? this.handleDisableProduct(row, 0)
      //           : this.handleDisableProduct(row, 1)
      //       }} />
      //     )
      //   }
      // },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 150,
        render: (image, row) => {
          if (!image) {
            return <div style={{ height: 90 }}></div>;
          }
          return <div style={{ height: 90, margin: 0, padding: 0 }}><img src={process.env.REACT_APP_FILES_URL + image} alt={row.name} width={80} style={{ height: 80, objectFit: 'contain', filter: row.product.status === 0 && 'grayscale(100%)' }} /></div>
        }
      },
      {
        title: 'SKU Code',
        dataIndex: 'material_code',
        key: 'product_variants.material_code',
        align: 'center',
        // filters: this.getSkuCode(),
        // onFilter: (value, record) => {
        //   if (value !== 'ALL') {
        //     if (record.material_code != null) {
        //       return record.material_code === value
        //     }
        //   } else {
        //     return record.material_code
        //   }
        // },
        // width: 200,
        sorter: (a, b) => {
          // const x = a.material_code.toLowerCase();
          // const y = b.material_code.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      }
      ,
      // {
      //   title: 'SKU Description',
      //   dataIndex: 'description',
      //   key: 'description',
      //   align: 'center',
      //   // width: 200,
      //   filters: ,
      //   onFilter: (value, record) => {
      //     if(value !== 'ALL'){
      //       if(record.product.description != null){
      //         return record.product.description === value
      //       }
      //     }else{
      //       return record.product.description
      //     }
      //   },
      //   sorter: (a, b) => {
      //     if(a.description != null && b.description!=null){
      //       const x = a.description.toLowerCase();
      //       const y = b.description.toLowerCase();
      //       if (x < y) { return -1; }
      //       if (x > y) { return 1; }
      //     }
      //     return 0;
      //   },
      // }
      // ,
      // {
      //   title: 'Product',
      //   dataIndex: 'product',
      //   key: 'product',
      //   filters: productFilter,
      //   onFilter: (value, record) => {
      //     if(value !== 'ALL'){
      //       return record.product.name.indexOf(value) >= 0
      //     }else{
      //       return record.product.name
      //     }
      //   },
      //   sorter: (a, b) => {
      //     const x = a.product.name.toLowerCase();
      //     const y = b.product.name.toLowerCase();
      //     if (x < y) { return -1; }
      //     if (x > y) { return 1; }
      //     return 0;
      //   },
      //   render: product => {
      //     return <span>{product.name}</span>
      //   }
      // },
      {
        title: 'SKU Description',
        dataIndex: 'name',
        key: 'product_variants.name',
        // filters: this.getVariantsName(),
        // onFilter: (value, record) => {
        //   if (value !== 'ALL') {
        //     return record.name === value
        //   } else {
        //     return record.name
        //   }
        // },

        sorter: (a, b) => {
          // const x = a.name.toLowerCase();
          // const y = b.name.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Packing Scheme',
        dataIndex: 'packing_scheme',
        key: 'product_variants.packing_scheme',
        // filters: this.getPackingSchemes(),
        // onFilter: (value, record) => {
        //   if (value !== 'ALL') {
        //     if (record.packing_scheme != null) {
        //       return record.packing_scheme === value
        //     }
        //   } else {
        //     return record.packing_scheme
        //   }
        // },
        align: 'center',
        // width: 200,
        sorter: (a, b) => {
          // const x = a.packing_scheme.toLowerCase();
          // const y = b.packing_scheme.toLowerCase();
          // if (x < y) { return -1; }
          // if (x > y) { return 1; }
          // return 0;
        },
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'products.country_made',
        // filters: this.getCountry(),
        // onFilter: (value, record) => {
        //   if (value !== 'ALL') {
        //     return record.product.country_made === value
        //   } else {
        //     return record.product.country_made
        //   }
        // },
        sorter: (a, b) => {
          // if (a.product.country_made != null && b.product.country_made != null) {
          //   const x = (a.product.country_made) ? a.product.country_made.toLowerCase() : '';
          //   const y = (b.product.country_made) ? b.product.country_made.toLowerCase() : '';
          //   if (x < y) { return -1; }
          //   if (x > y) { return 1; }
          // }
          // return 0;
        },
        render: (c, row) => {
          return <span>{row.product.country_made}</span>
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'product_variants.status',
        align: 'center',
        width: 100,
        // onFilter: (value, record) => record.status === value,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }], // change value from Active to 1 and Inactive to 0
        defaultFilteredValue: [1],
        render: (tags, row) => {
          // const status = row.status ? 'Active' : 'Inactive'
          const status = row.status
          let htmlElement

          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                disabled={row.product.status === 0}
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 1)
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleChangeStatus(row, 0)
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>
          )
        }
      },
      // {
      //   title: 'Is Disabled',
      //   dataIndex: 'is_disabled',
      //   key: 'is_disabled',
      //   align: 'center',
      //   width: 200,
      //   width: 200,
      //   sorter: (a, b) => {
      //     const x = a.is_disabled;
      //     const y = b.is_disabled;
      //     if (x < y) { return -1; }
      //     if (x > y) { return 1; }
      //     return 0;
      //   },
      //   render: (tags, row) => {
      //     const is_disabled = row.is_disabled
      //     return <span>{is_disabled}</span>
      //   }
      // },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>View</p>)}>
                <span onClick={() => row.product.status === 1 && this.handleShowViewModal(row)} style={{ cursor: row.product.status === 0 ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="eye" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => row.product.status === 1 && this.handleShowEditFormModal(row)} style={{ cursor: row.product.status === 0 ? 'no-drop' : 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
            </div>

            // <Dropdown overlay={(
            //   <Menu>
            //     {row.status === 'Active'
            //       ?
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.product_variants.edit')}
            //         onClick={() => this.handleChangeStatus(row, 0)}>
            //           Deactivate
            //         </Menu.Item> 
            //       :
            //       <Menu.Item
            //         disabled={!myPermissions.some(r => r.name === 'admin.product_variants.edit')}
            //         onClick={() => this.handleChangeStatus(row, 1)}>
            //           Activate
            //       </Menu.Item>
            //     }
            //     <Menu.Item
            //       disabled={!myPermissions.some(r => r.name === 'admin.product_variants.edit')}
            //       onClick={() => this.handleShowEditFormModal(row)}>Edit Details</Menu.Item>
            //     <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
            //   </Menu>
            // )}>
            //   <Button>
            //     Actions <Icon type="down" />
            //   </Button>
            // </Dropdown>
          )
        }
      }
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }
    return myPermissions.some(r => r.name === 'admin.product_variants.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <Row>
              <Col span={12} className="page-title">
                Product Variants
              </Col>
              <Col span={12} align="right">
                <ButtonGroup style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  <Button
                    className="btn-small btn-primary"
                    disabled={!myPermissions.some(r => r.name === 'admin.product_variants.create')}
                    type="primary"
                    onClick={this.handleShowFormModal}
                    style={{ margin: '10px' }} ><Icon type="plus" /> Add Product Variants</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col >
                <div className="product-variants-container">
                  <Search
                    className="search-container"
                    size="large"
                    placeholder="Search"
                    onSearch={(value) => this.handleSearch(value, filterBy)}
                  />
                  <Select style={{ width: 160 }}
                    // suffixIcon={<img src={FilterIcon} />}
                    size="large"
                    placeholder="Filter"
                    defaultValue="material_code" // changed the defaukt value selected in dropdown to material_code
                    onChange={(value) => this.handleFilterBy(value)}
                    dropdownClassName="--select"
                    autoFocus={true}
                    showSearch>
                    {/* <Option value="all">All</Option> */}
                    <Option value="material_code">SKU Code</Option>
                    <Option value="name">SKU Description</Option>
                    <Option value="packing_scheme">Packing Scheme</Option>
                    <Option value="product.country_made">Country</Option>
                  </Select>
                  <Table
                    rowKey="id"
                    dataSource={variantsData}
                    columns={columns}
                    loading={variantLoading}
                    onChange={this.handleOnChangeTableListener} 
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10 }}/>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        {showFormModal &&
          <ModalForm
            showFormModal={showFormModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            getFieldDecorator={getFieldDecorator}
            isLoading={variantLoading}
            products={this.activeProducts}
            loadActiveProducts={this.props.onLoadActiveProductData}
            isProductLoading={productLoading}
          />
        }


        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </div >
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const ProductVariantForm = Form.create({ name: 'product_variant_form' })(ProductVariant);

export default ProductVariantForm;
