import React, { Component } from 'react';
import {
  Upload, message, Radio,
  Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Skeleton, Spin, Result, Divider, Modal, Checkbox, Select, InputNumber,
} from 'antd';
import moment from 'moment'
import './ContactUs.scss'
import { downloadFile } from './components/downloadFile'
import { getCode, getName } from 'country-list'
import ReadMoreAndLess from 'react-read-more-less';
import { findLastIndex } from 'lodash';
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['EXCEL', 'CSV', 'PDF'];
const { Option } = Select;
const { Search } = Input;


class ContactUs extends Component {
  constructor() {
    super()
    this.state = {
      activeRadio: 'All',
      filterBy: 'all',
      tableData: [],
      showDownloadModal: false,
      indeterminate: false,
      checkAll: false,
      checkedList: [],
    }
    this.timeout = 0;
  }
  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadContact()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.contactUsData !== nextProps.contactUsData) {
      let contactDataFilteredByType
      if (this.state.activeRadio !== 'All') {
        contactDataFilteredByType = nextProps.contactUsData.filter((data) => data.type === this.state.activeRadio)
      } else {
        contactDataFilteredByType = nextProps.contactUsData
      }
      this.setState({ tableData: contactDataFilteredByType })
    }
  }


  onChangeFilterRadio(type) {
    let filteredByType = []
    if (type.toLowerCase().trim() !== 'all') {
      filteredByType = this.props.contactUsData.filter((data) => data.type.toLowerCase().trim() === type.toLowerCase().trim())
    } else {
      filteredByType = this.props.contactUsData
    }
    this.setState({ activeRadio: type, tableData: filteredByType })
  }

  handleUpdateStatus(id, status) {
    this.props.onUpdateStatus(id, status)
  }

  handleUpdateCosts(id, cost) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onUpdateCosts(id, cost)
    }, 2000);

  }

  handleUpdateVolume(id, volume) {
    this.props.onUpdateVolume(id, volume)
  }

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };


  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  handleDownload = (data) => {
    const { checkedList } = this.state
    checkedList.includes('EXCEL') && downloadFile.excel(data, this.state.activeRadio);
    checkedList.includes('CSV') && downloadFile.csv(data, this.state.activeRadio);
    checkedList.includes('PDF') && downloadFile.pdf(data, this.state.activeRadio);
    // checkedList.includes('PDF') && downloadFile.pdf(data);
  }

  handleSearch = (searchTerm, searchKeys) => {
    if (this.state.filterBy !== 'all') {
      searchKeys = [this.state.filterBy]
    }
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }

    const { orderData } = this.props
    let newArrayObj
    if (this.state.activeRadio === 'All') {
      newArrayObj = this.props.contactUsData
    } else {
      newArrayObj = this.props.contactUsData.filter((data) => data.type === this.state.activeRadio)
    }
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj })
    }
    let newArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
        if (isFound) newArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = newArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ tableData: newArrayElement })
  }


  handleFilterBy(data) {
    this.setState({
      filterBy: data.split(' ').join('_') || data,
      // tableData: this.props.contactUsData.filter((data) => data.type === this.state.activeRadio)
      // activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs
    })
  }


  render() {
    const { myPermissions, myPermissionsLoading, contactUsLoading } = this.props

    if (myPermissionsLoading) {
      // return (
      //   <Layout style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
      //     <Spin spinning={myPermissionsLoading}>
      //       <Skeleton active />
      //     </Spin>
      //   </Layout>
      // )
      return null
    }

    const downloadData = this.state.tableData.map(value => {
      return {
        created_at: value.created_at,
        company_name: value.company_name,
        full_name: value.full_name,
        country: value.country,
        email_address: value.email_address,
        contact_number: value.contact_number,
        status: value.status,
        cost: value.cost,
        volume: value.volume,
        contact_number: value.contact_number,
        message: value.message,
        type: value.type
      }
    })


    const { showDownloadModal, checkedList } = this.state
    const dataSource = this.state.tableData

    dataSource.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())

    const columns = [
      {
        title: 'CREATED DATE',
        dataIndex: 'created_at',
        key: 'created_at',
        // width: 40,
        sorter: (a, b) => {
          const x = a.created_at.toLowerCase();
          const y = b.created_at.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        }
      },
      {
        title: 'COMPANY NAME',
        dataIndex: 'company_name',
        key: 'company_name',
        // width: 40,
        sorter: (a, b) => {
          const x = a.company_name.toLowerCase();
          const y = b.company_name.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        }
      },
      {
        title: 'FULL NAME',
        dataIndex: 'full_name',
        key: 'full_name',
        // width: 40,
        sorter: (a, b) => {
          const x = a.full_name.toLowerCase();
          const y = b.full_name.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },

      },
      {
        title: 'COUNTRY',
        dataIndex: 'country',
        key: 'country',
        // width: 40,
        sorter: (a, b) => {
          const x = a.country.toLowerCase();
          const y = b.country.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (i, data) => {
          return (
            <span>{data.country}</span>
          )
        }
      },
      {
        title: 'EMAIL ADDRESS',
        dataIndex: 'email_address',
        key: 'email_address',
        // width: 40,
        sorter: (a, b) => {
          const x = a.email_address.toLowerCase();
          const y = b.email_address.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'CONTACT NUMBER',
        dataIndex: 'contact_number',
        key: 'contact_number',
        // width: 40,
        sorter: (a, b) => {
          const x = a.contact_number.toLowerCase();
          const y = b.contact_number.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },

      },
      {
        title: 'CUSTOMER TAGGING',
        dataIndex: 'status',
        key: 'status',
        // width: 40,
        className: this.state.activeRadio !== 'Product / Distribution Inquiry' && 'hidden',
        // className: 'hidden',
        filters: [{ text: "New", value: 'New' }, { text: "Existing", value: 'Existing' }],
        onFilter: (value, record) => record.status === value,
        render: (id, data) => {
          let htmlElemet
          if (data.status === 'NoStatus') htmlElemet = <span style={{ cursor: 'pointer' }}>Add Tag</span>
          else if (data.status === 'New') htmlElemet = <Button className='contact-tag-new'>New</Button>
          else if (data.status === 'Existing') htmlElemet = <Button className='contact-tag-existing'>Existing</Button>
          return (
            <div style={{ width: 100 }}>
              <Dropdown overlay={<Menu style={{ top: -30, left: -15 }} >
                <Menu.Item>
                  <span>Add Tag</span>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                  <Button className='contact-tag-existing' onClick={() => this.handleUpdateStatus(data.id, 2)}>Existing</Button>
                </Menu.Item>
                <Menu.Item>
                  <Button className='contact-tag-new' onClick={() => this.handleUpdateStatus(data.id, 1)}>New</Button>
                </Menu.Item>
              </Menu>} className='dropdown-tag' trigger={['click']} >
                {htmlElemet}
              </Dropdown>
            </div>

          )
        }
      },
      {
        title: 'INQUIRY VALUE (IN USD)',
        dataIndex: 'cost',
        key: 'cost',
        // width: 40,
        sorter: (a, b) => {
          const x = a.cost;
          const y = b.cost;
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        className: this.state.activeRadio !== 'Product / Distribution Inquiry' && 'hidden',
        // className: 'hidden',
        render: (i, data) => {
          return (
            <InputNumber
              key={data.id}
              defaultValue={data.cost}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(e) => this.handleUpdateCosts(data.id, e)}
              style={{ width: 120 }}
            />
          )
        }
      },
      {
        title: 'INQUIRY VOLUME',
        dataIndex: 'volume',
        key: 'volume',
        // width: 40,
        className: this.state.activeRadio !== 'Product / Distribution Inquiry' && 'hidden',
        // className: 'hidden',
        sorter: (a, b) => {
          const x = a.volume;
          const y = b.volume;
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (i, data) => {
          return (
            <Select defaultValue={data.volume} key={data.id} style={{ width: 90 }} onChange={(e) => this.handleUpdateVolume(data.id, e)}>
              <Select.Option value='20FT'>20FT</Select.Option>
              <Select.Option value='40FT'>40FT</Select.Option>
              <Select.Option value='40HC'>40HC</Select.Option>
              <Select.Option value='Others'>Others</Select.Option>
            </Select>
          )
        }
      },
      {
        title: 'MESSAGE',
        dataIndex: 'message',
        key: 'message',
        // width: 100,
        sorter: (a, b) => {
          const x = a.message.toLowerCase();
          const y = b.message.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: (i, data) => {
          return (
            <div style={{ width: 210 }}>
              <ReadMoreAndLess
                // ref={data.message}
                className="read-more-content"
                charLimit={250}
                readMoreText="Read more"
                readLessText="Read less"
              >
                {data.message}
              </ReadMoreAndLess>
            </div>
          )
        }

      },
    ];


    return myPermissions.some(r => r.name === 'admin.contact.view') ? (
      // return (
      <div>
        <Layout className='contact-us-container' style={{ padding: 25 }}>
          <Layout.Content className='contact-us-header'>
            <h1>Contact Us Database</h1>
            <Button
              className='extract-btn'
              type="primary"
              disabled={!myPermissions.some(r => r.name === 'admin.contact.download')}
              onClick={() => this.setState({ showDownloadModal: true })}
              style={{ margin: '10px' }}
            >Extract</Button>
          </Layout.Content>
          <Layout.Content className='contact-us-filter'>
            <h1>Sort by type of Inquiry</h1>
            <Row className='filter-radio'>
              <label className="radio">All
              <input type="radio" name="volume" checked={this.state.activeRadio === 'All'} value="All" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
              <label className="radio">Product / Distribution Inquiry
              <input type="radio" name="volume" checked={this.state.activeRadio === 'Product / Distribution Inquiry'} value="Product / Distribution Inquiry" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
              <label className="radio">Offered Services
              <input type="radio" name="volume" checked={this.state.activeRadio === 'Offered Services'} value="Offered Services" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
              <label className="radio">Website OMS Feedback
              <input type="radio" name="volume" checked={this.state.activeRadio === 'Website OMS Feedback'} value="Website OMS Feedback" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
              <label className="radio">Suggestions
              <input type="radio" name="volume" checked={this.state.activeRadio === 'Suggestions'} value="Suggestions" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
              <label className="radio">Others
              <input type="radio" name="volume" checked={this.state.activeRadio === 'Others'} value="Others" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                <span className="checkmark"></span>
              </label>
            </Row>
            <Layout.Content className="contact-us-table-content">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='contact-search'>
                <Col md={8}>
                  <Search
                    className="search-container"
                    placeholder="Search"
                    onSearch={value => this.handleSearch(value, [
                      'created_at',
                      'created_at_whole',
                      'created_at_number',
                      'company_name',
                      'contact_number',
                      'cost',
                      'country',
                      'email_address',
                      'full_name',
                      'status',
                      'message',
                      'volume'
                    ])}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col md={16} align="left" className='contact-sort-by'>
                  <div className='filter-by-container'>
                    <span>FILTER BY</span>
                    <Select style={{ width: 200 }} defaultValue="All" onChange={(e) => this.handleFilterBy(e)} showSearch>
                      <Option value="all">All</Option>
                      <Option value="company name">Company Name</Option>
                      <Option value="full name">Full Name</Option>
                      <Option value="country">Country</Option>
                      <Option value="email address">Email Address</Option>
                      <Option value="contact number">Contact Number</Option>
                      <Option value="message">Message</Option>
                      {(this.state.activeRadio === 'Product / Distribution Inquiry') && <Option value="status">Tagging</Option>}
                      {(this.state.activeRadio === 'Product / Distribution Inquiry') && <Option value="cost">Inquiry Value (IN USD)</Option>}
                      {(this.state.activeRadio === 'Product / Distribution Inquiry') && <Option value="volume">Inquiry Volume</Option>}
                    </Select>
                  </div>
                </Col>
              </Row>
              <Row className='contact-us-table'>
                <Table loading={contactUsLoading} dataSource={dataSource} rowKey="id" columns={columns} onRow={(row, rowIndex) => {
                  return <span style={{ width: "100%" }}>{row}</span>
                }} />
              </Row>
            </Layout.Content>
            <Layout.Content >

            </Layout.Content>
          </Layout.Content>
        </Layout>

        <Modal
          title="Download Option"
          visible={showDownloadModal}
          width={350}
          onCancel={() => this.setState({ showDownloadModal: false })}
          footer={null}
          className="modal"
        >
          <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            All
                    </Checkbox>
          <br />
          <CheckboxGroup
            options={plainOptions}
            value={checkedList}
            onChange={this.onChange}
          />
          <Row>
            <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
          </Row>

          <Row type="flex" style={{ margin: "10px 0px" }}>
            <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
            <Col span={12}><Button className="btn-small btn-primary" disabled={checkedList.length < 1} onClick={() => this.handleDownload(downloadData)}>Download</Button></Col>
          </Row>
        </Modal>

      </div>
    )
      : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const ContactUsForm = Form.create({ name: 'contact-us' })(ContactUs);

export default ContactUsForm;
