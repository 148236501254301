import Axios from 'axios'
import { headers } from '../../../utils/token'

const POST_RESET_PASSWORD_DATA = 'POST_RESET_PASSWORD_DATA'
const POST_RESET_PASSWORD_FULFILLED = 'POST_RESET_PASSWORD_FULFILLED'
const POST_RESET_PASSWORD_REJECTED = 'POST_RESET_PASSWORD_REJECTED'

const API_URL = process.env.REACT_APP_API_URL

export const submitResetPassword = (data) => {
  return dispatch => {
    dispatch({
      type: POST_RESET_PASSWORD_DATA,
      payload: {}
    })
    return Axios.post(`${API_URL}/password/reset`, { ...data })
      .then(function (response) {
        dispatch({
          type: POST_RESET_PASSWORD_FULFILLED,
          payload: response.data
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_RESET_PASSWORD_REJECTED,
          payload: error
        })
        return { error: true, message: error.response && error.response.data ? error.response.data.error_code : null }
      })
  }
}
