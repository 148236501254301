import moment from 'moment'
const initialData = {
  inquiryDbLoading: false,
  inquiryDbLoaded: false,
  inquiryDbRejected: false,
  inquiryDbData: []
}
export default function INQUIRYDB(state = initialData, action) {
  switch (action.type) {
    case 'GET_INQUIRY_DB_DATA':
      return {
        ...state,
        inquiryDbLoading: true,
        inquiryDbLoaded: false,
        inquiryDbRejected: false,
      }
    case 'GET_INQUIRY_DB_DATA_FULFILLED':
      action.payload.success.map((data, i) => {
        data.is_yes = data.is_yes === 1 ? 'yes' : 'noe'
        data.created_at = moment(data.created_at).format("MM/DD/YYYY")
        data.created_at_local = moment(data.created_at).format('LLLL')
      })
      return {
        ...state,
        inquiryDbLoading: false,
        inquiryDbLoaded: true,
        inquiryDbRejected: false,
        inquiryDbData: action.payload.success
      }
    case 'GET_INQUIRY_DB_DATA_REJECTED':
      return {
        ...state,
        faqCategoryData: '',
        inquiryDbLoading: false,
        inquiryDbLoaded: false,
        inquiryDbRejected: true,
      }
    default:
      return {
        ...state
      };
  }
}
