import Axios from '../../../utils/axios'

export const loadAdminManagementData = (data) => {
  return dispatch => {
    Axios.get('/admin/users', dispatch, 'GET_ADMIN_MANAGEMENT', data)
  }
}

export const submitAddAdminManagement = (data, searchParams) => {
  return dispatch => {
    return Axios.post(`/admin/user`, dispatch, 'POST_ADMIN_MANAGEMENT', data)
      .then((response) => {
        dispatch(loadAdminManagementData(searchParams))
        return response
      })
  }
}

export const submitUpdateAdminManagement = (data, searchParams) => {
  return dispatch => {
    return Axios.post(`/admin/user/${data.id}`, dispatch, 'POST_ADMIN_MANAGEMENT', data)
      .then((response) => {
        dispatch(loadAdminManagementData(searchParams))
        return response
      })
  }
}

export const loadUserRolesData = (data) => {
  return dispatch => {
    Axios.get('/admin/role', dispatch, 'GET_USER_ROLES', data)
  }
}
