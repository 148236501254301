import moment from 'moment'
const initialData = {
  auditTrailLoading: false,
  auditTrailLoaded: false,
  auditTrailRejected: false,
  auditTrailData: []
}
export default function AUDITTRAIL(state = initialData, action) {
  switch (action.type) {
    case 'GET_AUDIT_TRAIL':
      return {
        ...state,
        auditTrailLoading: true,
        auditTrailLoaded: false,
        auditTrailRejected: false,
      }
    case 'GET_AUDIT_TRAIL_FULFILLED':
      action.payload.map((data, i) => {
        data.updated_at_local = moment(new Date(data.updated_at)).format('LLLL')
        // data.content = JSON.parse(data.content)
      })
      return {
        ...state,
        auditTrailLoading: false,
        auditTrailLoaded: true,
        auditTrailRejected: false,
        auditTrailData: action.payload
      }
    case 'GET_AUDIT_TRAIL_REJECTED':
      return {
        ...state,
        auditTrailLoading: false,
        auditTrailLoaded: false,
        auditTrailRejected: true,
      }
    default:
      return {
        ...state
      };
  }
}
