import { Badge, Button, Card, Checkbox, Col, DatePicker, Layout, Modal, Result, Row, Select, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import downloadIcon from '../../assets/images/download-icon.png';
import resetIcon from '../../assets/images/reset-icon.png';
import { getName } from 'country-list'

import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import Papa from 'papaparse'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import './InquiryDashboard.scss';

const wb = new ExcelJS.Workbook()

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const plainOptions = ['EXCEL', 'CSV', 'PDF'];

export default class Dashboard extends Component {
    state = {
        month: "this month",
        selectedCountries: ['all'],
        showDownloadModal: false,
        showCustomDateModal: false,
        indeterminate: false,
        checkAll: false,
        checkedList: [],
        startDate: moment().startOf('month').format('MM-DD-YYYY'),
        endDate: moment().endOf('month').format('MM-DD-YYYY'),
        rangePicker: []
    }

    filterCountries = selectedCountries => {
        this.setState({ selectedCountries });
    }

    filterCustomers = selectedCustomers => {
        this.setState({ selectedCustomers });
    }

    onChange = checkedList => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
    };

    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    numberFormat = (amount, decimalPlace = 2) => {
        if (isNaN(amount)) {
            return amount;
        }

        const pieces = parseFloat(amount)
            .toFixed(decimalPlace)
            .split("");
        let ii = pieces.length - 3;
        while ((ii -= 3) > 0) {
            pieces.splice(ii, 0, ",");
        }

        return pieces.join("");
    };

    getTotal = (data, column) => data.reduce((a, c) => a + parseFloat(c[column]), 0)

    groupBy = (data, key) => {
        return data.reduce(function (storage, item) {
            var group = item[key];
            storage[group] = storage[group] || [];
            storage[group].push(item);
            return storage;
        }, {});
    };

    handleMonth = (value) => {
        const today = moment().format('MM-DD-YYYY');
        const startOfWeek = moment().startOf('week').format('MM-DD-YYYY');
        const endOfWeek = moment().endOf('week').format('MM-DD-YYYY');
        const startOfMonth = moment().startOf('month').format('MM-DD-YYYY')
        const endOfMonth = moment().endOf('month').format('MM-DD-YYYY')
        const startOfYear = moment().startOf('year').format('MM-DD-YYYY')
        const endOfYear = moment().endOf('year').format('MM-DD-YYYY')

        let startDate, endDate;

        if (value === "today") {
            startDate = today
            endDate = today
        } else if (value === "this week") {
            startDate = startOfWeek
            endDate = endOfWeek
        } else if (value === "this month") {
            startDate = startOfMonth
            endDate = endOfMonth
        } else if (value === "this year") {
            startDate = startOfYear
            endDate = endOfYear
        } else {
            startDate = this.state.startDate
            endDate = this.state.endDate
        }

        this.setState({
            month: value,
            showCustomDateModal: value === "custom date",
            rangePicker: [],
            startDate,
            endDate,
        })
    }

    removeDuplicates = (myArr, prop) => {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    filterData = (data, predicate) => {
        // if no data is sent in, return null, otherwise transform the data
        return !!!data ? null : data.reduce((list, entry) => {
            let clone = null;
            if (predicate(entry)) {
                // if the object matches the filter, clone it as it is
                clone = Object.assign({}, entry)
            } else if (entry.order != null) {
                // if the object has orders, filter the list of order
                let order = this.filterData(entry.order, predicate)
                if (order.length > 0) {
                    // if any of the order matches, clone the parent object, overwrite
                    // the order list with the filtered list
                    clone = Object.assign({}, entry, { order: order })
                }
            }
            // if there's a cloned object, push it to the output list
            clone && list.push(clone)
            return list
        }, [])

    }

    // exportExcel = async (data) => {
    //     const worksheet = wb.addWorksheet("INQUIRY_DASHBOARD_REPORT")

    //     // Width of column
    //     worksheet.getColumn('A').width = 16
    //     worksheet.getColumn('B').width = 20
    //     worksheet.getColumn('C').width = 20
    //     worksheet.getColumn('D').width = 25
    //     worksheet.getColumn('E').width = 20
    //     worksheet.getColumn('F').width = 16
    //     worksheet.getColumn('G').width = 12
    //     worksheet.getColumn('H').width = 20
    //     worksheet.getColumn('I').width = 24
    //     worksheet.getColumn('J').width = 14

    //     // TOTALS
    //     const lastRow = 6
    //     let topInquiriesData = data.map(item => { return item.topInquiries.length })
    //     let inquiryCountChartRow = lastRow + topInquiriesData[0] + 3

    //     worksheet.getCell(`A1`).value = 'TOTAL VALUE OF INQUIRIES';
    //     worksheet.getCell(`A1`).font = { bold: true };
    //     worksheet.getCell((`A2`).toString()).value = `$${this.numberFormat(Number(data[0].totalValueOfInquiries))}`;

    //     worksheet.getCell(`B1`).value = 'TOTAL INQUIRIES FROM NEW CUSTOMERS';
    //     worksheet.getCell(`B1`).font = { bold: true };
    //     worksheet.getCell((`B2`).toString()).value = data[0].totalInquiriesFromNewCustomers;

    //     worksheet.getCell(`C1`).value = 'TOTAL INQUIRIES FROM EXISTING CUSTOMERS';
    //     worksheet.getCell(`C1`).font = { bold: true };
    //     worksheet.getCell((`C2`).toString()).value = data[0].totalInquiriesFromExistingCustomers;

    //     worksheet.getCell(`D1`).value = 'TOTAL COUNTRIES';
    //     worksheet.getCell(`D1`).font = { bold: true };
    //     worksheet.getCell((`D2`).toString()).value = data[0].totalCountries;

    //     worksheet.getCell(`E1`).value = 'TOTAL INQUIRY VOLUME';
    //     worksheet.getCell(`E1`).font = { bold: true };
    //     worksheet.getCell((`E2`).toString()).value = data[0].totalInquiryVolume;

    //     worksheet.getCell(`F1`).value = 'TOTAL 20FT VOLUME INQUIRIES';
    //     worksheet.getCell(`F1`).font = { bold: true };
    //     worksheet.getCell((`F2`).toString()).value = data[0].total20FT;

    //     worksheet.getCell(`G1`).value = 'TOTAL 40FT VOLUME INQUIRIES';
    //     worksheet.getCell(`G1`).font = { bold: true };
    //     worksheet.getCell((`G2`).toString()).value = data[0].total40FT;

    //     worksheet.getCell(`H1`).value = 'TOTAL 40HC VOLUME INQUIRIES';
    //     worksheet.getCell(`H1`).font = { bold: true };
    //     worksheet.getCell((`H2`).toString()).value = data[0].total40HC;

    //     worksheet.getCell(`I1`).value = 'TOTAL OTHERS VOLUME INQUIRIES';
    //     worksheet.getCell(`I1`).font = { bold: true };
    //     worksheet.getCell((`I2`).toString()).value = data[0].totalOthers;

    //     // TOP 8 INQUIRIES CHART
    //     worksheet.mergeCells('A4:J4');
    //     worksheet.getCell('A4').value = 'INQUIRY VALUE PER COUNTRY (TOP 8 BY VALUE)';
    //     worksheet.getCell('A4').alignment = { horizontal: 'center' };
    //     worksheet.getCell('A4').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('A4').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('A5').value = 'INQUIRY DATE';
    //     worksheet.getCell('A5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('A5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('A5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('B5').value = 'COMPANY NAME';
    //     worksheet.getCell('B5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('B5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('B5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('C5').value = 'FULL NAME';
    //     worksheet.getCell('C5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('C5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('C5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('D5').value = 'MESSAGE';
    //     worksheet.getCell('D5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('D5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('D5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('E5').value = 'TYPE';
    //     worksheet.getCell('E5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('E5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('E5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('F5').value = 'COST';
    //     worksheet.getCell('F5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('F5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('F5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('G5').value = 'VOLUME';
    //     worksheet.getCell('G5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('G5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('G5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('H5').value = 'CONTACT NUMBER';
    //     worksheet.getCell('H5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('H5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('H5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('I5').value = 'EMAIL ADDRESS';
    //     worksheet.getCell('I5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('I5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('I5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     worksheet.getCell('J5').value = 'COUNTRY';
    //     worksheet.getCell('J5').alignment = { horizontal: 'center' };
    //     worksheet.getCell('J5').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('J5').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

    //     data.map(item => item.topInquiries.map(
    //         (value, i) => {
    //             worksheet.getCell((`A${lastRow + i}`).toString()).value = moment(value.created_at).format("MM-DD-YYYY");
    //             worksheet.getCell((`B${lastRow + i}`).toString()).value = value.company_name;
    //             worksheet.getCell((`C${lastRow + i}`).toString()).value = value.full_name;
    //             worksheet.getCell((`D${lastRow + i}`).toString()).value = value.message;
    //             worksheet.getCell((`E${lastRow + i}`).toString()).value = value.type;
    //             worksheet.getCell((`F${lastRow + i}`).toString()).value = `$${this.numberFormat(Number(value.cost))}`;
    //             worksheet.getCell((`G${lastRow + i}`).toString()).value = value.volume !== null ? value.volume : "";
    //             worksheet.getCell((`H${lastRow + i}`).toString()).value = value.contact_number;
    //             worksheet.getCell((`I${lastRow + i}`).toString()).value = value.email_address;
    //             worksheet.getCell((`J${lastRow + i}`).toString()).value = getName(value.country);
    //         }
    //     ))

    //     // INQUIRY COUNT PER COUNTRY CHART
    //     worksheet.mergeCells('A15:C15');
    //     worksheet.getCell('A15').value = 'INQUIRY COUNT PER COUNTRY';
    //     worksheet.getCell('A15').alignment = { horizontal: 'center' };
    //     worksheet.getCell('A15').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('A15').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '78D89F' } };

    //     worksheet.mergeCells('A16:B16');
    //     worksheet.getCell('A16').value = 'COUNTRY';
    //     worksheet.getCell('A16').alignment = { horizontal: 'center' };
    //     worksheet.getCell('A16').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('A16').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '78D89F' } };

    //     worksheet.getCell('C16').value = 'INQUIRY COUNT';
    //     worksheet.getCell('C16').alignment = { horizontal: 'center' };
    //     worksheet.getCell('C16').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
    //     worksheet.getCell('C16').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '78D89F' } };

    //     data.map(item => item.totalInquiryCountPerCountry.map(
    //         (value, i) => {
    //             worksheet.getCell((`A${inquiryCountChartRow + i}`).toString()).value = getName(value.country);
    //             worksheet.getCell((`C${inquiryCountChartRow + i}`).toString()).value = value.count;
    //         }
    //     ))

    //     const excelData = await wb.xlsx.writeBuffer()

    //     saveAs(new Blob([excelData]), 'inquiry-dashboard-report.xlsx')
    //     wb.removeWorksheet(worksheet.id)
    // }

    // exportCSV = (data) => {
    //     const csv = Papa.unparse({
    //         "fields": ["INQUIRY DATE", "COMPANY NAME", "FULL NAME", "MESSAGE", "TYPE", "COST", "VOLUME", "CONTACT NUMBER", "EMAIL ADDRESS", "COUNTRY"],
    //         "data": data.map(value => Object.values(value)),
    //         delimiter: "",
    //         newline: "",
    //         delimitersToGuess: [',', '|']
    //     });

    //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    //     saveAs(blob, "inquiry-dashboard-report.csv");
    // }

    // exportPDF = async (data) => {
    //     const pdfDoc = await PDFDocument.create()

    //     // Embed the Times Roman font
    //     const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

    //     // Split page by 80
    //     let pageArray = this.splitArrayIntoChunksOfLen(data, 80)

    //     let pageCount = 0
    //     pageArray.map((value, i) => {
    //         const page = pdfDoc.addPage()
    //         const { height } = page.getSize()
    //         page.moveTo(110, 200);

    //         // TOTALS
    //         // value.map((value, i) => {
    //         //     page.drawText(value.totalValueOfInquiries, { x: 30, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.full_name.length > 8 ? `${value.full_name.substring(0, 8)}...` : value.full_name, { x: 110, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.message.length > 18 ? `${value.message.substring(0, 18)}...` : value.message, { x: 180, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.type.length > 10 ? `${value.type.substring(0, 10)}...` : value.type, { x: 260, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(this.numberFormat(value.cost), { x: 260, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.volume, { x: 350, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.contact_number, { x: 350, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(value.email_address.length > 10 ? `${value.email_address.substring(0, 10)}...` : value.email_address, { x: 420, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         //     page.drawText(getName(value.country), { x: 470, y: height - (6 + i) * 9, size: 9, font: timesRomanFont })
    //         // })

    //         page.drawText('TOTAL VALUE OF INQUIRIES:', { x: 30, y: height - 4 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`$${this.numberFormat(value[0].totalValueOfInquiries)}`, { x: 170, y: height - 4 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('TOTAL COUNTRIES:', { x: 250, y: height - 4 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].totalCountries}`, { x: 345, y: height - 4 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('TOTAL INQUIRY VOLUME:', { x: 400, y: height - 4 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].totalInquiryVolume}`, { x: 525, y: height - 4 * 9, size: 9, font: timesRomanFont })


    //         page.drawText('TOTAL 20FT VOLUME INQUIRIES:', { x: 30, y: height - 6 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].total20FT}`, { x: 180, y: height - 6 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('TOTAL 40HC VOLUME INQUIRIES:', { x: 300, y: height - 6 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].total40HC}`, { x: 450, y: height - 6 * 9, size: 9, font: timesRomanFont })

    //         page.drawText('TOTAL 40FT VOLUME INQUIRIES:', { x: 30, y: height - 8 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].total40FT}`, { x: 180, y: height - 8 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('TOTAL OTHERS VOLUME INQUIRIES:', { x: 300, y: height - 8 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].totalOthers}`, { x: 465, y: height - 8 * 9, size: 9, font: timesRomanFont })

    //         page.drawText('TOTAL INQUIRIES FROM NEW CUSTOMERS:', { x: 30, y: height - 10 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].totalInquiriesFromNewCustomers}`, { x: 225, y: height - 10 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('TOTAL INQUIRIES FROM EXISTING CUSTOMERS:', { x: 300, y: height - 10 * 9, size: 9, font: timesRomanFont })
    //         page.drawText(`${value[0].totalInquiriesFromExistingCustomers}`, { x: 520, y: height - 10 * 9, size: 9, font: timesRomanFont })


    //         // // INQUIRY VALUE PER COUNTRY (TOP 8 BY VALUE) CHART
    //         page.drawText('INQUIRY VALUE PER COUNTRY (TOP 8 BY VALUE)', { x: 180, y: height - 14 * 9, size: 9, font: timesRomanFont })

    //         page.drawText('#', { x: 30, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('COUNTRY', { x: 40, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('COMPANY', { x: 95, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('COST', { x: 150, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('VOLUME', { x: 205, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('DATE', { x: 255, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('NAME', { x: 300, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('CONTACT #', { x: 365, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('EMAIL', { x: 425, y: height - 16 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('MESSAGE', { x: 485, y: height - 16 * 9, size: 9, font: timesRomanFont })

    //         value[0].topInquiries.map((top, i) => {
    //             pageCount++

    //             page.drawText((pageCount).toString(), { x: 30, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(getName(top.country).length > 10 ? `${getName(top.country).substring(0, 10)}...` : getName(top.country), { x: 40, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.company_name.length > 8 ? `${top.company_name.substring(0, 8)}...` : top.company_name, { x: 95, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(`$${this.numberFormat(top.cost)}`, { x: 150, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.volume, { x: 210, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(`${moment(top.created_at).format("MM-DD-YYYY")}`, { x: 250, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.full_name.length > 10 ? `${top.full_name.substring(0, 10)}...` : top.full_name, { x: 300, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.contact_number.length > 11 ? `${top.contact_number.substring(0, 11)}` : `${top.contact_number}`, { x: 365, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.email_address.length > 10 ? `${top.email_address.substring(0, 10)}...` : top.email_address, { x: 425, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(top.message.length > 10 ? `${top.message.substring(0, 10)}...` : top.message, { x: 485, y: height - (17 + i) * 9, size: 9, font: timesRomanFont })
    //         })

    //         // INQUIRY COUNT PER COUNTRY CHART
    //         page.drawText('INQUIRY COUNT PER COUNTRY', { x: 200, y: height - 28 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('COUNT', { x: 200, y: height - 30 * 9, size: 9, font: timesRomanFont })
    //         page.drawText('COUNTRY', { x: 265, y: height - 30 * 9, size: 9, font: timesRomanFont })

    //         value[0].totalInquiryCountPerCountry.map((country, i) => {
    //             page.drawText(`${country.count}`, { x: 215, y: height - (31 + i) * 9, size: 9, font: timesRomanFont })
    //             page.drawText(getName(country.country), { x: 265, y: height - (31 + i) * 9, size: 9, font: timesRomanFont })
    //         })

    //     })

    //     const pdfBytes = await pdfDoc.save()
    //     const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
    //     saveAs(pdfBlob, "inquiry-dashboard-report.pdf");
    // }

    // handleDownload = (data) => {
    //     const { checkedList } = this.state
    //     checkedList.includes('EXCEL') && this.exportExcel(data);
    //     checkedList.includes('CSV') && this.exportCSV(data);
    //     checkedList.includes('PDF') && this.exportPDF(data);
    // }

    getNameById = (id) => {
        const distributor = this.props.dashboardData.find(value => value.id === Number(id))
        return distributor && distributor.name ? distributor.name.replace(/[^A-Z0-9]+/ig, '').substring(0, 3).toUpperCase() : ""
    }

    kFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(0) + 'K';
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num < 900) {
            return num;
        }
    }

    numberFormat = (amount, decimalPlace = 2) => {
        if (isNaN(amount)) {
            return amount;
        }

        const pieces = parseFloat(amount)
            .toFixed(decimalPlace)
            .split("");
        let ii = pieces.length - 3;
        while ((ii -= 3) > 0) {
            pieces.splice(ii, 0, ",");
        }

        return pieces.join("");
    };

    splitArrayIntoChunksOfLen = (arr, len) => {
        let chunks = [], i = 0, n = arr.length;
        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }

    componentDidMount() {
        this.props.fetchPermissions()
        this.props.fetchInquiryDashboardData()
    }

    render() {
        const {
            myPermissions,
            myPermissionsLoading,
            inquiryDashboardData,
            inquiryDashboardLoading
        } = this.props
        const { month, showDownloadModal, showCustomDateModal, startDate, endDate, rangePicker, checkedList, selectedCountries } = this.state

        let filteredInquiryDashboardData = inquiryDashboardData

        filteredInquiryDashboardData = this.filterData(filteredInquiryDashboardData, function (item) {
            return moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        });

        const countryData = this.filterData(inquiryDashboardData, function (item) {
            return moment(moment(item.created_at).format("MM-DD-YYYY")).isBetween(startDate, endDate, null, '[]')
        })

        filteredInquiryDashboardData = selectedCountries.includes("all") || selectedCountries.length === 0 ?
            filteredInquiryDashboardData :
            filteredInquiryDashboardData.filter(value => selectedCountries.includes(value.country))

        if (selectedCountries[0] === 'all' && selectedCountries.length > 1) {
            selectedCountries.splice(selectedCountries.indexOf('all'), 1)
            filteredInquiryDashboardData = filteredInquiryDashboardData.filter(value => selectedCountries.includes(value.country))
        } else if (selectedCountries[selectedCountries.length - 1] === 'all' && selectedCountries.length > 1) {
            selectedCountries.splice(0, selectedCountries.length, 'all')
            filteredInquiryDashboardData = filteredInquiryDashboardData
        }

        // Total Value of Inquiries
        const inquiryValues = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').map(inquiry => { return inquiry.cost })
        let totalValueOfInquiries = inquiryValues.reduce((a, b) => { return a + b }, 0);

        // Total Inquiries Received from New Customers
        const totalInquiriesFromNewCustomers = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.status === 1 }).length

        // Total Inquiries Received from Existing Customers
        const totalInquiriesFromExistingCustomers = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.status === 2 }).length

        // Total Number of Countries
        const totalCountries = this.removeDuplicates(filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(value => !!value.country), "country").length

        // Total Inquiry Volume
        const totalInquiryVolume = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => {
            return inquiry.volume !== null
        }).length

        // Containers
        const total20FT = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.volume === '20FT' }).length
        const total40FT = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.volume === '40FT' }).length
        const total40HC = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.volume === '40HC' }).length
        const totalOthers = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(inquiry => { return inquiry.volume === 'Others' }).length

        // Country List
        const countryList = this.removeDuplicates(countryData.filter(value => !!value.country), "country")

        const countryChartData = this.removeDuplicates(filteredInquiryDashboardData.filter(value => !!value.country), "country")

        // Inquiry Type List
        const inquiryTypeList = this.removeDuplicates(filteredInquiryDashboardData.filter(value => !!value.type), "type")

        // New And Existing List
        const newAndExistingList = this.removeDuplicates(filteredInquiryDashboardData.filter(value => !!value.status).filter(value => value.status !== 0), "status")

        // const desctructureData = () => {

        //     // unique Registered Country List
        //     const uniqueCountryList = filteredInquiryDashboardData
        //       .map((row) => row.country)
        //       .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

        //     // compared Data to Country List if have
        //     const allData = filteredInquiryDashboardData.filter((row) =>
        //       uniqueCountryList.includes(row.country)
        //     );

        //     // store country and count registered
        //     const allCountry = [];
        //     uniqueCountryList.forEach((row1) => {
        //       const f = allData.filter((row) => row.country === row1);
        //       allCountry.push({
        //         country: row1,
        //         count: f.filter((row) => row.country === row1).length,
        //       });
        //     });
        //     return allCountry;
        //   };


        // Bar Data
        const inquiryCountTop8 = countryChartData.map(value => {
            return {
                country: value.country,
                count:
                    filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(item => {
                        return item.country === value.country
                    }).length
            }
        }).filter(value => !!value.country).filter(value => value.count !== 0).sort((a, b) => b.count - a.count).slice(0, 8)

        const othersCountData = countryChartData.map(value => {
            return {
                country: value.country,
                count:
                    filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(item => {
                        return item.country === value.country
                    }).length
            }
        }).filter(value => !!value.country).filter(value => value.count !== 0).sort((a, b) => b.count - a.count).slice(8, countryChartData.length)

        const inquiryCountOthers = {
            country: 'Others',
            count: this.getTotal(othersCountData, 'count')
        }

        // const inquiryCountBar = countryChartData.map(value => {
        //     return {
        //         country: value.country,
        //         count: 
        //         filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').filter(item => {
        //             return item.country === value.country
        //         }).length
        //     }
        // }).filter(value => !!value.country).filter(value => value.count !== 0).sort((a, b) => b.count - a.count).slice(0, 8)

        let inquiryCountBar = []

        othersCountData.length !== 0 ? inquiryCountBar = [...inquiryCountTop8, inquiryCountOthers] : inquiryCountBar = [...inquiryCountTop8]

        const inquiryValueTop8 = countryList.map(value => {
            return {
                country: value.country,
                usd: value.cost
            }
        }).filter(value => value.usd !== 0).sort((a, b) => b.usd - a.usd).slice(0, 8)

        const othersValueData = countryList.map(value => {
            return {
                country: value.country,
                usd: value.cost
            }
        }).filter(value => value.usd !== 0).sort((a, b) => b.usd - a.usd).slice(0, 8)

        const inquiryValueOthers = {
            country: 'Others',
            usd: this.getTotal(othersValueData, 'usd')
        }

        let inquiryValueBar = []

        othersValueData.length !== 0 && countryList.length > 8 ? inquiryValueBar = [...inquiryValueTop8, inquiryValueOthers] : inquiryValueBar = [...inquiryValueTop8]

        const newAndExistingChunk = newAndExistingList.map(value => {
            return {
                status: value.status,
                count:
                    filteredInquiryDashboardData.filter(item => {
                        return item.status === value.status
                    }).length,
            }
        }).filter(value => !!value.status)

        let newAndExistingCounts = newAndExistingChunk.map((value) => value.count);
        let totalNewAndExistingCounts = newAndExistingCounts.reduce((a, b) => {
            return a + b;
        }, 0);

        newAndExistingChunk.map(item => {
            item.total = totalNewAndExistingCounts
        })

        const overallInquiryChunk = inquiryTypeList.map(value => {
            return {
                type: value.type,
                count:
                    filteredInquiryDashboardData.filter(item => {
                        return item.type === value.type
                    }).length
            }
        }).filter(value => !!value.type)

        let overallInquiryCounts = overallInquiryChunk.map((value) => value.count);
        let totalOverallInquiryCounts = overallInquiryCounts.reduce((a, b) => {
            return a + b;
        }, 0);

        overallInquiryChunk.map(item => {
            item.total = totalOverallInquiryCounts
        })

        // const topInquiries = Object.assign(newAndExistingList.filter(value => value.cost !== 0).sort((a, b) => b.cost - a.cost).slice(0, 8))
        // const totalInquiryPerCountry = Object.assign(inquiryCountBar)

        // const downloadData = filteredInquiryDashboardData.map(value => {
        //     return {
        //         // Per Inquiry Data
        //         company_name: value.company_name,
        //         full_name: value.full_name,
        //         message: value.message,
        //         type: value.type,
        //         contact_number: value.contact_number,
        //         email_address: value.email_address,
        //         country: getName(value.country),
        //         cost: `$${this.numberFormat(Number(value.cost))}`,
        //         volume: value.volume,
        //         created_at: moment(value.created_at).format("MM-DD-YYYY"),
        //         updated_at: moment(value.updated_at).format("MM-DD-YYYY"),

        //         // Totals
        //         totalValueOfInquiries: totalValueOfInquiries,
        //         totalInquiriesFromNewCustomers: totalInquiriesFromNewCustomers,
        //         totalInquiriesFromExistingCustomers: totalInquiriesFromExistingCustomers,
        //         totalCountries: totalCountries,
        //         totalInquiryVolume: totalInquiryVolume,
        //         total20FT: total20FT,
        //         total40FT: total40FT,
        //         total40HC: total40HC,
        //         totalOthers: totalOthers,

        //         // Charts
        //         topInquiries: topInquiries,
        //         totalInquiryCountPerCountry: totalInquiryPerCountry

        //         // total_amount: this.getTotal(value.product.map(value => { return { amount: parseFloat((value.quantity || 0) * (value.selling_price || 0)) } }), "amount"),
        //         // ordered_quantity: this.getTotal(value.product, "quantity"),
        //         // etd: moment(value.estimated_delivery).format("MM-YYYY"),
        //         // po_date: moment(value.created_at).format("MM-DD-YYYY"),
        //     }
        // })


        // let forCSV = [

        //     // Totals
        //     {
        //         totalValueOfInquiries: totalValueOfInquiries,
        //         totalInquiriesFromNewCustomers: totalInquiriesFromNewCustomers,
        //         totalInquiriesFromExistingCustomers: totalInquiriesFromExistingCustomers,
        //         totalCountries: totalCountries,
        //         totalInquiryVolume: totalInquiryVolume,
        //         total20FT: total20FT,
        //         total40FT: total40FT,
        //         total40HC: total40HC,
        //         totalOthers: totalOthers
        //     },

        //     // Charts
        //     ...topInquiries, ...totalInquiryPerCountry

        // ]

        // const handleDownload = () => {
        //     const { checkedList } = this.state
        //     checkedList.includes('EXCEL') && this.exportExcel(downloadData);
        //     checkedList.includes('CSV') && this.exportCSV(forCSV);
        //     checkedList.includes('PDF') && this.exportPDF(downloadData);
        // }

        // downloadData.map(data => data.topInquiries.map(top => {
        // }))
        const prodDistCount = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Product / Distribution Inquiry').length
        const offeredSvcsCount = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Offered Services').length
        const webOMSFeedbackCount = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Website OMS Feedback').length
        const suggestionsCount = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Suggestions').length
        const othersCount = filteredInquiryDashboardData.filter(inquiry => inquiry.type === 'Others').length

        const inquiryTypes = overallInquiryChunk.map(inquiry => inquiry.type)

        const customerTypes = newAndExistingChunk.map(inquiry => inquiry.status === 1 ? 'New Customers' : 'Existing Customers')

        const colors = [
            '#35A0FF',
            '#1F3BB3',
            '#78D89F',
            '#59BFB9',
            '#7AC6FF',
            '#0496FF',
            '#9381FF',
            '#4361EE',
        ]

        const inquiryValuePerCountry = {

            labels: inquiryValueBar.map(value => value.country),
            datasets: [
                {
                    label: 'Inquiry Value',
                    backgroundColor: '#7AC6FF',
                    borderColor: '#7AC6FF',
                    borderWidth: 1,
                    hoverBackgroundColor: '#7AC6FF',
                    hoverBorderColor: '#7AC6FF',
                    data: inquiryValueBar.map(value => value.usd)
                }
            ]
        };

        const inquiryValueOptions = {
            position: 'left',
            legend: { display: false },
            tooltips: {
                callbacks: {
                    label: (t, d) => {
                        return `${d.datasets[t.datasetIndex].label}: $${this.numberFormat(Number(t.xLabel))}`
                    }
                }
            },
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    barPercentage: 0.6,
                }],
                xAxes: [{
                    ticks: {
                        // beginAtZero: true,
                        // callback: value => {
                        //     return this.kFormatter(value);
                        // }
                        beginAtZero: true,
                        // min: 0,
                        // max: 5,
                        // stepSize: 1,
                        callback: value => {
                            parseFloat(value).toFixed(2)
                            // return this.kFormatter(value);
                            return (value.toFixed(2) < 1000000) ? value / 1000 + 'K' : value / 1000000 + 'M';
                        },
                        autoSkip: true,
                        maxTicksLimit: 10,
                        stepSize: .2
                    },
                    gridLines: {
                        borderDash: [8, 4],
                    }
                }]
            }
        }

        const inquiryCountPerCountry = {
            labels: inquiryCountBar.map(value => value.country),
            datasets: [
                {
                    label: 'Inquiry Count',
                    backgroundColor: '#78D89F',
                    borderColor: '#78D89F',
                    borderWidth: 1,
                    hoverBackgroundColor: '#78D89F',
                    hoverBorderColor: '#78D89F',
                    data: inquiryCountBar.map(value => value.count)
                }
            ]
        };

        const inquiryCountOptions = {
            position: 'left',
            legend: { display: false },
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    barPercentage: 0.6,
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1
                    },
                    gridLines: {
                        borderDash: [8, 4],
                    }
                }]
            }
        }

        const newAndExistingCustomerOptions = {
            cutoutPercentage: 55,
            legend: { display: false },
        }

        const newAndExistingCustomerData = {
            labels: newAndExistingChunk.map(inquiry => inquiry.status === 1 ? 'New Customers' : 'Existing Customers'),
            datasets: [
                {
                    data: newAndExistingChunk.map(inquiry => inquiry.count),
                    backgroundColor: [
                        '#35A0FF',
                        '#1F3BB3',
                    ],
                    hoverBackgroundColor: [
                        '#35A0FF',
                        '#1F3BB3',
                    ]
                }
            ]
        };

        const inquiryBreakdownOptions = {
            cutoutPercentage: 55,
            legend: { display: false },
        }

        const inquiryBreakdownData = {
            labels: inquiryTypes,
            datasets: [
                {
                    data: overallInquiryChunk.map(inquiry => inquiry.count).sort((a, b) => b - a),
                    backgroundColor: [
                        '#35A0FF',
                        '#1F3BB3',
                        '#78D89F',
                        '#59BFB9',
                        '#7AC6FF'
                    ],
                    hoverBackgroundColor: [
                        '#35A0FF',
                        '#1F3BB3',
                        '#78D89F',
                        '#59BFB9',
                        '#7AC6FF'
                    ]
                }
            ]
        };

        if (myPermissionsLoading) {
            // return (
            //     <Layout>
            //         <Skeleton active />
            //     </Layout>
            // )
            return null
        }

        return myPermissions.some(r => r.name === 'admin.inquiry.view') ? (
            <Layout>
                <Spin spinning={inquiryDashboardLoading}>
                    <Row type="flex" justify="center" className="admin-dashboard-page">
                        <Col span={22}>
                            <Row type="flex" align="middle" className="header">
                                <Col span={24}>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Title level={3}>Inquiry Database</Title>
                                        </Col>
                                        <Col>
                                            <Link to="/contact-us" >
                                                <Button
                                                    className="btn-small btn-primary"
                                                    style={{ width: "100%" }}
                                                    disabled={
                                                        !myPermissions.some((r) => r.name === "admin.inquiry.download")
                                                    }
                                                    onClick={() => this.setState({ showDownloadModal: true })}><img style={{ height: 16, width: 18, marginRight: 8, marginBottom: 2 }} src={downloadIcon} alt="download" /> Extract RAW Data</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={24}>
                                    <Row type="flex" gutter={32} justify="end" align="bottom" className="filters">
                                        <Col xs={24} sm={24} md={8} lg={8} className="date">
                                            <p>DATE AS OF: <span>{moment().format("LL")}</span></p>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>DATE</p>
                                            <Select value={month} onSelect={(value) => this.handleMonth(value)} style={{ width: "100%" }} showSearch>
                                                <Option value="today">Today</Option>
                                                <Option value="this week">This Week</Option>
                                                <Option value="this month">This Month</Option>
                                                <Option value="this year">This Year</Option>
                                                <Option value="custom date">Custom Date</Option>
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <p>Country</p>
                                            <Select mode="multiple" allowClear={true} value={selectedCountries} onChange={this.filterCountries} style={{ width: "100%", textTransform: "capitalize" }} placeholder={"choose a filter"}>
                                                <Option value="all">All</Option>
                                                {countryList.sort((a, b) => a.country.localeCompare(b.country)).map(value => (
                                                    <Option key={value.country} value={value.country}>{value.country}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={4}>
                                            <Button className="btn-small btn-default" onClick={() => this.setState({ month: "This Month", selectedCountries: ['all'], rangePicker: [], startDate: moment().startOf('month').format('MM-DD-YYYY'), endDate: moment().endOf('month').format('MM-DD-YYYY') })} style={{ width: "100%" }}><img style={{ marginBottom: 5, marginRight: 5 }} src={resetIcon} alt="reset" /> Reset Filter</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={22} className="summary-card">
                            <Card>
                                <Row type="flex">
                                    <Col md={6}><p>${this.numberFormat(totalValueOfInquiries)}</p><span>Total Value Of<br />Inquiries</span></Col>
                                    <Col md={6}><p>{inquiryValues.length}</p><span>Total Inquiries<br />Received</span></Col>
                                    {/* <Col md={4}><p>{totalInquiriesFromNewCustomers}</p><span>Total Inquiries<br />Received From New<br />Customers</span></Col> */}
                                    {/* <Col md={4}><p>{totalInquiriesFromExistingCustomers}</p><span>Total Inquiries<br />Received From<br />Existing Customers</span></Col> */}
                                    <Col md={6}><p>{totalCountries}</p><span>Total Number Of<br />Countries</span></Col>
                                    <Col md={6} style={{ border: "none" }}>
                                        <div style={{ display: "flex", flex: 1, justifyContent: "space-evenly", width: "100%", paddingLeft: "10%" }}>
                                            <div>
                                                <p>{totalInquiryVolume}</p>
                                                <span>Total Inquiry<br />Volume</span><br />
                                            </div>

                                            <div style={{ display: "flex", flex: 1, justifyContent: "space-evenly", flexDirection: "column", width: "100%", textAlign: "right", paddingRight: 30 }}>
                                                <span>20FT <strong>{total20FT ? total20FT : '0'}</strong></span><br />
                                                <span>40FT <strong>{total40FT ? total40FT : '0'}</strong></span><br />
                                                <span>40HC <strong>{total40HC ? total40HC : '0'}</strong></span><br />
                                                <span>Others <strong>{totalOthers ? totalOthers : '0'}</strong></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col md={22}>
                            <Row type="flex" gutter={20}>

                                <Col md={12} style={{ margin: "16px 0px" }}>
                                    <Card title="CONTACT US FEEDBACK COUNT BY TYPE" style={{ height: 200 }}>
                                        <div style={{ display: "flex", flex: 1, justifyContent: "space-around" }}>
                                            <div style={{ width: 180 }}>
                                                <Doughnut
                                                    className="inquiry-breakdown"
                                                    responsive={true}
                                                    aspectRatio={1}
                                                    ref="chart"
                                                    borderWidth={10}
                                                    options={inquiryBreakdownOptions}
                                                    data={inquiryBreakdownData}
                                                    style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}
                                                />
                                            </div>

                                            <div style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "column", width: "100%" }}>
                                                {overallInquiryChunk.map((value, index) => (
                                                    <div key={index} style={{ display: "flex", flex: 1, justifyContent: "space-evenly" }}>
                                                        <span style={{ display: "flex", flex: 1, justifyContent: "flex-start", textAlign: "left", whiteSpace: "nowrap", fontWeight: 500 }}>
                                                            <Badge color={colors[index]} text={value.type} />
                                                        </span>
                                                        <span style={{ display: "flex", flex: 1, justifyContent: "flex-end", textAlign: "right", whiteSpace: "nowrap" }}>
                                                            <strong>
                                                                <Col style={{ display: "flex", flex: 1, justifyContent: "space-around" }}>
                                                                    <Col style={{ textAlign: "right" }}>{value.count}</Col>
                                                                    <Col style={{ width: 80, textAlign: "right" }}>(<span style={{ color: colors[index] }}>
                                                                        {parseFloat(((100 * value.count) / value.total).toFixed(2))}%</span>)
                                                            </Col>
                                                                </Col>
                                                            </strong>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col md={12} style={{ margin: "16px 0px" }}>
                                    <Card title="INQUIRY COUNT FROM NEW & EXISTING CUSTOMERS" style={{ height: 200 }}>
                                        <div style={{ display: "flex", flex: 1, justifyContent: "space-evenly" }}>
                                            <div style={{ width: 180 }}>
                                                <Doughnut
                                                    responsive={true}
                                                    aspectRatio={1}
                                                    ref="chart"
                                                    borderWidth={10}
                                                    options={newAndExistingCustomerOptions}
                                                    data={newAndExistingCustomerData}
                                                    style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}
                                                />
                                            </div>

                                            <div style={{ display: "flex", flex: 1, justifyContent: "space-evenly", flexDirection: "column", width: "100%" }}>
                                                {newAndExistingChunk.map((value, index) => (
                                                    <div key={index} style={{ display: "flex", flex: 1, justifyContent: "space-evenly", alignItems: "center" }}>
                                                        <span style={{ display: "flex", flex: 1, justifyContent: "flex-start", textAlign: "left", whiteSpace: "nowrap", fontWeight: 500 }}>
                                                            <Badge color={colors[index]} text={value.status === 1 ? 'New Customers' : 'Existing Customers'} />
                                                        </span>
                                                        <span style={{ display: "flex", flex: 1, justifyContent: "flex-end", textAlign: "right", whiteSpace: "nowrap" }}>
                                                            <strong>{value.count} (<span style={{ color: colors[index] }}>{Math.round(((value.count / value.total) * 100))}%</span>)</strong>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Row type="flex" gutter={20}>
                                <Col md={12} style={{ margin: "16px 0px" }}>
                                    <Card title="Inquiry Value Per Country (Top 8 by Value)" style={{ minHeight: "393px" }}>
                                        <HorizontalBar ref="chart" width={100} height={60} data={inquiryValuePerCountry} options={inquiryValueOptions} />
                                        <p style={{ textAlign: "center" }}>Value USD</p>
                                    </Card>
                                </Col>
                                <Col md={12} style={{ margin: "16px 0px" }}>
                                    <Card title="Inquiry Count Per Country (Top 8 by Value)" style={{ minHeight: "393px" }}>
                                        <HorizontalBar ref="chart" width={100} height={60} data={inquiryCountPerCountry} options={inquiryCountOptions} />
                                        <p style={{ textAlign: "center" }}>Inquiry Count</p>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Modal
                    title="Download Option"
                    visible={showDownloadModal}
                    width={350}
                    onCancel={() => this.setState({ showDownloadModal: false })}
                    footer={null}
                    className="modal"
                >
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        All
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                        options={plainOptions}
                        value={checkedList}
                        onChange={this.onChange}
                    />
                    <Row>
                        <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
                    </Row>

                    <Row type="flex" style={{ margin: "10px 0px" }}>
                        <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
                        <Col span={12}><Button className="btn-small btn-primary" disabled={checkedList.length < 1} onClick={() => this.handleDownload(downloadData)}>Download</Button></Col>
                    </Row>
                </Modal> */}
                    <Modal
                        width={400}
                        visible={showCustomDateModal}
                        onCancel={() => this.setState({ showCustomDateModal: false })}
                        footer={null}
                        className="modal"
                        closable={false}
                    >
                        <Row>
                            <Col span={24}>
                                <p>Period Covered:</p>
                                <RangePicker
                                    size="large" s
                                    value={rangePicker}
                                    onCalendarChange={value => this.setState({ rangePicker: value })}
                                />
                            </Col>
                            <Col span={24}>
                                <Row type="flex" justify="end" style={{ marginTop: "20px" }}>
                                    <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showCustomDateModal: false })}>Cancel</Button></Col>
                                    <Col span={12}><Button className="btn-small btn-primary" disabled={rangePicker.length < 1} onClick={() => this.setState({ showCustomDateModal: false, startDate: moment(rangePicker[0]).format("MM-DD-YYYY"), endDate: moment(rangePicker[1]).format("MM-DD-YYYY") })}>Apply</Button></Col>
                                </Row>
                            </Col>

                        </Row>


                    </Modal>
                </Spin>
            </Layout>
        ) : (
                <Result
                    status="warning"
                    title="You have no permission to view this page."
                />
            );
    }
}
