import React, { Component } from 'react';
import { Form, Icon, Button, Layout, Row, Col, Table, Dropdown, Menu, Input, Modal, Skeleton, Result, Switch, Popover, Select, DatePicker } from 'antd';
import moment from 'moment';
import ModalForm from './components/ModalForm';
import ModalView from './components/ModalView';

import './Brands.scss'

const { Content } = Layout;
const { Search } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;

class Brand extends Component {
  initialState = {
    showFormModal: false,
    showViewModal: false,
    modalLoading: false,
    selectedRow: null,
    filterBy: [
      'name'
    ]
  }
filter = '';
  // Inital State for the sorting
  sortState = {
    sortBy: 'created_at',
    orderBy: 'asc',
    filters: JSON.stringify({ status: [1] })
  };

  // Initial state for searching
  searchState = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.imageUploader = React.createRef();
  }

  handleSubmit = (e) => {
    this.setState({ modalLoading: true })
    e.preventDefault();
    const { onSubmitAddBrand, onSubmitUpdateBrand } = this.props
    const { selectedRow, tableData } = this.state;
    const data = {
      page: tableData.current_page
    }

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values
        }

        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        params.image = media ? media.url : imageUrl;
        params.status = params.status === 'Active' ? 1 : 0

        if (selectedRow) {
          params.id = selectedRow.id;
          await onSubmitUpdateBrand(params, {...this.sortState, ...data, ...this.searchState}).then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to update Brands.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Brands has been updated successfully',
                content: ''
              })
            }
            this.setState({ modalLoading: false })
          })
        } else {
          await onSubmitAddBrand(params, {...this.sortState, ...data, ...this.searchState}).then((response) => {
            if (response.error) {
              Modal.warning({
                title: 'Unable to Add Brands.',
                content: ''
              })
            } else {
              Modal.success({
                title: 'Brands has been added successfully',
                content: ''
              })
            }
            this.setState({ modalLoading: false })
          })
        }

        this.props.form.resetFields();
        this.handleCloseModal()
      }
    });
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = row => {
    this.setState({
      showFormModal: true,
      selectedRow: row
    });
  };

  handleShowViewModal = row => {
    this.setState({
      showViewModal: true,
      selectedRow: row
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      showViewModal: false,
      selectedRow: null
    });
  };
  handleFilterBy = (value) => {
    let category = ''
    // value === 'all'
    //   ? category = [
    //     'name',
    //     'created_at',
    //     'created_at_whole',
    //     'material_code',
    //     'status',
    //     'is_disabled',
    //     'created_at',
    //     'description'
    //   ]
    //     : 
    value === 'name'
          ? category = ['name']
            : value === 'created_at'
              ? category = ['created_at']
                : category = [
                  'name',
                  'created_at',
                  'created_at_whole',
                  'material_code',
                  'status',
                  'is_disabled',
                  'created_at',
                  'description'
                ]
                this.filter = value;
    this.setState({ filterBy: category, keyword: "" });
    // console.log("CATEGORY ===========", category)
    // const { orderStatus } = this.props;
    // const newArrayObj = orderStatus.data;

    // // if data not yet loaded condition to avoid error
    // if (!newArrayObj) {
    //   return;
    // } else {
    //   // return original data
    //   console.log(newArrayObj);
    //   return this.setState({ tableData: newArrayObj });
    // }
  };
  changeStatus = (row, status) => {
    const { tableData } = this.state;
    const data = {
      page: tableData.current_page
    };

    const params = {
      id: row.id,
      status: status
    };
    this.props.onSubmitUpdateBrand(params, {...this.sortState, ...data, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update status of brand.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Brand status has been updated successfully',
            content: ''
          })
        }
      })
  }


  disableBrand = (row, is_disabled) => {
    const { tableData } = this.state;
    const data = {
      page: tableData.current_page
    };

    const params = {
      id: row.id,
      is_disabled: is_disabled
    };

    this.props.onSubmitDisableBrand(params, {...this.sortState, ...data, ...this.searchState})
      .then((response) => {
        if (response.error) {
          Modal.warning({
            title: 'Unable to update status of brand.',
            content: ''
          })
        } else {
          Modal.success({
            title: 'Brand status has been updated successfully',
            content: ''
          })
        }
      })
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadBrandsData( { filters: JSON.stringify({ status: [1] }) } )
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    // const convertStringDotNotation = (desc, obj) => {
    //   var arr = desc.split(".");
    //   while (arr.length && (obj = obj[arr.shift()]));
    //   return obj;
    // }
    // const { brandsData } = this.props
    // const newArrayObj = brandsData
    // //if SearchTerm is Empty return original data.
    // if (!searchTerm) {
    //   return this.setState({ tableData: newArrayObj })
    // }
    // let distributorArray = []
    // newArrayObj.forEach((rowItem, i) => {
    //   searchKeys.forEach(keys => {
    //     let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
    //     let isFound = (rowItemKeys) && rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase())
    //     if (isFound) distributorArray.push(rowItem)
    //   })
    // })
    // //Removed Duplicate Entry in Array.
    // const newArrayElement = distributorArray.filter(function (elem, index, self) {
    //   return index === self.indexOf(elem);
    // })
    // this.setState({ tableData: newArrayElement })

    this.searchState = searchTerm.trim() === '' ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.retrieveBrands({
      page: 1, // reset pagination to 1
    });

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.brandsData !== nextProps.brandsData) {
      this.setState({ tableData: nextProps.brandsData })
    }
  }

  handleOnChangeTableListener = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.sortState = {
      sortBy: sorter.field || this.sortState.sortBy,
      orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
      filters: JSON.stringify(filters)
    }

    if (!sorter.order) {
      ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
    }

    this.retrieveBrands({
      page: pagination.current
    });
  }

  retrieveBrands(data) {
    this.props.onLoadBrandsData({...this.sortState, ...data, ...this.searchState});
  }

  render() {
    const { showFormModal, showViewModal, selectedRow, tableData, modalLoading, filterBy } = this.state
    const {
      myPermissionsLoading, myPermissions,
      brandLoading, disabledBrandLoading, updateBrandLoading, brandsData,
      form: { getFieldDecorator }
    } = this.props
    if (tableData === undefined) return false;
    let dataSource = tableData.data;

    if (dataSource === undefined) return false;
    
    const columns = [
      {
        title: 'SHOW/HIDE',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 150,
        // sorter: (a, b) => {
        //   const x = a.is_disabled;
        //   const y = b.is_disabled;
        //   if (x > y) { return -1; }
        //   if (x < y) { return 1; }
        //   return 0;
        // },
        render: (id, row) => {
          return (
            <Switch key={row.id} 
            disabled={row.status === 'Inactive' ? true : false} //commented this out for now, enhancement for disabling toggle button when brand is inactive
            checked={row.is_disabled === 'Enabled' ? true : false} onChange={(checked) => {
              checked
                ? this.disableBrand(row, 0)
                : this.disableBrand(row, 1)
            }} />
          )
        }
      },
      {
        title: '',
        dataIndex: 'image',
        key: 'image',
        width: 200,
        render: (image, row) => {
          if (!image) {
            return <div style={{ height: 90 }}></div>;
          }
          return <div style={{ height: 90, margin: 0, padding: 0 }}><img src={process.env.REACT_APP_FILES_URL + image} alt={row.name} width={80} style={{ height: 80, objectFit: 'contain' }} /></div>
        }
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        sorter: (a, b) => {
          const x = a.name.toLowerCase();
          const y = b.name.toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
      },
      {
        title: 'CREATED DATE',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        sorter: (a, b) => {
          const x = ((a.created_at) ? a.created_at : 'N/A').toLowerCase();
          const y = ((b.created_at) ? b.created_at : 'N/A').toLowerCase();
          if (x < y) { return -1; }
          if (x > y) { return 1; }
          return 0;
        },
        render: created_at => {
          return <span>{created_at}</span>
        }
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 200,
        filters: [{ text: "Active", value: 1 }, { text: "Inactive", value: 0 }],
        defaultFilteredValue: [1],
        // onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status
          // return status === 'Active' ? <span className="active">{status}</span> : <span className="inactive">{status}</span>

          let htmlElement
          if (status === 'Active') htmlElement = <Button className='active'>ACTIVE</Button>
          else if (status === 'Inactive') htmlElement = <Button className='inactive'>INACTIVE</Button>
          return (
            <div>
              <Dropdown
                key={row.id}
                className='status-dropdown'
                overlay={
                  <Menu key={row.id} style={{ top: -40, left: -10 }}>
                    <Menu.Item>
                      <Button
                        className='active'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.changeStatus(row, 1)
                        }}>ACTIVE</Button>
                    </Menu.Item>

                    <Menu.Item>
                      <Button
                        className='inactive'
                        onClick={(e) => {
                          e.stopPropagation()
                          this.changeStatus(row, 0)
                        }}>INACTIVE</Button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                // visible={this.state.visible}
                onClick={(e) => {
                  this.setState({ visible: true })
                  e.stopPropagation()
                }} >
                {htmlElement}
              </Dropdown>
            </div>

          )
        }
      },
      // {
      //   title: 'Is Disabled',
      //   dataIndex: 'is_disabled',
      //   key: 'is_disabled',
      //   align: 'center',
      //   width: 200,
      //   sorter: (a, b) => {
      //     const x = a.is_disabled;
      //     const y = b.is_disabled;
      //     if (x < y) { return -1; }
      //     if (x > y) { return 1; }
      //     return 0;
      //   },
      //   render: (tags, row) => {
      //     const is_disabled = row.is_disabled
      //     return (
      //       <div>
      //         <span>{is_disabled}</span>
      //       </div>
      //     )
      //   }
      // },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 200,
        render: (id, row) => {
          return (
            <div>
              <Popover content={(<p>View</p>)}>
                <span onClick={() => this.handleShowViewModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="eye" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>
              <Popover content={(<p>Edit</p>)}>
                <span onClick={() => this.handleShowEditFormModal(row)} style={{ cursor: 'pointer', margin: 5 }}>
                  <Icon type="edit" style={{ fontSize: '1.5em' }} />
                </span>
              </Popover>



              {/* <Dropdown overlay={(
              <Menu>
                {(row.status === 'Active')
                  ?
                  <Menu.Item
                    disabled={!myPermissions.some(r => r.name === 'admin.brands.edit')}
                    onClick={() => this.changeStatus(row, 0)}>Deactivate</Menu.Item>
                  :
                  <Menu.Item
                    disabled={!myPermissions.some(r => r.name === 'admin.brands.edit')}
                    onClick={() => this.changeStatus(row, 1)}>Activate</Menu.Item>
                }
                {(row.is_disabled === 'Enabled')
                  ?
                  <Menu.Item
                    disabled={!myPermissions.some(r => r.name === 'admin.brands.edit')}
                    onClick={() => this.disableBrand(row, 1)}>Disable</Menu.Item>
                  :
                  <Menu.Item
                    disabled={!myPermissions.some(r => r.name === 'admin.brands.edit')}
                    onClick={() => this.disableBrand(row, 0)}>Enable</Menu.Item>
                }
                <Menu.Item
                  disabled={!myPermissions.some(r => r.name === 'admin.brands.edit')}
                  onClick={() => this.handleShowEditFormModal(row)}>Edit Details</Menu.Item>
                <Menu.Item onClick={() => this.handleShowViewModal(row)}>View Details</Menu.Item>
              </Menu>
            )}>
              <Button>
                Actions <Icon type="down" />
              </Button>
            </Dropdown> */}
            </div>
          )
        }
      }
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.brands.view') ? (
      <div>
        <Layout>
          <Content style={{ padding: 25 }}>
            <Row>
              <Col span={12} className="page-title">
                Brands
              </Col>
              <Col span={12} align="right" className='button-group'>
                <ButtonGroup style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  <Button
                    className='btn-small btn-primary'
                    disabled={!myPermissions.some(r => r.name === 'admin.brands.create')}
                    type="primary"
                    onClick={this.handleShowFormModal}
                  ><Icon type="plus" /> Add Brand</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="brands-container">
                  {this.filter === 'created_at' ? 
                  <DatePicker className='search-container' 
                              size="large" 
                              onChange={(date, dateString) => this.handleSearch(dateString, filterBy)}/>
                  
                  :
                    <Search className='search-container' size='large' placeholder="Search" onSearch={(value) => this.handleSearch(value, filterBy
                    // [
                    //   'name',
                    //   'created_at',
                    //   'created_at_whole',
                    //   'material_code',
                    //   'status',
                    //   'is_disabled',
                    //   'created_at',
                    //   'description'
                    // ]
                    )} /> 
                    }
                  <Select style={{width:160}}
                          // suffixIcon={<img src={FilterIcon} />}
                          size="large"
                          placeholder="Filter"
                          // defaultValue={[filterBy]}
                          defaultValue="name"
                          onChange={(value) => this.handleFilterBy(value)}
                          dropdownClassName="--select"
                          autoFocus={true}
                          showSearch
                        >
                          {/* <Option value="all">All</Option> */}
                          <Option value="name">Brand Name</Option>
                          <Option value="created_at">Created Date</Option>
                          {/* {columns.map((value) => (
                            <Option
                              key={value.key}
                              value={value.key}
                            >
                              {value.key.toLowerCase()}
                            </Option>
                          )).filter(value => value.key !== 'poCreatedAtWhole')} */}
                        </Select>
                  <Table
                    rowKey="id"
                    dataSource={dataSource}
                    columns={columns}
                    loading={brandLoading || disabledBrandLoading || updateBrandLoading}
                    onChange={this.handleOnChangeTableListener} 
                    pagination={{ total: tableData.total, current: tableData.current_page, pageSize: tableData.per_page || 10 }}
                  />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        {showFormModal &&
          <ModalForm
            showFormModal={showFormModal}
            handleCloseModal={this.handleCloseModal}
            handleSubmit={this.handleSubmit}
            imageUploader={this.imageUploader}
            selectedRow={selectedRow}
            getFieldDecorator={getFieldDecorator}
            isLoading={brandLoading || disabledBrandLoading || updateBrandLoading || modalLoading}
          />
        }
        <ModalView
          showFormModal={showViewModal}
          handleCloseModal={this.handleCloseModal}
          selectedRow={selectedRow}
        />
      </div>
    ) : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const BrandForm = Form.create({ name: 'brand_form' })(Brand);

export default BrandForm;
