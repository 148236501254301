import React from 'react'
import { Row, Col, Button, Form, Modal, Layout, Input } from 'antd';

const { Content } = Layout;

export default function ModalForm(props) {
  const { selectedRow } = props;

  if (selectedRow === null) {
    return "";
  }

  return (
    <Modal
      title="View Details"
      visible={props.showFormModal}
      onCancel={props.handleCloseModal}
      centered={true}
      footer={null}
      width={800}
    >
      <Layout>
        <Content className='contact-form-container'>


          <Row type="flex" justify="center" gutter={30}>
            <Form onSubmit={props.handleSubmit} className="login-form">

              <Col span={24}>
                <Form.Item label="Distributor Name">
                  <Input value={selectedRow.name} readOnly={true} />
                </Form.Item>
              </Col>
              <Col>
                <h3>Distributor Address</h3>
              </Col>
              {
                selectedRow.address.map((data, i) => {
                  return (
                    <Col span={24} key={i}>
                      <Form.Item label={`Address ${i + 1}`}>
                        <Input value={`${data.name || ""} ${data.unit || ""} ${data.street || ""} ${data.region || ""} ${data.country || ""}`} readOnly />
                      </Form.Item>
                    </Col>
                  )
                })
              }
              <Col>
                <h3>Distributor Contact</h3>
              </Col>
              {
                selectedRow.contact.map((data, i) => {
                  return (
                    <Col span={8} key={i}>
                      <Form.Item label={`Contact ${i + 1}`}>
                        <Input value={`${data.contact}`} readOnly />
                      </Form.Item>
                    </Col>
                  )
                })
              }

              <Col span={8}>
                <Form.Item label="Status">
                  <Input value={selectedRow.status ? "Active" : "Inactive"} readOnly={true} />
                </Form.Item>
              </Col>

              <Col span={24} align="right">
                <Form.Item>
                  <Button key="back" onClick={props.handleCloseModal}>
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Modal>
  )
}
