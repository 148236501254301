import React, { Component } from 'react'
import { Row, Col, Button, Icon, Layout, Select, Table, Card, Divider, Progress, Input, Radio, DatePicker, Typography, Badge, Skeleton, Spin, Result, Modal, Tag } from 'antd'
import { connect } from 'react-redux';
import {
    loadFeedbackData,
    ignoreComment,
} from '../actions';
import {
    fetchPermissions
} from '../../../../actions'
import './index.scss';
import { Line, Pie } from 'react-chartjs-2';
import moment from "moment"
import Workbook from 'react-excel-workbook'
import _ from 'lodash'

import rate5 from '../../../../assets/svg/rate-5.svg'
import rate4 from '../../../../assets/svg/rate-4.svg'
import rate3 from '../../../../assets/svg/rate-3.svg'
import rate2 from '../../../../assets/svg/rate-2.svg'
import rate1 from '../../../../assets/svg/rate-1.svg'
import randomColor from 'randomcolor'
const { Search } = Input;
const ButtonGroup = Button.Group;

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography
    ;


class Feedback extends Component {
    constructor() {
        super()
        this.state = {
            tableData: [],
            startDate: null,
            endDate: null,
            feedbackType: "all",
            frequency: "daily"
        }
    }
    componentDidMount() {
        this.props.fetchPermissions()
        this.props.onLoadFeedbackData()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.feedbackData !== nextProps.feedbackData) {
            this.setState({ tableData: nextProps.feedbackData })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableData !== this.state.tableData) {
            const oldest = new Date(Math.max.apply(null, this.state.tableData.map(function (e) {
                return new Date(e.po_date);
            })));
            const newest = new Date(Math.min.apply(null, this.state.tableData.map(function (e) {
                return new Date(e.po_date);
            })));

            this.setState({ startDate: moment(newest).format('MM/DD/YYYY'), endDate: moment(oldest).format('MM/DD/YYYY') })
        }
    }

    getEmoji = (score) => {
        const img = (src, score) => <img src={src} style={{ height: "40px", width: "40px" }} alt={score} />
        switch (score) {
            case 5:
                return img(rate5, 5)
            case 4:
                return img(rate4, 4)
            case 3:
                return img(rate3, 3)
            case 2:
                return img(rate2, 2)
            default:
                return img(rate1, 1)
        }
    }


    disabledDate = (current) => {
        const { tableData } = this.state
        if (!tableData) {
            return
        }

        const oldest = new Date(Math.max.apply(null, tableData.map(function (e) {
            return new Date(e.po_date);
        })));
        const newest = new Date(Math.min.apply(null, tableData.map(function (e) {
            return new Date(e.po_date);
        })));

        return !moment(current.format('MM/DD/YYYY')).isBetween(moment(newest).format('MM/DD/YYYY'), moment(oldest).format('MM/DD/YYYY'), null, '[]');
    }

    groupBy = (data, key) => {
        return data.reduce(function (storage, item) {
            var group = item[key];
            storage[group] = storage[group] || [];
            storage[group].push(item);
            return storage;
        }, {});
    };

    handleGenerateExcel = (data) => {
        // 
    }

    handleIgnoreComment = (e) => {
        Modal.confirm({
            title: "Ignore Comment",
            content: `Are you sure, you want to ignore this ${e.feedback_type}.`,
            okText: ' Okay',
            onOk: () => this.props.onIgnoreComment(e.id).then((res) => {
                if (!res.error) {
                    Modal.success({
                        title: "Success",
                        content: `The selected comment has been ignored.`,
                        okText: ' Okay',
                    })
                }
            })
        })
    }

    // The first params is the search value, second param are the search keys in table
    handleSearch = (searchTerm, searchKeys) => {
        //function inside function, this will convert the dot string into actual object
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split(".");
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }
        const { feedbackData } = this.props
        const newArrayObj = feedbackData
        //if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return this.setState({ tableData: newArrayObj })
        }
        let arrayData = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase())
                if (isFound) arrayData.push(rowItem)
            })
        })
        //Removed Duplicate Entry in Array.
        const newArrayElement = arrayData.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        this.setState({ tableData: newArrayElement })
    }

    processString = (wordGroupings, testString) => {
        var regexes = wordGroupings.map((words, i) => {
            // Sept 12, 2020 fixed for prod deployment (removed all special characters)
            words = words.replace(/[^a-zA-Z0-9 ]/g, "")
            return ({
                origString: words,
                regex: new RegExp(words.replace(`${/\s+/g}`, `|`), 'g'),
                expCount: words.split(/\s+/g).length
            })
        }
        )

        var filtered = regexes.filter(({ regex, expCount }) =>
            (testString.match(regex) || []).length === expCount
        );

        return filtered.map(dataObj => dataObj.origString);
    }


    render() {
        const { feedbackType, frequency, startDate, endDate, tableData } = this.state
        const {
            myPermissions, myPermissionsLoading
        } = this.props




        const columns = [
            {
                title: 'Date',
                dataIndex: 'po_date',
                render: date => moment(date).format("MM/DD/YYYY"),
                width: 120,
                sorter: (a, b) =>
                    moment(a.po_date).unix() - moment(b.po_date).unix(),
            },
            {
                title: 'Feedback',
                dataIndex: 'feedback_score',
                render: score => this.getEmoji(score),
                width: 120,
                sorter: (a, b) => a.feedback_score - b.feedback_score,
            },
            {
                title: 'Comments',
                dataIndex: 'comments',
            },
            {
                title: 'Customer Name',
                dataIndex: 'distributor',
                width: 200,
                render: (data) => data !== null ? data.name : 'N/A'
            },
            // {
            //     title: 'Action',
            //     dataIndex: '',
            //     width: 50,
            //     render: (data) => <Button className='ignore-button' onClick={() => this.handleIgnoreComment(data)}>Ignore</Button>
            // },
        ];


        let filteredDataTable = []


        if (tableData) {
            filteredDataTable = feedbackType === "all" ? tableData : tableData.filter(value => value.feedback_type === feedbackType)
            if (startDate && endDate) {
                filteredDataTable = filteredDataTable.filter(value =>
                    moment(value.po_date).isBetween(startDate, endDate, null, '[]')
                )
            }
        }

        const getPercentage = (score) => {
            let count = filteredDataTable.reduce(function (n, val) {
                return n + (val.feedback_score === score);
            }, 0);
            return filteredDataTable.length === 0 ? 0 : Math.round((count * 100) / filteredDataTable.length)
        }

        let excellentPercent = getPercentage(5)
        let goodPercent = getPercentage(4)
        let okayPercent = getPercentage(3)
        let poorPercent = getPercentage(2)
        let veryPoorPercent = getPercentage(1)

        const getMonths = (start, end, data) => {
            let dateArray = [];
            let newArray = [];
            let endDate = moment(end)
            let currentDate = moment(start);
            while (currentDate <= endDate) {
                let count = 0;
                for (let i = 0; i < data.length; ++i) {
                    if (moment(data[i].po_date).format("MM/DD/YYYY") === moment(currentDate).format("MM/DD/YYYY")) {
                        count++;
                    }
                }


                dateArray.push({ date: currentDate.format("MM/DD/YYYY"), response: count });
                currentDate = currentDate.add(1, 'days');
            }
            let monthArray = [...new Set(dateArray.map(value => moment(value.date).format("MMMM")))]

            monthArray.map(value => {
                let res = dateArray.reduce((a, c) => {
                    if (value === moment(c.date).format("MMMM")) {
                        return (a + (parseFloat(c.response)))
                    }
                    return a
                }, 0)
                let date = dateArray.find(data => {
                    return value === moment(data.date).format("MMMM")
                })

                return newArray.push({ date: date.date, response: res });
            })

            return newArray;
        }

        const getWeeks = (start, end, data) => {
            let dateArray = [];
            let endDate = moment(end)
            let currentDate = moment(start);

            while (currentDate <= endDate) {
                let count = 0;
                for (let i = 0; i < data.length; ++i) {
                    if (moment(data[i].po_date).format("MM/DD/YYYY") === moment(currentDate).format("MM/DD/YYYY")) {
                        count++;
                    }
                }

                dateArray.push({ date: currentDate.format("MM/DD/YYYY"), response: count });
                currentDate = currentDate.add(1, 'days');
            }
            return dateArray;
        }

        let frequencyData = frequency === "daily" ? getWeeks(startDate, endDate, filteredDataTable) : getMonths(startDate, endDate, filteredDataTable)

        const data = {
            labels: frequency === "daily" ? frequencyData.map((value) => moment(value.date).format("DD")) : frequencyData.map((value) => moment(value.date).format("MMMM")),
            datasets: [
                {
                    label: 'Response',
                    fill: true,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    data: frequencyData.map((value) => value.response)
                }
            ]
        };

        const pieData = {
            labels: ['Order Placement', 'Order Fulfillment'],
            datasets: [
                {
                    data: [filteredDataTable.filter(value => value.feedback_type === 'order fulfillment').length, filteredDataTable.filter(value => value.feedback_type === 'order placement').length],
                    backgroundColor: ['#E67373', '#73E6CD'],
                    hoverBackgroundColor: ['#E67373', '#73E6CD'],

                }
            ]
        };

        const options = {
            title: {
                display: true,
                position: 'bottom',
                text: `${filteredDataTable.length} Responses Collected`,
                fontSize: 16,
                fontColor: '#595959',
                fontStyle: "normal"
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                        var total = meta.total;
                        var currentValue = dataset.data[tooltipItem.index];
                        var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                        return currentValue + ' (' + percentage + '%)';
                    },
                    title: function (tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    }
                }
            }

        }

        const legendOpts = {
            display: false,
        };

        const groupByData = this.groupBy(filteredDataTable, 'po_date')

        const responseTrend = Object.keys(groupByData).map((key) => {
            return { date: key, number_of_response: groupByData[key].length }
        });

        const responseCollectedData = Object.keys(groupByData).map((key, index) => {
            return this.groupBy(groupByData[key], 'feedback_type')
        });
        let responseCollected = [];
        responseCollectedData.map(value => {
            if (Object.entries(value).length > 1) {
                Object.entries(value).map((value) => {
                    return responseCollected.push({ date: value[1][0].po_date, feedback_type: value[0], number_of_response: value[1].length })
                })
            } else {
                responseCollected.push({ date: Object.values(value)[0][0].po_date, feedback_type: Object.keys(value)[0], number_of_response: Object.values(value)[0].length })
            }
            return responseCollected
        })

        const ratingOrderExperienceData = Object.keys(groupByData).map((key, index) => {
            return this.groupBy(groupByData[key], 'feedback_score')
        });
        let ratingOrderExperience = [];
        ratingOrderExperienceData.map(value => {
            if (Object.entries(value).length > 1) {
                Object.entries(value).map((value) => {
                    return ratingOrderExperience.push({ date: value[1][0].po_date, feedback_type: value[1][0].feedback_type, feedback: value[0], number_of_response: value[1].length })
                })
            } else {
                ratingOrderExperience.push({ date: Object.values(value)[0][0].po_date, feedback_type: Object.values(value)[0][0].feedback_type, feedback: Object.keys(value)[0], number_of_response: Object.values(value)[0].length })
            }
            return ratingOrderExperience
        })

        const feedbackVerbatims = filteredDataTable && filteredDataTable.map((value) => {
            return {
                date: value.po_date,
                feedback_type: value.feedback_type,
                feedback: value.feedback_score,
                comments: value.comments,
                name: value && value.distributor && value.distributor.name || ''
            }
        });

        if (myPermissionsLoading) {
            return (
                <Layout>
                    <Skeleton active />
                </Layout>
            )
        }

        const overallCsat = filteredDataTable
        const filterByOrderPlacement = overallCsat.filter((item) => item.feedback_type === 'order placement')
        const filterByOrderFulfillment = overallCsat.filter((item) => item.feedback_type === 'order fulfillment')

        // overall getting of average
        const overallCsatScore = (overallCsat.reduce((a, b) => +a + +b.feedback_score, 0) / overallCsat.length) || 0
        // 5(Score) * 100(Percentage) / 5(Max Total Score)
        const overallCsatAverage = (overallCsatScore * 100 / 5) || 0

        // order placement getting of average
        const orderPlacementScore = (filterByOrderPlacement.reduce((a, b) => +a + +b.feedback_score, 0) / filterByOrderPlacement.length) || 0
        // 5(Score) * 100(Percentage) / 5(Max Total Score)
        const orderPlacementAverage = (orderPlacementScore * 100 / 5) || 0

        // order fulfillment getting of average
        const orderFulfillmentScore = (filterByOrderFulfillment.reduce((a, b) => +a + +b.feedback_score, 0) / filterByOrderFulfillment.length) || 0
        // 5(Score) * 100(Percentage) / 5(Max Total Score)
        const orderFulfillmentAverage = (orderFulfillmentScore * 100 / 5) || 0

        const comments = _.map(filteredDataTable, 'comments')

        const getMostCommonWords = (data) => {
            // to count duplicate value in array
            const count = names =>
                names.reduce((a, b) => ({
                    ...a,
                    [b]: (a[b] || 0) + 1
                }), {})

            const newFilteredArray = []
            data.map((x, y) => {
                this.processString(data, x).map(z => {
                    // push only if more than 3 characters,
                    if (z.length > 2) newFilteredArray.push({ _comments: z.toLowerCase() })
                })
            })

            const result = [...newFilteredArray.reduce((mp, o) => {
                if (!mp.has(o._comments)) mp.set(o._comments, { ...o, _count: 0 });
                mp.get(o._comments)._count++;
                return mp;
            }, new Map).values()].filter(x => x._count !== 1).sort((a, b) => (b._count) - (a._count));

            return result
        }

        return myPermissions.some(r => r.name === 'admin.reports.view') ? (
            <Layout>
                <Spin spinning={this.props.feedbackLoading}>

                    <Content className="feedback-page">
                        <Row type="flex" align="middle" justify="space-between" className="parent-header" gutter={16} style={{ margin: "24px" }} >
                            <Col>
                                <Title level={3}>OMS Experience Feedback Dashboard</Title>
                            </Col>
                            <Col>
                                <Workbook filename="feedback-report.xlsx" element={<Button size="large" disabled={this.props.feedbackLoading}> <Icon type="form" />Generate Excel</Button>}>
                                    <Workbook.Sheet data={responseTrend} name="Response Trend">
                                        <Workbook.Column label="Date" value={row => moment(row.date).format("MM/DD/YYYY")} />
                                        <Workbook.Column label="Number Of Response" value={row => row.number_of_response} />
                                    </Workbook.Sheet>
                                    <Workbook.Sheet data={feedbackVerbatims} name="Feedback Verbatims">
                                        <Workbook.Column label="Date" value={row => moment(row.date).format("MM/DD/YYYY")} />
                                        <Workbook.Column label="Feedback Type" value={row => row.feedback_type} />
                                        <Workbook.Column label="Feedback" value={row => row.feedback} />
                                        <Workbook.Column label="Comments" value={row => row.comments} />
                                        <Workbook.Column label="Customer Name" value={row => row.name} />
                                    </Workbook.Sheet>
                                    <Workbook.Sheet data={responseCollected} name="Response Collected">
                                        <Workbook.Column label="Date" value={row => moment(row.date).format("MM/DD/YYYY")} />
                                        <Workbook.Column label="Feedback Type" value={row => row.feedback_type} />
                                        <Workbook.Column label="Number Of Response" value={row => row.number_of_response} />
                                    </Workbook.Sheet>
                                    <Workbook.Sheet data={ratingOrderExperience} name="Rating Order Experience">
                                        <Workbook.Column label="Date" value={row => moment(row.date).format("MM/DD/YYYY")} />
                                        <Workbook.Column label="Feedback Type" value={row => row.feedback_type} />
                                        <Workbook.Column label="Feedback" value={row => row.feedback} />
                                        <Workbook.Column label="Number Of Response" value={row => row.number_of_response} />
                                    </Workbook.Sheet>
                                </Workbook>

                            </Col>
                        </Row>
                        <Divider />
                        <Row type="flex" gutter={16} align="middle" className="feedback-filters" style={{ margin: "24px" }}>
                            <Col span={24}>
                                <Row type="flex" justify="space-between" align="middle" gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={6} style={{ marginBottom: "10px" }}>
                                        <Row type="flex" align="middle" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={12} style={{ marginBottom: "6px" }}>
                                                <span className="label">Feedback:</span>
                                            </Col>
                                        </Row>
                                        <Row type="flex" align="middle" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={11} style={{ marginBottom: "6px" }}>
                                                <Select defaultValue="all" size="large" onChange={(value) => this.setState({ feedbackType: value })} style={{ width: "100%" }} showSearch>
                                                    <Option value="all">All</Option>
                                                    <Option value="order placement">Order Placement</Option>
                                                    <Option value="order fulfillment">Order Fulfillment</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={7} style={{ marginBottom: "10px" }}>
                                        <Row type="flex" align="middle" justify="left" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={8} style={{ marginBottom: "6px" }}>
                                                <span className="label">Frequency:</span>
                                            </Col>
                                        </Row>
                                        <Row type="flex" align="middle" justify="left" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={16} style={{ marginBottom: "6px" }}>
                                                {/* <Radio.Group onChange={e => this.setState({ frequency: e.target.value })} value={frequency}>
                                                <Radio value="daily">Daily</Radio>
                                                <Radio value="monthly">Monthly</Radio>
                                            </Radio.Group> */}

                                                <label className="radio">
                                                    DAILY
                                            <input
                                                        type="radio"
                                                        name="status"
                                                        checked={frequency === "daily"}
                                                        value="daily"
                                                        onChange={(e) => this.setState({ frequency: e.target.value })}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="radio">
                                                    MONTHLY
                                            <input
                                                        type="radio"
                                                        name="status"
                                                        checked={frequency === "monthly"}
                                                        value="monthly"
                                                        onChange={(e) => this.setState({ frequency: e.target.value })}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={11} style={{ marginBottom: "10px" }}>
                                        <Row type="flex" align="left" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={6} style={{ marginBottom: "6px" }}>
                                                <span className="label">Period Covered:</span>
                                            </Col>
                                        </Row>
                                        <Row type="flex" align="left" gutter={10}>
                                            <Col xs={24} sm={24} md={24} lg={9} style={{ marginBottom: "6px" }}>
                                                <DatePicker placeholder="Start" disabledDate={this.disabledDate} value={startDate ? moment(startDate, "MM/DD/YYYY") : null} size="large" format="MM/DD/YYYY" onChange={(date) => this.setState({ startDate: date.format("MM/DD/YYYY") })} style={{ width: "100%" }} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={9} style={{ marginBottom: "6px" }}>
                                                <DatePicker placeholder="End" disabledDate={this.disabledDate} value={startDate ? moment(endDate, "MM/DD/YYYY") : null} size="large" format="MM/DD/YYYY" onChange={(date) => this.setState({ endDate: date.format("MM/DD/YYYY") })} style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ margin: "0px 24px 0px 24px" }}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Card className='container' style={{ marginBottom: "24px" }}>
                                    <Row type="flex" align="middle">
                                        <Col xs={24} sm={24} md={8}>
                                            {/* Math floor conveter: example 1.123456789 => 1.12 */}
                                            <Progress type="circle" percent={overallCsatAverage} width={80} strokeColor="#59BFB9" format={percent => `${Math.floor(overallCsatScore * 100) / 100}`} />
                                        </Col>
                                        <Col xs={24} sm={24} md={16}>
                                            <h3>Overall CSAT Score</h3>
                                            <Text type="secondary" strong>{overallCsat.length} Overall Recorded Responses</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{ marginBottom: "24px" }}>
                                <Card className='container'>
                                    <Row type="flex" align="middle">
                                        <Col xs={24} sm={24} md={8}>
                                            {/* Math floor conveter: example 1.123456789 => 1.12 */}
                                            <Progress type="circle" percent={orderPlacementAverage} width={80} strokeColor="#7AC6FF" format={percent => `${Math.floor(orderPlacementScore * 100) / 100}`} />
                                        </Col>
                                        <Col xs={24} sm={24} md={16}>
                                            <h3>Order Placement CSAT Score</h3>
                                            <Text type="secondary" strong>{filterByOrderPlacement.length} Recorded Responses</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{ marginBottom: "24px" }}>
                                <Card className='container' >
                                    <Row type="flex" align="middle">
                                        <Col xs={24} sm={24} md={8}>
                                            {/* Math floor conveter: example 1.123456789 => 1.12 */}
                                            <Progress type="circle" percent={orderFulfillmentAverage} width={80} strokeColor="#0785E3" format={percent => `${Math.floor(orderFulfillmentScore * 100) / 100}`} />
                                        </Col>
                                        <Col xs={24} sm={24} md={16}>
                                            <h3>Order Fulfillment CSAT Score</h3>
                                            <Text type="secondary" strong>{filterByOrderFulfillment.length} Recorded Responses</Text>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ margin: "0px 24px 0px 24px" }}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Card className='container' title="Response Collected" style={{ marginBottom: "24px" }}>
                                    <Pie ref="chart" width={100} height={65} borderWidth={10} options={options} legend={legendOpts} data={pieData} />
                                    <div style={{ display: "flex", justifyContent: "center", }}>
                                        <Badge color="#73e6cd" style={{ margin: "5px 10px" }} text="Order Placement" />
                                        <Badge color="#e55863" style={{ margin: "5px 10px" }} text="Order Fulfillment" />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} style={{ marginBottom: "24px" }}>
                                <Card className='container' title="Response Trend">
                                    <Line ref="chart"
                                        width={100}
                                        height={34}
                                        options={{ backgroundColor: "red" }}
                                        legend={{ display: false }}
                                        data={data}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ margin: "0px 24px 0px 24px" }}>
                            <Col xs={24} sm={24} md={8} lg={8} style={{ marginBottom: "24px" }}>
                                <Card title="Rating Based On Order Experience" className="rating-card container">
                                    <Row type="flex" align="middle">
                                        <Col span={4}>
                                            <img src={rate5} alt="5" />
                                        </Col>
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={12}>
                                                    <h3>5 - Excellent</h3>
                                                </Col>
                                                <Col span={12} align="right">
                                                    <h3>{excellentPercent}%</h3>
                                                </Col>
                                            </Row>
                                            <Progress percent={excellentPercent} showInfo={false} />
                                        </Col>
                                    </Row>
                                    <Row type="flex" align="middle">
                                        <Col span={4}>
                                            <img src={rate4} alt="4" />
                                        </Col>
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={12}>
                                                    <h3>4 - Good</h3>

                                                </Col>
                                                <Col span={12} align="right">
                                                    <h3>{goodPercent}%</h3>
                                                </Col>
                                            </Row>
                                            <Progress percent={goodPercent} showInfo={false} />
                                        </Col>
                                    </Row>
                                    <Row type="flex" align="middle">
                                        <Col span={4}>
                                            <img src={rate3} alt="3" />
                                        </Col>
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={12}>
                                                    <h3>3 - Okay</h3>

                                                </Col>
                                                <Col span={12} align="right">
                                                    <h3>{okayPercent}%</h3>
                                                </Col>
                                            </Row>
                                            <Progress percent={okayPercent} showInfo={false} />
                                        </Col>
                                    </Row>
                                    <Row type="flex" align="middle">
                                        <Col span={4}>
                                            <img src={rate2} alt="2" />
                                        </Col>
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={12}>
                                                    <h3>2 - Poor</h3>

                                                </Col>
                                                <Col span={12} align="right">
                                                    <h3>{poorPercent}%</h3>
                                                </Col>
                                            </Row>
                                            <Progress percent={poorPercent} showInfo={false} />
                                        </Col>
                                    </Row>
                                    <Row type="flex" align="middle">
                                        <Col span={4}>
                                            <img src={rate1} alt="1" />
                                        </Col>
                                        <Col span={20}>
                                            <Row type="flex">
                                                <Col span={12}>
                                                    <h3>1 - Very Poor</h3>

                                                </Col>
                                                <Col span={12} align="right">
                                                    <h3>{veryPoorPercent}%</h3>
                                                </Col>
                                            </Row>
                                            <Progress percent={veryPoorPercent} showInfo={false} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16}>
                                <Row>
                                    <Col>
                                        <Card title="Feedback Verbatims" className="feedback-table container">
                                            <Row>
                                                <Col span={12} align="right">
                                                    <ButtonGroup style={{ display: 'flex' }}>
                                                        <Search placeholder="Search" onSearch={(value) => this.handleSearch(value, [
                                                            'po_date',
                                                            'comments',
                                                            'distributor.category',
                                                            'distributor.country',
                                                            'distributor.customer_code',
                                                            'distributor.name',
                                                            'distributor_order.po_reference',
                                                            'distributor_order.port_destination',
                                                            'distributor_order.sales_ref_number',
                                                            'distributor_order.special_instructions',
                                                            'distributor_order.transaction_number',
                                                            'feedback_score',
                                                            'feedback_type'
                                                        ])} style={{ margin: '10px' }} />
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: 20 }}>
                                                <h1>Most Frequent Words Used</h1>
                                                <Col>
                                                    {
                                                        getMostCommonWords(comments).slice(0, 10).map((data, i) => {
                                                            return (
                                                                <Tag color={randomColor({
                                                                    luminosity: 'dark',
                                                                    format: 'rgba',
                                                                    alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.5)',
                                                                })}>{data._comments}</Tag>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            <Table
                                                style={{ overflowY: "auto", height: 340 }}
                                                dataSource={filteredDataTable}
                                                columns={columns}
                                                loading={this.props.feedbackLoading}
                                                pagination={false}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content >
                </Spin>
            </Layout >
        ) : (
                <Result
                    status="warning"
                    title="You have no permission to view this page."
                />
            );
    }
}

function mapStateToProps(state) {
    return {
        myPermissions: state.myPermissions.myPermissions,
        myPermissionsLoading: state.myPermissions.myPermissionsLoading,
        feedbackData: state.feedback.feedbackData,
        feedbackLoading: state.feedback.feedbackLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPermissions: (data) => dispatch(fetchPermissions(data)),
        onLoadFeedbackData: (data) => dispatch(loadFeedbackData(data)),
        onIgnoreComment: (data) => dispatch(ignoreComment(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);