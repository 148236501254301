import React, { Component } from 'react';
import { Layout, Row, Col, Input, Result, Tag, Form, Table } from 'antd';
// Import CSS
import './AuditTrail.scss'
const { Content } = Layout
const { Search } = Input;

class AuditTrail extends Component {
  constructor() {
    super()
    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadAuditTrail()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auditTrailData !== nextProps.auditTrailData) {
      this.setState({
        tableData: nextProps.auditTrailData.sort(function (a, b) {
          return (b.id - a.id);
        })
      })
    }
  }
  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };

    const { auditTrailData } = this.props;
    const newArrayObj = auditTrailData;
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj });
    }
    let distributorArray = [];
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach((keys) => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem });
        let isFound =
          rowItemKeys &&
          rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
        if (isFound) distributorArray.push(rowItem);
      });
    });
    //Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });
    this.setState({ tableData: newArrayElement });
  };

  render() {
    const { myPermissions, myPermissionsLoading } = this.props
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Login DateTime',
        dataIndex: 'updated_at',
        key: 'updated_at'
      },
      {
        title: 'Customer Code',
        dataIndex: 'content',
        key: 'content',
        render: (value, data) => {
          return (
            <div>
              {value.distributor && value.distributor.customer_code || 'N/A'}
            </div>
          )
        }
      },
      {
        title: 'Name',
        dataIndex: 'content',
        key: 'content',
        render: (value, data) => {
          return (
            <div>
              {value.distributor && value.distributor.name || 'N/A'}
            </div>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'content',
        key: 'content',
        render: (value, data) => {
          return (
            <div>
              {value.email}
            </div>
          )
        }
      },
      {
        title: 'Access',
        dataIndex: 'portal',
        key: 'portal',
        render: (value) => {
          return (
            <div>{value === 'admin' ? <Tag color="#2db7f5">Admin</Tag> : <Tag color="#87d068">Client</Tag>}</div>
          )
        }
      },
    ];

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.faq.view') ? (
      // return (
      <div className='audit-trail-page'>
        <Layout>
          <Content className='audit-trail-content-page' style={{ padding: 25 }}>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <span className='audit-trail-title'>
                  AUDIT TRAIL
                </span>
              </Col>
            </Row>
            <Row style={{
              background: '#FFFFFF',
              boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: 6
            }}>
              <Col>
                <Layout.Content style={{ margin: 24, backgroundColor: 'white' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='audit-trail-search'>
                    <Col md={8}>
                      <Search
                        placeholder="Search"
                        onSearch={(value) =>
                          this.handleSearch(value, [
                            'updated_at',
                            'updated_at_local',
                            "content.distributor.name",
                            'content.distributor.customer_code',
                            "content.email",
                            "portal",
                          ])
                        }
                        style={{ width: '100%' }}
                      />
                    </Col>
                  </Row>
                </Layout.Content>
              </Col>
              <Table dataSource={dataSource} columns={columns} />
            </Row>
          </Content>
        </Layout>
      </div >
    )
      : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

export default Form.create({ name: 'AuditTrail' })(AuditTrail);