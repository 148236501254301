import React, { Component } from 'react';
import moment from 'moment'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { Button } from 'antd'
import { numberFormat } from "../../utils";
const wb = new ExcelJS.Workbook()

class DownloadExcel extends Component {
    constructor() {
        super()
        this.state = {
            selectedOrder: []
        }
        this.saveAsExcel = this.saveAsExcel.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            const selectedArray = nextProps.rowIds.map(id => {
                return nextProps.data.find(data => data.id === id)
            })
            this.setState({ selectedOrder: selectedArray })
        }
    }

    async saveAsExcel() {

        let worksheetArray = []
        this.state.selectedOrder.map((orderData, i) => {
            const worksheet = wb.addWorksheet(`${orderData.transaction_number}`)

            // Width of  column
            worksheet.getColumn('A').width = 5
            worksheet.getColumn('B').width = 20
            worksheet.getColumn('C').width = 15
            worksheet.getColumn('D').width = 50
            worksheet.getColumn('E').width = 15
            worksheet.getColumn('F').width = 15
            worksheet.getColumn('G').width = 15
            worksheet.getColumn('H').width = 15
            worksheet.getColumn('I').width = 15
            worksheet.getColumn('J').width = 15
            worksheet.getColumn('K').width = 15
            worksheet.getColumn('L').width = 15

            //Header
            worksheet.mergeCells('A1:F1');
            worksheet.getCell('F1').value = 'CUSTOMER INFO SHEET';
            worksheet.getCell('F1').alignment = { horizontal: 'center' };
            worksheet.getCell('F1').font = { bold: true, horizontal: 'center' };

            //Distributor Name
            worksheet.mergeCells('A2:C2');
            worksheet.getCell('C2').value = 'DISTRIBUTOR NAME:';
            worksheet.getCell('C2').font = { bold: true };

            //Distributor Name (Value)
            worksheet.mergeCells('D2:F2');
            worksheet.getCell('F2').value = `${orderData.distributor.name}`;

            //Distributor Code
            worksheet.mergeCells('A3:C3');
            worksheet.getCell('C3').value = 'DISTRIBUTOR CODE:';
            worksheet.getCell('C3').font = { bold: true };

            //Distributor Code (Value)
            worksheet.mergeCells('D3:F3');
            worksheet.getCell('F3').value = `${orderData.distributor.customer_code}`;

            //Office Address
            worksheet.mergeCells('A4:C4');
            worksheet.getCell('C4').value = 'OFFICE ADDRESS:';
            worksheet.getCell('C4').font = { bold: true };

            //Office Address (Value)
            worksheet.mergeCells('D4:F4');
            worksheet.getCell('F4').value = `${orderData.distributor.address[0].name}`;

            //Office #
            worksheet.mergeCells('A5:C5');
            worksheet.getCell('C5').value = 'OFFICE #:';
            worksheet.getCell('C5').font = { bold: true };

            //Office # (Value)
            worksheet.mergeCells('D5:F5');
            worksheet.getCell('F5').value = `${orderData.distributor.contact.find((item) => item.type === 'office').contact}`;

            //Phone #
            worksheet.mergeCells('A6:C6');
            worksheet.getCell('C6').value = 'PHONE #:';
            worksheet.getCell('C6').font = { bold: true };

            //Phone # (Value)
            worksheet.mergeCells('D6:F6');
            worksheet.getCell('F6').value = `${orderData.distributor.contact.find((item) => item.type === 'mobile').contact}`;


            //Email Address
            worksheet.mergeCells('A7:C7');
            worksheet.getCell('C7').value = 'EMAIL ADDRESS:';
            worksheet.getCell('C7').font = { bold: true };

            //Email Address (Value)
            worksheet.mergeCells('D7:F7');
            worksheet.getCell('F7').value = `${orderData.distributor.contact.find((item) => item.type === 'email').contact}`;

            //Header
            worksheet.mergeCells('A8:F8');
            worksheet.getCell('F8').value = 'ORDER FORM DETAILS';
            worksheet.getCell('F8').alignment = { horizontal: 'center' };
            worksheet.getCell('F8').font = { bold: true, horizontal: 'center' };

            //PO Date  
            worksheet.mergeCells('A9:C9');
            worksheet.getCell('C9').value = 'P.O DATE:';
            worksheet.getCell('C9').font = { bold: true };

            //PO Date (Value)
            worksheet.mergeCells('D9:F9');
            worksheet.getCell('F9').value = `${orderData.created_at}`;

            //PO Ref Number
            worksheet.mergeCells('A10:C10');
            worksheet.getCell('C10').value = 'P.O REF NO.:';
            worksheet.getCell('C10').font = { bold: true };

            //PO Ref Number (Value)
            worksheet.mergeCells('D10:F10');
            worksheet.getCell('F10').value = `${orderData.po_reference}`;

            //Order #
            worksheet.mergeCells('A11:C11');
            worksheet.getCell('C11').value = 'ORDER #:';
            worksheet.getCell('C11').font = { bold: true };

            //Order # (Value)
            worksheet.mergeCells('D11:F11');
            worksheet.getCell('F11').value = `${orderData.transaction_number}`;

            //Port of Destination
            worksheet.mergeCells('A12:C12');
            worksheet.getCell('C12').value = 'PORT OF DESTINATION/DELIVER ADDRESS:';
            worksheet.getCell('C12').font = { bold: true };

            //Port of Destination # (Value)
            worksheet.mergeCells('D12:F12');
            worksheet.getCell('F12').value = `${orderData.port_destination}`;

            //Container Option
            worksheet.mergeCells('A13:C13');
            worksheet.getCell('C13').value = 'CONTAINER OPTION:';
            worksheet.getCell('C13').font = { bold: true };

            //Container Option (Value)
            worksheet.mergeCells('D13:F13');
            worksheet.getCell('F13').value = `${orderData.volume}`;

            //ETD
            worksheet.mergeCells('A14:C14');
            worksheet.getCell('C14').value = 'ETD:';
            worksheet.getCell('C14').font = { bold: true };

            //ETD (Value)
            worksheet.mergeCells('D14:F14');
            worksheet.getCell('F14').value = `${moment(orderData.estimated_delivery).format('MMM D, YYYY')}`;

            worksheet.getCell('B16').value = 'PRODUCT';
            worksheet.getCell('B16').font = { bold: true };

            worksheet.getCell('C16').value = 'SKU CODE';
            worksheet.getCell('C16').font = { bold: true };

            worksheet.getCell('D16').value = 'DESCRIPTION';
            worksheet.getCell('D16').font = { bold: true };

            worksheet.getCell('E16').value = 'PACKING SCHEME';
            worksheet.getCell('E16').font = { bold: true };

            worksheet.getCell('F16').value = 'QUANTITY';
            worksheet.getCell('F16').font = { bold: true };

            worksheet.getCell('G16').value = 'CBM';
            worksheet.getCell('G16').font = { bold: true };

            worksheet.getCell('H16').value = 'GROSS WEIGHT';
            worksheet.getCell('H16').font = { bold: true };

            worksheet.getCell('I16').value = 'PRICE';
            worksheet.getCell('I16').font = { bold: true };

            const lastRow = 17
            orderData.product.map((productItem, i) => {
                worksheet.getCell((`A${lastRow + i}`).toString()).value = `${i + 1}`;
                worksheet.getCell((`B${lastRow + i}`).toString()).value = `${productItem.product.name}`;
                worksheet.getCell((`C${lastRow + i}`).toString()).value = `${productItem.variant.material_code}`;
                worksheet.getCell((`D${lastRow + i}`).toString()).value = `${productItem.variant.name}`;
                worksheet.getCell((`E${lastRow + i}`).toString()).value = `${productItem.variant.packing_scheme}`;
                worksheet.getCell((`F${lastRow + i}`).toString()).value = `${productItem.quantity.toLocaleString()}`;
                worksheet.getCell((`G${lastRow + i}`).toString()).value = `${numberFormat(productItem.quantity * productItem.variant.cbm)}`;
                worksheet.getCell((`H${lastRow + i}`).toString()).value = `${(productItem.quantity * productItem.variant.gross_weight).toLocaleString()}`;
                worksheet.getCell((`I${lastRow + i}`).toString()).value = `$${numberFormat(productItem.quantity * productItem.selling_price)}`;
            })

            const totalQuantity = orderData.product.reduce((a, b) => +a + +b.quantity, 0);
            const totalCbm = orderData.product.reduce((a, b) => +a + +(b.variant.cbm * b.quantity), 0);
            const totalGrossWeight = orderData.product.reduce((a, b) => +a + +(b.variant.gross_weight * b.quantity), 0);
            const totalSellingPrice = orderData.product.reduce((a, b) => +a + +(b.selling_price * b.quantity), 0);

            let totalRow = lastRow + orderData.product.length
            worksheet.getCell(`B${totalRow}`).value = 'TOTAL';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`F${totalRow}`).toString()).value = `${totalQuantity.toLocaleString()}`;
            worksheet.getCell(`F${totalRow}`).font = { bold: true };

            worksheet.getCell((`G${totalRow}`).toString()).value = `${totalCbm.toFixed(2)}`;
            worksheet.getCell(`G${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${totalGrossWeight.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            worksheet.getCell((`I${totalRow}`).toString()).value = `$${numberFormat(totalSellingPrice)}`;
            worksheet.getCell(`I${totalRow}`).font = { bold: true };

            //Special Instructions
            totalRow = totalRow + 2
            worksheet.mergeCells(`A${totalRow}:F${totalRow}`);
            worksheet.getCell(`F${totalRow}`).value = 'SPECIAL INSTRUCTIONS:';
            worksheet.getCell(`F${totalRow}`).font = { bold: true };

            //Special Instructions (Value)
            totalRow = totalRow + 1
            worksheet.mergeCells(`A${totalRow}:F${totalRow}`);
            worksheet.getCell(`F${totalRow}`).value = `${orderData.special_instructions}`;

            worksheetArray.push(worksheet)
        })

        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Order Management Report.xlsx')

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })
    }

    render() {
        const { title, style, className, disabled } = this.props
        return (
            <div>
                <Button
                    className={className}
                    disabled={this.state.selectedOrder.length === 0}
                    style={style}
                    onClick={() => this.saveAsExcel()}
                >{title}
                </Button>
            </div>
        );
    }
}

export default DownloadExcel
