import Axios from 'axios'
import { headers } from '../../../../utils/token';

const GET_PORTFOLIO_BRAND = 'GET_PORTFOLIO_BRAND'
const GET_PORTFOLIO_BRAND_FULFILLED = 'GET_PORTFOLIO_BRAND_FULFILLED'
const GET_PORTFOLIO_BRAND_REJECTED = 'GET_PORTFOLIO_BRAND_REJECTED'

const POST_PORTFOLIO_BRAND = 'POST_PORTFOLIO_BRAND'
const POST_PORTFOLIO_BRAND_FULFILLED = 'POST_PORTFOLIO_BRAND_FULFILLED'
const POST_PORTFOLIO_BRAND_REJECTED = 'POST_PORTFOLIO_BRAND_REJECTED'

const API_URL = process.env.REACT_APP_API_URL

export const loadPortfolioBrands = (data) => {
  return dispatch => {
    dispatch({
      type: GET_PORTFOLIO_BRAND,
      payload: {}
    })
    return Axios.get(`${API_URL}/portfolio-brands`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PORTFOLIO_BRAND_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PORTFOLIO_BRAND_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPortfolioBrands = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PORTFOLIO_BRAND,
      payload: {}
    })
    return Axios.post(`${API_URL}/portfolio-brands/`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_PORTFOLIO_BRAND_FULFILLED,
          payload: response.data
        })
        // dispatch(loadAboutArticle())
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_PORTFOLIO_BRAND_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

export const submitAddArticle = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PORTFOLIO_BRAND,
      payload: {}
    })
    return Axios.post(`${API_URL}/admin/about`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_PORTFOLIO_BRAND_FULFILLED,
          payload: response.data
        })
        // dispatch(loadAboutArticle())
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_PORTFOLIO_BRAND_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}


