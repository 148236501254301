import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Switch, Icon, Button, Layout, Row, Col, Tabs, Dropdown, Menu, Input, Skeleton, Spin, Result, Modal, Form, Select } from 'antd';
// Import CSS
import './FAQ.scss'
import Reorder, {
  reorder,
} from 'react-reorder';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ModalCategory from './components/Modals/addNewCategory'
import ModalQuestionAndAnswer from './components/Modals/questionAndAnswer'
const { TabPane } = Tabs;
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

const DragHandle = sortableHandle(() => <Icon type="drag" className='faq-icon' />);

const SortableItem = sortableElement((props) => {
  const { value } = props
  const item = value
  return (
    <div className='faq-draggable-container'>
      <div className='faq-draggable'>
        <Row>
          <Col md={2} >
            <div className='faq-draggable-switch' ><Switch key={value.id} defaultChecked={item.is_active ? true : false} onChange={(e) => props.handleChangeStatus({ id: item.id, is_active: e === true ? 1 : 0 })} /></div>
          </Col>
          <Col md={15} >
            <div className='faq-draggable-content'>
              <strong>{props.listNumber + 1}. {item.question}</strong>
              <p>{item.answer}</p>
            </div>
          </Col>
          <Col md={7}  >
            <div className='faq-draggable-actions'>
              <Icon style={{ margin: '15px 0' }} type="eye" />
              <Select key={value.id} defaultValue={item.type} style={{ width: 130 }} onChange={(e) => props.handleChangeType({ id: item.id, type: e })} >
                <Option value={1}>Logged In</Option>
                <Option value={0}>Both</Option>
              </Select>
              <DragHandle />
              <Icon className='faq-icon' type="edit" onClick={() => props.handleModalQuestionAndAnswer('edit', item)} />
              <Icon className='faq-icon' type="delete" onClick={() => props.handleRemoveQuestionAndAnswer(item)} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
});

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ paddingInlineStart: 0 }}>{children}</ul>;
});

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalCategory: false,
      showModalQuestionAndAnswer: false,
      filterBy: 'all',
      activeCategoryId: 1,
      activeFaqsBasedOnActiveKeys: [],
      categoryArray: [],
      selectedActiveCategory: {},
      selectedAnswerAndQuestion: {},
    };
    this.handleModalCategory = this.handleModalCategory.bind(this)
    this.handleModalQuestionAndAnswer = this.handleModalQuestionAndAnswer.bind(this)
    this.handleRemoveQuestionAndAnswer = this.handleRemoveQuestionAndAnswer.bind(this)
    this.handleClickedKeyActive = this.handleClickedKeyActive.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentDidMount() {
    this.props.fetchPermissions()
    this.props.onLoadFAQCategory().then((res) => {
      const categoryArray = res.response.data
      const activeFaqsBasedOnActiveKeys = categoryArray.length !== 0 ? categoryArray.find((data) => Number(data.id) === (Number(1))).faqs : []
      this.setState({ categoryArray, activeFaqsBasedOnActiveKeys })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.faqCategoryData !== nextProps.faqCategoryData) {
      const activeFaqsBasedOnActiveKeys = nextProps.faqCategoryData.length !== 0 ? nextProps.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs : []
      this.setState({ categoryArray: nextProps.faqCategoryData, activeFaqsBasedOnActiveKeys })
    }
  }

  handleModalCategory(type) {
    switch (type) {
      case 'add':
        this.setState({
          showModalCategory: !this.state.showModalCategory,
          selectedActiveCategory: {}
        })
        break;
      case 'edit':
        this.setState({
          showModalCategory: !this.state.showModalCategory,
          selectedActiveCategory: this.state.categoryArray.find((data) => Number(data.id) === Number(this.state.activeCategoryId))
        })
        break;
      case 'exit':
        this.setState({
          showModalCategory: false,
        })
        break
      default:
        break;
    }
  }

  handleModalQuestionAndAnswer(type, data) {
    switch (type) {
      case 'add':
        this.setState({
          showModalQuestionAndAnswer: !this.state.showModalQuestionAndAnswer,
          selectedAnswerAndQuestion: {}
        })
        break;
      case 'edit':
        this.setState({
          showModalQuestionAndAnswer: !this.state.showModalQuestionAndAnswer,
          selectedAnswerAndQuestion: data
        })
        break;
      case 'exit':
        this.setState({
          showModalQuestionAndAnswer: false,
        })
        break
      default:
        break;
    }
  }

  handleRemoveQuestionAndAnswer(data) {
    Modal.confirm({
      title: "Delete Question And Answer",
      content: `Are you sure, you want to delete this question and answer.`,
      okText: ' Okay',
      onOk: () => this.props.onRemovedQuestionAndAnswer(data).then((res) => {
        if (!res.error) {
          Modal.success({
            title: "Success",
            content: `The selected question and answer has been removed.`,
            okText: ' Okay',
          })
        }
      })
    })

  }

  handleChangeType(value) {
    this.props.onUpdateType(value)
  }

  handleChangeStatus(value) {
    this.props.onUpdateStatus(value)
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    if (this.state.filterBy !== 'all') {
      searchKeys = [this.state.filterBy]
    }
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }

    const { orderData } = this.props
    const newArrayObj = this.state.activeFaqsBasedOnActiveKeys
    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs })
    }
    let newArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
        if (isFound) newArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = newArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ activeFaqsBasedOnActiveKeys: newArrayElement })
  }

  handleClickedKeyActive(id) {
    const activeFaqsBasedOnActiveKeys = this.state.categoryArray.find((data) => Number(data.id) === (Number(id))).faqs
    this.setState({ activeFaqsBasedOnActiveKeys, activeCategoryId: id })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const sortArray = []
    arrayMove(this.state.activeFaqsBasedOnActiveKeys, oldIndex, newIndex).map((data, index) => {
      sortArray.push({ id: data.id, order_no: index + 1 })
    })

    this.setState(({ activeFaqsBasedOnActiveKeys }) => ({
      activeFaqsBasedOnActiveKeys: arrayMove(activeFaqsBasedOnActiveKeys, oldIndex, newIndex),
    }));

    this.props.onUpdateSortList(sortArray)
  };

  handleFilterBy(data) {
    this.setState({
      filterBy: data,
      activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs
    })
  }

  render() {
    const { selectedActiveCategory } = this.state
    const { myPermissions, faqCategoryData, myPermissionsLoading } = this.props

    let activeCategory = this.state.categoryArray.find((data) => Number(data.id) === Number(this.state.activeCategoryId))

    if (myPermissionsLoading) {
      // return (
      //   <Layout>
      //     <Skeleton active />
      //   </Layout>
      // )
      return null
    }

    return myPermissions.some(r => r.name === 'admin.faq.view') ? (
      // return (
      <div className='faq-container-page'>
        <Layout>
          <Content className='faq-content-page' style={{ padding: 25 }}>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <span className='faq-title'>
                  Frequently Asked Questions (FAQ)
                </span>
              </Col>
              <Col xs={24} sm={24} md={12} align="right">
                <Button className='faq-add-new-category'
                  onClick={() => this.handleModalCategory('add')}
                  disabled={!myPermissions.some(r => r.name === 'admin.faq.create')}
                >
                  <Icon type="plus" />Add New Category
                </Button>
              </Col>
            </Row>
            <Row>
              <Tabs defaultActiveKey="1" tabPosition={'top'} className='faq-tabs' onTabClick={(id) => this.handleClickedKeyActive(id)}>
                {this.state.categoryArray.map(i => (
                  <TabPane tab={`${i.category}`} key={i.id} />
                ))}
              </Tabs>
            </Row>
            <Row style={{
              background: '#FFFFFF',
              boxShadow: "0px 7px 32px rgba(0, 0, 0, 0.07)",
              borderRadius: 6
            }}>
              <Col>
                <Layout.Content style={{ margin: 24, backgroundColor: 'white' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='faq-header'>
                    <Col xs={24} sm={24} md={16} >
                      <span className='faq-header-title'>{activeCategory && activeCategory.category}</span>
                    </Col>
                    <Col xs={24} sm={24} md={8} align="right" >
                      <Row className='faq-header-action'>
                        <h1
                          onClick={() => {
                            myPermissions.some(r => r.name === 'admin.faq.edit' ?
                              this.handleModalCategory('edit')
                              : null)
                          }
                          }
                        >Edit Category</h1>

                        <Button
                          onClick={() => this.handleModalQuestionAndAnswer('add')}
                          disabled={!myPermissions.some(r => r.name === 'admin.faq.create')}
                        >
                          <span>Add New Question</span>
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='faq-search'>
                    <Col md={8}>
                      <Search
                        placeholder="Search"
                        onSearch={value => this.handleSearch(value, ['question', 'answer'])}
                        style={{ width: '100%' }}
                      />
                    </Col>
                    <Col md={16} align="left" className='faq-sort-by'>
                      <span>FILTER BY</span>
                      <Select style={{ width: 100 }} defaultValue="All" onChange={(e) => this.handleFilterBy(e)} showSearch>
                        <Option value="all">All</Option>
                        <Option value="question">Question</Option>
                        <Option value="answer">Answer</Option>
                      </Select>
                    </Col>
                  </Row>
                </Layout.Content>
              </Col>
              <SortableContainer onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis="y">
                {this.state.activeFaqsBasedOnActiveKeys.map((value, index) => (
                  <SortableItem
                    key={`item-${value}_${index}`}
                    handleModalQuestionAndAnswer={this.handleModalQuestionAndAnswer}
                    handleRemoveQuestionAndAnswer={this.handleRemoveQuestionAndAnswer}
                    handleChangeType={this.handleChangeType}
                    handleChangeStatus={this.handleChangeStatus}
                    listNumber={index}
                    index={index}
                    value={value}
                  />
                ))}
              </SortableContainer>

              {
                <Layout style={{ display: this.state.activeFaqsBasedOnActiveKeys.length <= 0 ? 'block' : 'none' }}>
                  <Layout.Content className='faq-no-data'>
                    <Spin spinning={this.state.activeFaqsBasedOnActiveKeys.length <= 0} />
                  </Layout.Content>
                </Layout>
              }
            </Row>
          </Content>
        </Layout>
        {/* MODALS */}
        {
          this.state.showModalCategory &&
          <ModalCategory
            visible={this.state.showModalCategory}
            selectedActiveCategory={this.state.selectedActiveCategory}
            close={this.handleModalCategory}
          />
        }

        {
          this.state.showModalQuestionAndAnswer &&
          <ModalQuestionAndAnswer
            visible={this.state.showModalQuestionAndAnswer}
            selectedAnswerAndQuestion={this.state.selectedAnswerAndQuestion}
            activeCategoryId={this.state.activeCategoryId}
            close={this.handleModalQuestionAndAnswer}
          />
        }
      </div >
    )
      : (
        <Result
          status="warning"
          title="You have no permission to view this page."
        />
      );
  }
}

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
);

export default Form.create({ name: 'AddEditQuestionAndAnswer' })(FAQ);