import { getName } from 'country-list'
import moment from 'moment';

const initialData = {
  dashboardData: [],
  dashboardLoading: false,
  distributorTotalActiveData: {},
  distributorTotalActiveLoading: false
}
export default function dashboard(state = initialData, action) {
  switch (action.type) {
    case 'GET_DASHBOARD_DATA':
      return {
        ...state,
        dashboardLoading: true
      }
    case 'GET_DASHBOARD_DATA_FULFILLED':
      action.payload.sort((a, b) => moment(b.etd_month).valueOf() - moment(a.etd_month).valueOf()).map(data => {
        data.country === 'UAE'
          ? data.country = getName('AE')
          : data.country === 'UK'
            ? data.country = getName('GB')
            : data.country = getName(data.country)
        // data.etd_month = moment().add(data.etd_month, 'months').format("MMMM YYYY")
        data.order.filter(order => order.estimated_delivery !== null).map(order => {
          order.estimated_delivery = moment(order.estimated_delivery).format('MMMM YYYY')
        })
      })

      return {
        ...state,
        dashboardData: action.payload,
        dashboardLoading: false
      }
    case 'GET_DASHBOARD_DATA_REJECTED':
      return {
        ...state,
        dashboardData: [],
        dashboardLoading: false
      }

    case 'GET_DISTRIBUTOR_TOTAL_ACTIVE':
      return {
        ...state,
        distributorTotalActiveLoading: true
      }
    case 'GET_DISTRIBUTOR_TOTAL_ACTIVE_FULFILLED':

      return {
        ...state,
        distributorTotalActiveData: action.payload,
        distributorTotalActiveLoading: false
      }
    case 'GET_DISTRIBUTOR_TOTAL_ACTIVE_REJECTED':
      return {
        ...state,
        distributorTotalActiveData: [],
        distributorTotalActiveLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
