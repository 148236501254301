import Axios from 'axios'
import { headers } from '../../../../utils/token';

const GET_ARTICLE = 'GET_ARTICLE'
const GET_ARTICLE_FULFILLED = 'GET_ARTICLE_FULFILLED'
const GET_ARTICLE_REJECTED = 'GET_ARTICLE_REJECTED'

const POST_ARTICLE = 'POST_ARTICLE'
const POST_ARTICLE_FULFILLED = 'POST_ARTICLE_FULFILLED'
const POST_ARTICLE_REJECTED = 'POST_ARTICLE_REJECTED'

const API_URL = process.env.REACT_APP_API_URL

export const loadAboutArticle = (data) => {
  return dispatch => {
    dispatch({
      type: GET_ARTICLE,
      payload: {}
    })
    return Axios.get(`${API_URL}/admin/about/`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ARTICLE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ARTICLE_REJECTED,
          payload: error
        })
      })
  }
}

export const submitUpdateArticle = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ARTICLE,
      payload: {}
    })
    return Axios.post(`${API_URL}/admin/about/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ARTICLE_FULFILLED,
          payload: response.data
        })
        dispatch(loadAboutArticle())
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_ARTICLE_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

export const submitAddArticle = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ARTICLE,
      payload: {}
    })
    return Axios.post(`${API_URL}/admin/about`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ARTICLE_FULFILLED,
          payload: response.data
        })
        dispatch(loadAboutArticle())
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: POST_ARTICLE_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}


